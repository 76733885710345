import React, { memo, useMemo } from 'react';
import Logger from '../../utils/logger';
import bgImage from '../../metronic/media/bg/bg-8.jpg';
import Search from '../KTInputIcon';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';

const log = Logger('PageHeader');

function PageHeader({
    nav,
    title,
    onSearch,
    searchPlaceholder = 'Search'
}) {
    log.debug('render');

    const __title = useMemo(() => {
        if (onSearch) {
            return <Search 
                tpl="ktSc"
                onChange={onSearch} 
                placeholder={searchPlaceholder}
                icon={<SearchIcon/>}
            />;
        }
        if (!title) return null;
        if (typeof title === 'string') {
            return <h3 className="kt-sc__title">{ title }</h3>;
        } 
        return title;
    }, [title, onSearch, searchPlaceholder]);

    return (
        <React.Fragment>
            <div className="kt-sc-license" style={{backgroundImage: `url(${bgImage})`}}>
                <div className="kt-container ">
		            <div className="kt-sc__top">
                        { __title }
                        {
                            nav
                            ? <div className="kt-sc__nav">{ nav }</div> 
                            : null
                        }
		            </div>
	            </div>
            </div>
            <div className="kt-negative-spacing--7"></div>
        </React.Fragment>
    )
}

export default memo(PageHeader);