
import {
    typeCurrentEntity,
    typeNewEntity,
    typeDeleteEntity,
    fetchTrainingModel,
    fetchTrainingModelSuccess
} from '../constants';

export default function currentEntity(state = null, { type, payload }) {
    switch (type) {
        case typeNewEntity:
            return payload.id;
        case typeCurrentEntity:
            return payload;
        case typeDeleteEntity: 
            return payload === state ? null : state;
        case fetchTrainingModelSuccess:
        case fetchTrainingModel:
            return null;
        default:
            return state;
    }
}