import React, { useMemo, useCallback } from 'react';
import { EditorState, AtomicBlockUtils } from 'draft-js';
import { connect } from 'react-redux';
import _defaultsDeep from 'lodash/assign';

export const connectAtomicBlock = Component => {
    function AtomicBlock({
        setEditorState, getEditorState, getEditorRef, 
        setReadOnly, entityKey, data
    }) {
        const editor = getEditorRef();
        const { disableEditor, enableEditor } = useMemo(() => {
            return {
                disableEditor: () => {
                    if (editor) editor.blur();
                    setReadOnly(true);
                },
                enableEditor: focus => {
                    setReadOnly(false);
                    if (editor && focus) editor.focus();
                }
            };
        }, [editor, setReadOnly]);

        const mergeEntityData = useCallback(data => {
            const editorState = getEditorState();
            if (data.isNew) delete data.isNew;
            const content = editorState.getCurrentContent();
            content.replaceEntityData(
                entityKey, data
            );
            setEditorState(EditorState.createWithContent(content));
        }, [getEditorState, setEditorState, entityKey]);

        return (<Component 
            data={data}
            mergeEntityData={mergeEntityData}
            disableEditor={disableEditor}
            enableEditor={enableEditor}
        />)
    }
    
    return connect(
        null,
        null,
        (st, dis, { blockProps }) => {
            const { setEditorState, getEditorState, getEditorRef, setReadOnly, entityKey } = blockProps;
            const data = getEditorState().getCurrentContent().getEntity(entityKey).getData();
            return { setEditorState, getEditorState, getEditorRef, setReadOnly, entityKey, data };
        }
    )(AtomicBlock);
};

const defaultOptions = {
    defaultData: {
        isNew: true
    }
};

export function ceateAtomicPlugin(type, component, options) {
    component = connectAtomicBlock(component);
    let _options = {};
    Object.keys(defaultOptions).forEach(k => {
        _options[k] = {
            ...defaultOptions[k],
            ...(options[k] || {})
        };
    });
    return () => {
        return {
            blockRendererFn: (block, { 
                setEditorState, getEditorState, getEditorRef, setReadOnly 
            }) => {
                if (block.getType() === 'atomic') {
                    const editorState = getEditorState();
                    const contentState = editorState.getCurrentContent();
                    const entityKey = block.getEntityAt(0);
                    if (!entityKey) return null;
                    if (contentState.getEntity(entityKey).getType() === type) {
                        return {
                            component: component,
                            editable: false,
                            props: {
                                setEditorState, 
                                getEditorState, 
                                getEditorRef, 
                                setReadOnly,
                                entityKey
                            }
                        };
                    }
                    return null;
                }
                return null;
            },
            addBlock: (editorState, data = {}) => {
                const contentState = editorState.getCurrentContent();
                const contentStateWithEntity = contentState.createEntity(
                    type,
                    'IMMUTABLE',
                    _defaultsDeep({}, _options.defaultData, data)
                );
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                    editorState,
                    entityKey,
                    ' '
                );
            
                return EditorState.forceSelection(
                    newEditorState,
                    newEditorState.getCurrentContent().getSelectionAfter()
                );
            }
        };
    };
}