import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { deleteIntent } from '../../actions/intent';
import DeleteIconConfirmation from '../../components/DeleteIconConfirmation';

function IntentContentToolbar({id, deleteIntent, ...props}) {
    const onYes = useCallback(() => deleteIntent(id), [id, deleteIntent]);
    return <DeleteIconConfirmation id={id} onYes={onYes} {...props}/>
}

export default connect(
    state => ({
        id: state.currentIntent
    }),
    { deleteIntent }
)(IntentContentToolbar);