import {
    fetchActionsSuccess,
    typeCreateActionSuccess,
    typeUpdateActionSuccess,
    typeDeleteActionSuccess
} from '../constants';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { 
    getMergedIntegrationsSelector, 
    getIntegration,
    getIntegrationsDetailedName
} from './integrations';

export default function(state = [], { type, payload }) {
    switch (type) {
        case fetchActionsSuccess:
            return [
                ...payload.data
            ];
        case typeCreateActionSuccess:
            return [
                ...state,
                payload.data
            ];
        case typeDeleteActionSuccess:
            return state.filter(el => el.id !== payload.id);
        case typeUpdateActionSuccess:
            return state.map(el => {
                if (el.id === payload.data.id) {
                    return {
                        ...payload.data
                    };
                }
                return el;
            }); 
        default:
            return state;
    }
}

export const getActionById = (state, props) => 
    state.actions.find(el => el.id === props.id);

export const actionNameSelector = createSelector(
    [ getActionById ],
    action => action ? action.name : null
);

export const actionTypeSelector = createSelector(
    [ getActionById ],
    action => action ? action.type : null
);

export const actionIntegrationSelector = createSelector(
    [ getActionById, getMergedIntegrationsSelector],
    (action, ints) => {
        if (!action || !action.integration) return null;
        return getIntegration(ints, action.integration);
    }
);

export const actionDetailedNameSelector = createSelector(
    [actionNameSelector, actionIntegrationSelector, actionTypeSelector],
    (actionName, int, type) => {
        if (!actionName) return null;
        const intDetai = getIntegrationsDetailedName(int);
        return {
            name: actionName,
            description: intDetai ? intDetai.name : type,
            image: intDetai ? intDetai.image : null
        };
    }
);

export const actionParameters = action => {
    return _get(action, 'data.params');
};

export const actionParametersSelector = createSelector(
    [ getActionById ], actionParameters
);