import React, { memo, useMemo } from 'react';
import TextTooltip from '../TextTooltip';
import {
    faInfoCircle
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Doc from './Documentation';

const Icon = <FontAwesomeIcon className="kt-font-info" size="1x" icon={faInfoCircle} />;

function InfoIcon({
    tooltip,
    link,
    external,
    ...props
}) {

    const _Icon = useMemo(() => {
        if (link) {
            return (
                <a 
                    href={link} 
                    rel={external ? "noopener noreferrer" : null}
                    target={external ? "_blank" : null}
                >{Icon}</a>
            )
        }
        return Icon;
    }, [link, external]);

    if (tooltip) {
        return (
            <TextTooltip 
                text={tooltip}
                {...props}
            >
                {_Icon}
            </TextTooltip>
        ) 
    }
    return _Icon;
}

export const DocInfoIcon = Doc;
export default memo(InfoIcon);