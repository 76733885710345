import React, { 
    useCallback, useEffect,
    useState, useImperativeHandle
} from 'react';
import { connect } from 'react-redux';
import Editor from 'draft-js-plugins-editor';
import { 
    AlignmentTool,
    SideToolbar,
    plugins
} from './NodeOutputEditor';
// import { onOutputChange } from '../../actions/nodeForm';
import { formOutputSelector } from '../../reducers/forms/skillNode';
import { 
    EditorState, convertToRaw, 
    convertFromRaw
} from 'draft-js';

// function isNotSynced(value, state) {
//     return JSON.stringify(value) !== JSON.stringify(convertToRaw(state.getCurrentContent()));
// }

function NodeOutputEditorWrap({
    value, syncRef
}) {
    // const [state, setState] = useState(EditorState.createEmpty());
    // const _onChange = useCallback(editorState => setState(editorState), []);

    // useEffect(() => {
    //     // const tId = setTimeout(() => {
    //     //     if (!value || (value && isNotSynced(value, state))) 
    //     //         onChange(convertToRaw(state.getCurrentContent()));
    //     // }, 300);
    //     // return () => clearTimeout(tId);
    // // eslint-disable-next-line
    // }, [state]);

    // useEffect(() => {

    //     // if (value && isNotSynced(value, state)) { 
    //     //     const selection = state.getSelection();
    //     //     const content = convertFromRaw(value);
    //     //     let newState = EditorState.createWithContent(content);
    //     //     if (selection.getHasFocus()) {
    //     //         newState = EditorState.forceSelection(newState, selection);
    //     //     }
    //     //     setState(newState);
    //     // }
    // // eslint-disable-next-line
    // }, [value]);


    const [state, setState] = useState(EditorState.createEmpty());

    useImperativeHandle(syncRef, () => ({
        getNodeOutput: () => {
            return convertToRaw(state.getCurrentContent());
        }
    }));

    const _onChange = useCallback(editorState => setState(editorState), []);

    useEffect(() => {
        if (value) {
            setState(EditorState.createWithContent(convertFromRaw(value)));
        }
    }, [value]);

    return (
        <div className="output-input">
            <Editor
                editorState={state}
                onChange={_onChange}
                plugins={plugins}
                placeholder="Node output..."
            />
            <SideToolbar/>
            <AlignmentTool/>
        </div>
    );
}

export default connect(
    state => ({
        value: formOutputSelector(state)
    }),
    {
        // onChange: onOutputChange
    }
)(NodeOutputEditorWrap);

// const focus = useCallback(() => {
    //     if (ref.current) {
    //         if (selection && selection.getHasFocus()) {
    //             return false;
    //         }
    //         if (ref.current.editor.editor.getAttribute('contenteditable') === 'true') {
    //             ref.current.focus();
    //         }
    //     }
    // }, [ref, selection]);