import React, {memo} from 'react';

function Widget12Section({children, ...props}) {
    return (
        <div {...props} className="kt-widget12__item">
            {children}
        </div>
    )
}

export default memo(Widget12Section);