import React, { memo } from 'react';
import Logger from '../../utils/logger';
import classnames from 'classnames';
import "./index.sass";

const log = Logger('KTCallout');

function KTCallout({
    title,
    description,
    children,
    className,
    hover,
    ...props
}) {
    log.debug('render');
    return (
        <div 
            className={classnames("kt-portlet kt-callout kt-callout--diagonal-bg", {
                'kt-callout--hover-brand': hover
            }, className)}
            {...props}
        >
            <div className="kt-portlet__body">
                <div className="kt-callout__body">
                    <div className="kt-callout__content">
                        <h3 className="kt-callout__title">{title}</h3>
                        <p className="kt-callout__desc">{description}</p>
                    </div>
                    <div className="kt-callout__action">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(KTCallout);