import {
    fetchUserInfoSuccess,
    fetchUserInfoError,
    saveNewUserDetails,
    saveNewUserDetailsSuccess,
    saveNewUserDetailsError
} from '../constants';
import produce from "immer";

const initState = {
    user: {
        isNew: false
    },
    loading: true
};

export default produce((state, {type, payload}) => {
    switch (type) {
        case saveNewUserDetails:
            state.loading = true;
            break;
        case saveNewUserDetailsSuccess:
        case fetchUserInfoSuccess:
            state.user = payload.data;
            state.loading = false;
            break;
        case fetchUserInfoError:
        case saveNewUserDetailsError:
            state.user = null;
            state.loading = false;
            break;
        default:
            break;
    }
}, initState);