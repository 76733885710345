import { connect } from 'react-redux';
import { actionParamsSelector } from '../../reducers/forms/skillNode';
import SchemaEditor from '../../components/SchemaEditor';
import { setActionParameters } from '../../actions/nodeForm';

export default connect(
    (state, props) => ({
        value: actionParamsSelector(state, props),
        // mode: "form",
        expand: true,
        label: "Parameters"
    }),
    (dispatch, {confId}) => ({
        onChange: params => {
            dispatch(
                setActionParameters({
                    confId, params
                })
            );
        }
    })
)(SchemaEditor);