import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUsers } from '../../actions/users';

function UserListFetcher({
    loading,
    filter,
    currentPage,
    pageSize,
    fetch,
    refresh
}) {
    
    useEffect(() => {
        if (!loading) {
            const tId = setTimeout(() => {
                fetch({
                    filter, currentPage, pageSize
                });
            }, 300);
            return () => clearTimeout(tId);
        }
    // eslint-disable-next-line
    }, [
        filter, currentPage, 
        pageSize, fetch, refresh
    ]);

    return null;
}

export default connect(
    state => ({
        loading: state.users.loading,
        filter: state.users.filter,
        currentPage: state.users.currentPage,
        pageSize: state.users.pageSize,
        refresh: state.users.refresh
    }),
    {
        fetch: getUsers
    }
)(UserListFetcher);