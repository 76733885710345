import { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { 
    getAvailableIntegrations,
    getIntegrations
} from '../../actions/integrations';
import {
    getActions
} from '../../actions/actions';

const log = Logger('IntegrationsRoot');

function IntegrationsRoot({
    fetched,
    fetch,
    intFetched,
    fetchIntegrations,
    actsFetched,
    fetchActions
}) {
    log.debug('render');
    useEffect(() => {
        if (!fetched) fetch();
    }, [fetched, fetch]);
    useEffect(() => {
        if (!intFetched) fetchIntegrations();
    }, [intFetched, fetchIntegrations]);
    useEffect(() => {
        if (!actsFetched) fetchActions();
    }, [actsFetched, fetchActions]);
    return  null;
}

IntegrationsRoot.propTypes = {
    fetched: PropTypes.bool.isRequired,
    intFetched: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchIntegrations: PropTypes.func.isRequired,
    fetchActions: PropTypes.func.isRequired,
    actsFetched: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        fetched: state.availableIntegrations.length ? true : false,
        intFetched: state.integrations.length ? true : false,
        actsFetched: state.actions.length ? true : false,
    }),
    {
        fetch: getAvailableIntegrations,
        fetchIntegrations: getIntegrations,
        fetchActions: getActions
    }
)(IntegrationsRoot);