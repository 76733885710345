import React, { memo, useMemo } from 'react';
import Input from '../Input';
import classnames from 'classnames';

function InputGroup({ 
    prepend,
    append,
    loading,
    ...props
}) {
    delete props.confId;

    const input = useMemo(() => (
        <Input {...props}/>
    ), [props]);

    if (!prepend) {
        return input;
    }

    return (
        <div className={classnames("input-group", {
            "is-invalid": props.className && props.className.indexOf('is-invalid') !== -1,
            "kt-spinner kt-spinner--v2 kt-spinner--md kt-spinner--info kt-spinner--right kt-spinner--input": loading
        })}>
            {
                prepend
                ? (
                    <div className="input-group-prepend">
                        <span className="input-group-text">{prepend}</span>
                    </div>
                ) : null
            }
            { input }
            {
                append
                ? (
                    <div className="input-group-append">
                        <span className="input-group-text">{append}</span>
                    </div>
                ) : null
            }
		</div>
    )
}

export default memo(InputGroup);