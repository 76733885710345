import React, { memo, useMemo } from 'react';
import PaginationLink from './Link';
import PageSize from './PageSize';
import PageDetail from './PageDetail';
import './index.sass';

const delta = 3;

function Pagination({
    goToPage,
    currentPage = 1,
    total = 0,
    pageSize = 10,
    setPageSize
}) {
    currentPage = parseInt(currentPage);
    total = parseInt(total);
    pageSize = parseInt(pageSize);

    const cantPrev = useMemo(() => currentPage === 1, [currentPage]);
    const totalPages =  useMemo(() => Math.ceil(total/pageSize), [total, pageSize]);
    const cantNext = useMemo(() => totalPages === currentPage, [currentPage, totalPages]);

    const prev = useMemo(() => {
        if (currentPage === 1) return null;
        const items = [];
        var n = currentPage - delta;
        if (n > 1) items.push(<PaginationLink key="0"/>);
        else n = 1;
        do {
            if (n > 0) items.push(<PaginationLink onClick={goToPage} key={n} type={n} />)
            n++;
        } while (n < currentPage);
        return items;
    }, [currentPage, goToPage]);
    
    const next = useMemo(() => {
        const a = totalPages - currentPage;
        if (a === 0) return null;
        const items = [];
        let i = 1;
        let n = a < delta ? a : delta;
        do {
            const key = currentPage + i;
            items.push(<PaginationLink onClick={goToPage} key={key} type={key} />)
            i++;
        } while (i <= n);
        if (currentPage + delta < totalPages) {
            items.push(<PaginationLink key="-0"/>);
        }
        return items;
    }, [currentPage, totalPages, goToPage]);

    if (!total) {
        return null;
    }

    return (
        <div className="kt-datatable kt-datatable--default">
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ul className="kt-datatable__pager-nav">
                    <PaginationLink disabled={cantPrev} onClick={goToPage} type="first" />
                    <PaginationLink disabled={cantPrev} onClick={goToPage} type="prev" />
                    { prev }
                    <PaginationLink type={currentPage} active />
                    { next }
                    <PaginationLink disabled={cantNext} onClick={goToPage} type="next" />
                    <PaginationLink disabled={cantNext} onClick={goToPage} type="last" />
                </ul>
                <div className="kt-datatable__pager-info">
                    <PageSize 
                        onSelect={setPageSize} 
                        value={pageSize}/>
                    <PageDetail 
                        currentPage={currentPage}
                        pageSize={pageSize}
                        total={total}
                    />    
                </div>
            </div>
        </div>
    )
}

export default memo(Pagination);