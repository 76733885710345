import React, { 
    memo
} from 'react';
import { Formik, Field, FastField } from 'formik';
import FInput from '../formik/Input';
import FSelect from '../formik/Select';
import FSchema from '../formik/Schema';
import { validateSchema, required } from '../validators';
import { useFormRef, useValues, useInputPrefix } from '../formik/hooks';
import Joi from '@hapi/joi';

const methods = [{
    value: 'GET',
    label: 'GET'
},{
    value: 'POST',
    label: 'POST'
},{
    value: 'PUT',
    label: 'PUT'
},{
    value: 'PATCH',
    label: 'PATCH'
},{
    value: 'DELETE',
    label: 'DELETE'
}];

const initialValues = { 
    url: '',
    method: methods[0].value,
    params: {
        "":""
    }
};

const validateCb = validateSchema(Joi.object({
    url: required('Action URL'),
    method: required('Request method'),
    params: Joi.object()
}));

function ServicenowForm({ 
    forwardedRef, 
    values, 
    integration 
}) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    const baseUrl = integration.baseUrl;
    const urlPrefix = useInputPrefix(baseUrl);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {() => (
                <React.Fragment>
                    <Field 
                        name="url" 
                        autoFocus={autoFocus}
                        label="Action url"
                        description="Specify action url"
                        component={FInput} 
                        prepend={urlPrefix}
                    />
                    <FastField 
                        name="method" 
                        label="Request method"
                        description="Specify request method"
                        component={FSelect}
                        options={methods}
                        convertToValues
                    />
                    <FastField 
                        name="params" 
                        label="Parameters"
                        description="Specify request parameters"
                        component={FSchema}
                    />
                </React.Fragment>
            )}
        </Formik>
    )
}

export default memo(ServicenowForm);