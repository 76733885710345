import {
    successMessage,
    errorMessage
} from '../constants';

export const success = payload => ({
    type: successMessage,
    payload
});

export const error = payload => ({
    type: errorMessage,
    payload
});