import React from 'react';
import { connect } from 'react-redux';
import { updateNodeBehavior } from '../../actions/nodeForm';
import { formBehaviorSelector } from '../../reducers/forms/skillNode';
import { currentNodeHasChilds } from '../../reducers/skill';
import { getNodeName } from '../../reducers/nodes';
import NodeSelector from '../Node/Selector';
import DynamicDropdown from '../../components/Dropdown/Dynamic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCommentAlt, 
    faCommentAltSlash, 
    faReply
} from '@fortawesome/pro-duotone-svg-icons';

const options = [{
    text: 'Wait for user response',
    onClick: {
        skipUserInput: false
    },
    icon: faCommentAlt,
    active: ({skipUserInput, jumpTo}) => !jumpTo && !skipUserInput
},{
    text: 'Skip the user response',
    onClick: {
        skipUserInput: true
    },
    icon: faCommentAltSlash,
    active: ({skipUserInput, jumpTo}) => !jumpTo && skipUserInput,
    disabled: ({hasChilds}) => {
        if (!hasChilds) return true;
        return false;
    }
},{
    text: 'Redirect to ...',
    onClick: ({preventClose, render, onSelect}) => {
        preventClose();
        render(<NodeSelector onSelect={jumpTo => onSelect({jumpTo})}/>)
    },
    active: ({jumpTo}) => jumpTo ? true : false,
    icon: faReply
}];

function Toggle({
    nodeName, skipUserInput
}) {

    let label = options[0].text;
    let icon = options[0].icon;
    if (nodeName) {
        label = `Redirect to: ${nodeName}`;
        icon = options[2].icon;
    } else if (skipUserInput) {
        label = options[1].text;
        icon = options[1].icon;
    }
    
    return (
        <React.Fragment>
            <FontAwesomeIcon 
                icon={icon} 
                size="lg"
                style={{
                    marginRight: '0.5rem'
                }}
            />
            { label }
        </React.Fragment>
    );
}

export default connect(
    state => {
        const hasChilds = currentNodeHasChilds(state);
        const { skipUserInput, jumpTo } = formBehaviorSelector(state);
        let nodeName = null;
        if (jumpTo) nodeName = getNodeName(state, {id:jumpTo});
        return {
            toggle: <Toggle nodeName={nodeName} skipUserInput={skipUserInput}/>,
            value: { skipUserInput, jumpTo, hasChilds },
            options,
            alignRight: true
        };
    },
    {
        onSelect: updateNodeBehavior
    }
)(DynamicDropdown);