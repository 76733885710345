import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

function preventBubblingUp(e) {
    e.preventDefault();
}

function FontA({
    onClick,
    icon,
    label
}) {
    return (
        <button
            onMouseDown={preventBubblingUp}
            className={classnames("btn btn-clean", {
                'btn-icon': !label
            })}
            onClick={onClick}
            type="button"
        >
            <i><FontAwesomeIcon icon={icon}/></i>
            {label}
        </button>
    )
}

export default FontA;