import React, { memo } from 'react';
import Logger from '../../utils/logger';
import classnames from 'classnames';
import './index.sass';

const log = Logger('Icon');

function Icon({
    children,
    onClick,
    className,
    active,
    ...props
}) {
    log.debug('render');
    return (
        <span 
            className={classnames('icon-hover-fx', className, {
                active
            })}
            onClick={onClick}
            {...props}
        >
            { children }
        </span>
    )
}

export default memo(Icon);