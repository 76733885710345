import { connect } from 'react-redux';
import InputGroup from '../../components/InputGroup';
import { onActionResponseChange } from '../../actions/nodeForm';
import { actionResSelector } from '../../reducers/forms/skillNode';

const _onChange = confId => e => onActionResponseChange({
    confId,
    value: e.target.value
});

export default connect(
    (state, props) => ({
        prepend: 'context.',
        placeholder: 'actionResult',
        value: actionResSelector(state, props)
    }),
    (dispatch, {confId}) => {
        const onChange = _onChange(confId);
        return {
            onChange: e => dispatch(onChange(e))
        };
    }
)(InputGroup);