import React, { useMemo, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';

const log = Logger('BodyWrapper');

function updateClasses(layout, el) {
    const cl = el.classList;
    Object.keys(layout).forEach(key => {
        const name = `kt-${key}--enabled`;
        if (layout[key]) cl.add(name);
        else cl.remove(name);
    });
}

function El({ children, layout }) {
    log.debug('render');

    const ref = useRef();

    useLayoutEffect(() => {
        updateClasses(layout, ref.current);
    }, [ layout ]);

    useLayoutEffect(() => {
        ref.current.classList.remove('kt-page--loading');
    }, [ layout ]);

    return useMemo(() => (
        <div id="body-wrapper" ref={ref} className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    { children }
                </div>
            </div>
        </div>
    ), [ children ]);
}

El.propTypes = {
    layout: PropTypes.object.isRequired
};

export default connect(
    state => ({
        layout: state.layout.config
    })
)(El);

