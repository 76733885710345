import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './index.sass';

function UserCard({
    firstName,
    lastName,
    description,
    size,
    circle,
    type,
    details
}) {
    return (
        <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__pic">
                <div className={classnames("kt-media", `kt-media--${type || 'brand'}`, {
                    'kt-media--circle': circle,
                    [`kt-media--${size}`]: size
                })}>
                    <span>{firstName ? firstName.charAt(0) : '-'}{lastName ? lastName.charAt(0) : ''}</span>
                </div>						
            </div>
            {
                typeof details !== 'undefined'
                ? details
                : (
                    <div className="kt-user-card-v2__details">									
                        <span className="kt-user-card-v2__name">{firstName} {lastName}</span>	
                        {
                            description
                            ? <span className="kt-user-card-v2__desc">{description}</span>
                            : null
                        }								
                    </div>
                )
            }
        </div>
    )
}

export default memo(UserCard);

export const UserCardLink = memo(({link, ...props}) => (
    <Link to={link}>
        <UserCard {...props} />
    </Link>
));