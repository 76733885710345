import React, { memo, useEffect, useRef, useMemo, useCallback } from 'react';
import TagsInput from 'react-tagsinput';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutosizeInput from 'react-input-autosize';
import './index.sass';

function Tag({
    tag, key, disabled, onRemove, 
    classNameRemove, getTagDisplayValue, 
    ...props
}) {
    return (
        <span key={key} {...props} className="btn btn-label-primary">
            {getTagDisplayValue(tag)}
            {!disabled && <i className="remove-icon" onClick={() => onRemove(key)}><FontAwesomeIcon icon={faTimes}/></i>}
        </span>
    )
}

function Input ({
    onChange, value, addTag, ...other
}) {
    return (
        <span className="btn btn-label-secondary">
            <AutosizeInput 
                type="text" 
                inputClassName="quick-reply-input"
                placeholderIsMinWidth 
                onChange={onChange} 
                value={value} 
                {...other} 
            />
        </span>
    )
}

function QuickReply({
    data, mergeEntityData,
    enableEditor, disableEditor
}){
    const ref = useRef();
    const onChange = useCallback(items => mergeEntityData({ items }), [mergeEntityData]);
    const {inputProps, onBlockClick} = useMemo(() => {
        return {
            onBlockClick: e => {
                e.preventDefault();
                e.stopPropagation();
                disableEditor(); 
                ref.current.focus();
            },
            inputProps: {
                onFocus: (e) => {
                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    disableEditor();
                },
                onBlur: () => enableEditor(),
                placeholder: 'Add quick reply'
            }
        };
    }, [disableEditor, enableEditor]);

    useEffect(() => {
        if (data.isNew) {
            disableEditor();
            ref.current.focus();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div 
            onMouseUp={onBlockClick}
        >
            <TagsInput 
                className="quick-reply"
                inputProps={inputProps}
                ref={ref}
                addOnBlur
                value={data.items || []}
                onChange={onChange}
                renderTag={Tag}
                renderInput={Input}
            />
        </div>
    )
}

export default memo(QuickReply);