import React, { 
    memo, useCallback
} from 'react';
import { Formik, FastField } from 'formik';
import FInput from './formik/Input';
import { validateSchema, required, jIsUrl, jError } from './validators';
import { useFormRef, useValues } from './formik/hooks';
import Wrap from './components/FormWrap';
import Joi from '@hapi/joi';

const initialValues = { 
    name: '',
    baseUrl: '',
    type: 'sap',
    username: '',
    password: ''
};

const validateCb = validateSchema(Joi.object({
    name: required('Integration Name'),
    baseUrl: Joi.string().required().custom(jIsUrl).error(jError({
        'string.empty': 'Base URL is required'
    })),
    type: Joi.any(),
    username: required('Username'),
    password: required('Password')
}));

function SapForm({ onSubmit, forwardedRef, values }) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    const _onSubmit = useCallback(() => {
        if (onSubmit) onSubmit(forwardedRef.current.getValues());
    }, [onSubmit, forwardedRef]);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmit}
        >
            {() => (
                <Wrap>
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Integration Name"
                        description="Specify integration name"
                        component={FInput} 
                    />
                    <FastField 
                        name="baseUrl" 
                        label="SAP Base URL"
                        description="SAP Instance Base URL"
                        component={FInput} 
                    />
                    <FastField 
                        name="username" 
                        label="SAP User Name"
                        component={FInput} 
                        description="SAP User Name"
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="password" 
                        label="SAP User Password"
                        type="password" 
                        component={FInput} 
                        description="SAP User Password"
                        autoComplete="new-password"
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(SapForm);