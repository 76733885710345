import {
    typeNewIntent,
    typeCurrentIntent,
    typeDeleteIntent,
    fetchTrainingModel,
    fetchTrainingModelSuccess
} from '../constants';

export default function currentIntent(state = null, { type, payload }) {
    switch (type) {
        case typeNewIntent:
        case typeCurrentIntent:
            return payload;
        case typeDeleteIntent: 
            return payload === state ? null : state;
        case fetchTrainingModelSuccess:
        case fetchTrainingModel:
            return null;
        default:
            return state;
    }
}