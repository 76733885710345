import {
    fetchAnalytics,
    fetchUsersAnalytics,
    fetchSkillsAnalytics,
    ANALYTICS_FILTER_BY_DATE,
    clearAnalyticsSkillsFilter,
    analyticsSetSkillsPageSize,
    analyticsGoToSkillsPage
} from '../constants';

import { apiAction } from './api';

export const getAnalytics = payload => apiAction({
    path: `/analytics`,
    name: fetchAnalytics,
    qs: payload
});

export const setDates = (from, to) => ({
    type: ANALYTICS_FILTER_BY_DATE,
    payload: {from, to}
});

export const fetchUsers = payload => apiAction({
    path: `/analytics/users`,
    name: fetchUsersAnalytics,
    qs: payload
});

export const fetchSkills = payload => apiAction({
    path: `/analytics/skills`,
    name: fetchSkillsAnalytics,
    qs: payload
});

export const clearSkillsFilter = () => ({
    type: clearAnalyticsSkillsFilter
});

export const setSkillsPageSize = payload => ({
    type: analyticsSetSkillsPageSize,
    payload
});

export const goToSkillsPage = payload => ({
    type: analyticsGoToSkillsPage,
    payload
});