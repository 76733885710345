import React, { memo, useEffect } from 'react';
import * as MySwal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import KTInfobox from '../KTInfobox';
import { 
    faCheck
    // faAt, 
    // faSnowboarding, faSkiing
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.sass';

export const storageKey = 'showWelcomeMessage';

const Link = ({href, children}) => {
    return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="kt-link">{children}</a>
    )
}

const Swal = withReactContent(MySwal);
const message = (
    <KTInfobox
        title={
            <React.Fragment>
                <FontAwesomeIcon 
                    className="kt-font-success kt-margin-r-20" 
                    icon={faCheck}
                />
                <span className="kt-font-success">Welcome to the Hala Platform!</span>
            </React.Fragment>
        }
    >
        Thank you for your interest in exploring the Hala solution.
        <br/><br/>
        We have created quick-start guides and video tutorials that can help you to start exploring the capabilities of the solution
        <br/><br/>
        Documentation:
        <br/>
        <Link href="https://docs.hala.ai/quick-start">Getting started with Hala</Link>
        <br/>
        <Link href="https://docs.hala.ai/how-to/create-a-simple-skill">Create a simple skill</Link>
        <br/>
        <Link href="https://docs.hala.ai/how-to/create-the-weather-skill">Create the weather skill</Link>
        <br/>
        <Link href="https://docs.hala.ai/how-to/create-skill-for-sap">Create skill for SAP ERP</Link>
        <br/>
        <Link href="https://www.youtube.com/watch?v=3sIX8u6dhc0&t=22s">Platform capabilities</Link>
        <br/><br/>
        If you have any questions, you can join our <Link href="https://join.slack.com/t/hala-support/shared_invite/enQtNzc3MjUxNDQ2NTc3LTAxY2I1MDYxNDA5Mzg5NTI2NTM1YWY0YWIyMjcxZmU1Mzk2ODRlZmU5ZDgyMTA4NTUwNWY3NzRkZWYwODZhOTA">Slack channel</Link>
        <br/><br/>
        Regards,<br/>
        Hala team<br/>
    </KTInfobox>
);
const swalOpt = {
    customClass: {
        container: "welcome-message",
    },
    showConfirmButton: false,
    showCloseButton: true,
    width: 700,
    html: message,
    onClose: () => {
        window.localStorage.setItem(storageKey, false);
    }
};

function WelcomeMessage() {
    useEffect(() => {
        if (window.localStorage.getItem(storageKey) === 'true') {
            Swal.fire(swalOpt);
        }
    }, []);
    return null;
}

export default memo(WelcomeMessage);