import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import SubHeader from '../../components/SubHeader';
import { newEntity } from '../../actions/entity';
import { Button } from 'react-bootstrap';
import TrainingDataStatus from '../TrainingDataStatus';

const log = Logger('EntitiesSubHeader');

function EntitiesSubHeader({newEntity}) {
    log.debug('render');

    return <SubHeader
        title="Entities"
        toolbar={<TrainingDataStatus/>}
        leftToolbar={
            <Button onClick={() => newEntity()} variant="btn" className="btn-label-success btn-bold">
                New Entity
            </Button>
        }
    />
}

EntitiesSubHeader.propTypes = {
    newEntity: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        newEntity
    }
)(EntitiesSubHeader);