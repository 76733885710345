import React, { memo } from 'react';
import LongText from '../../LongText';
import FormGroup from '../components/FormGroup';

function FormLongText({ 
    field, 
    form: {
        errors
    },
    label,
    sensitive,
    description,
    onChange,
    error,
    ...props
}) {
    const _error = error || errors[field.name];
    
    return (
        <FormGroup 
            label={label}
            error={_error}
            description={description}
        >
            <LongText 
                error={_error}
                {...field}
                {...props}
            />
        </FormGroup>
    )
}

export default memo(FormLongText);