import React, { memo } from 'react';
import Input from './components/Input';
import Form from './components/Form';
import Select from './components/Select2';
import FormGroup from './components/FormGroup';
import Joi from '@hapi/joi';
import { EmailValidate } from './validators';

const options = [{
    value: 'User',
    label: 'User',
    isFixed: true
},{
    value: 'Admin',
    label: 'Admin'
},{
    value: 'Developer',
    label: 'Developer'
}];

function UserForm({ onSubmit, forwardedRef, values }) {
    const {email, firstName, lastName, roles} = values || {};

    return (
        <Form 
            onSubmit={onSubmit}
            actions={false}
            forwardedRef={forwardedRef}
        >
            <Input
                row={true}
                value={email || null}
                label="Email *"
                formKey="email"
                validate={EmailValidate}
            />
            <Input
                row={true}
                value={firstName || null}
                label="First name *"
                formKey="firstName"
                validate={Joi.string().required().error(() => 'First name is required')}
            />
            <Input
                row={true}
                value={lastName || null}
                label="Last name *"
                formKey="lastName"
                validate={Joi.string().required().error(() => 'Last name is required')}
            />
            <FormGroup 
                row={true}
                label="Roles"
                description="specify user roles (optional)"
            >
                <Select
                    value={roles || []}
                    formKey="roles"
                    options={options}
                    convertToValues
                    isMulti={true}
                    closeMenuOnSelect={false}
                />
            </FormGroup>
        </Form>
    )
}

export default memo(UserForm);