import React, { memo } from 'react';

function SkillStatus({val}) {
    let type;
    switch(val) {
        case "completed":
            type = 'success';
            break;
        case "failed":
        case "error":
            type = 'danger';
            break;
        case "escaped":
            type = 'warning';
            break;
        case "processing":
        case "current":
            type = 'info';
            break;
        default: 
            type = 'dark';
            break;
    }

    return (
        <span className={`kt-badge kt-badge--inline kt-badge--${type}`}>{val}</span>
    )
}

export default memo(SkillStatus);