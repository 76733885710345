import React, { memo, useRef } from 'react';
import { Formik, FastField } from 'formik';
import FInput from '../formik/Input';
import FSelect from '../formik/Select';
import Wrap from '../components/FormWrap';
import OptionalLabel from '../components/OptionalLabel';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import { 
    validateSchema, required,
    jIsUrl, jError
} from '../validators';
import BasicForm from './Basic';

const grantTypes = [{
    value: 'clientCredentials',
    label: 'Client Credentials'
}, {
    value: 'passwordCredentials',
    label: 'Password Credentials'
}];

const initialValues = { 
    grantType: grantTypes[0].value,
    tokenUrl: '',
    clientId: '',
    clientSecret: '',
    scope: ''
};

const validateCb = validateSchema(Joi.object({
    grantType: required('Grant Type'),
    tokenUrl: Joi.string().required().custom(jIsUrl).error(jError({
        'string.empty': 'Access Token URL is required'
    })),
    clientId: required('Client ID'),
    clientSecret: required('Client Secret'),
    scope: Joi.any()
}));

const ScopeDescription = (
    <OptionalLabel>
        The scope of the access request. It may have multiple space-delimited values. e.g. read:org
    </OptionalLabel>
)

function OAuth2Form({
    onSubmit, forwardedRef, 
    values, nested
}) {
    const nestedRef = useRef();
    const ref = useFormRef(forwardedRef, [nestedRef]);
    const {vals, autoFocus} = useValues(values, initialValues);
    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {({values: {grantType}}) => (
                <Wrap nested={nested}>
                    <FastField 
                        name="grantType" 
                        component={FSelect} 
                        label="Grant Type"
                        autoFocus={autoFocus}
                        options={grantTypes}
                        convertToValues
                    />
                    <FastField 
                        name="tokenUrl" 
                        label="Access Token URL"
                        description="The endpoint for authentification server. This is used to exchange the authorization data for an access token."
                        component={FInput} 
                    />
                    {
                        grantType === 'passwordCredentials'
                        ? <BasicForm values={values} nested forwardedRef={nestedRef} />
                        : null
                    }
                    <FastField 
                        name="clientId" 
                        label="Client ID"
                        description="The client identifier issued to the client during the Application registration process."
                        component={FInput}
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="clientSecret" 
                        label="Client Secret"
                        description="The client secret issued to the client during the Application registration process."
                        type="password"
                        component={FInput}
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="scope" 
                        label="Scope"
                        description={ScopeDescription}
                        component={FInput} 
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(OAuth2Form);