import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import './index.sass';

function Confirmation({
    onYes, onClose
}) {
    return (
        <div className="confirmation">
            <span className="lead">Are you sure?</span>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <Button onClick={onYes} variant="clean" size="sm">YES</Button>
                    </div>
                    <div className="col">
                        <Button onClick={onClose} variant="clean" size="sm">NO</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Confirmation);