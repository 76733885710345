import { combineReducers } from 'redux';
import nodes from './nodes';
import skills from './skills';
import layout from './layout';
import skill from './skill';
import notification from './notification';
import reduceReducers from 'reduce-reducers';
import postReducer from './postReducer';
import entities from './entities';
import intents from './intents';
import currentIntent from './currentIntent';
import currentUtterance from './currentUtterance';
import currentEntity from './currentEntity';
import training from './training';
import availableIntegrations from './availableIntegrations';
import integrations from './integrations';
import integrationsState from './integrationsState';
import actions from './actions';
import actionsState from './actionsState';
import forms from './forms';
import users from './users';
import auth from './auth';
import analytics from './analytics';
import {
    typeSignOut
} from '../constants';

const rootReducer = combineReducers({
    auth,
    nodes,
    skills,
    layout,
    skill,
    notification,
    entities,
    intents,
    currentIntent,
    currentUtterance,
    currentEntity,
    training,
    availableIntegrations,
    integrations,
    integrationsState,
    actions,
    actionsState,
    forms,
    users,
    analytics
});

export default (state, action) => {
    if (action.type === typeSignOut) {
        state = undefined;
    }
    return reduceReducers(rootReducer, postReducer)(state, action);
};