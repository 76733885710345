import React from 'react';
import { faUnderline } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createInlineStyleButton } from 'draft-js-buttons';

export default createInlineStyleButton({
    style: 'UNDERLINE',
    children: (
        <FontAwesomeIcon icon={faUnderline} />
    )
});