import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { submitNewUserDetails } from '../../actions/users';
import Checkbox from '../../components/Checkbox';
import classnames from 'classnames';
import Joi from '@hapi/joi';
import { required } from '../../components/Forms/validators';
import { storageKey } from '../../components/WelcomeMessage';

const signUpSchema = Joi.object({
    firstName: required('First name'),
    lastName: required('Last name'),
    terms_and_conditions: Joi.any(),
    newsletters: Joi.any()
});

function NewUserForm({loading, submitNewUserDetails}) {
    const [state, setUserData] = useState({
        firstName: '',
        lastName: '',
        terms_and_conditions: false,
        newsletters: false
    });
    const [errors, setErrors] = useState({});

    const handleChange = useCallback(e => {
        const newSt = {
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        };
        setUserData(st => ({
            ...st,
            ...newSt
        }));
    }, []);

    const submit = useCallback(e => {
        e.preventDefault();
        const {error} = signUpSchema.validate(state, { 
            abortEarly: false 
        });
        let errors = {};
        if (error) {
            error.details.forEach(({message, path}) => {
                errors[path[0]] = message;
            });
        }
        if (state.terms_and_conditions !== true) {
            errors.terms_and_conditions = 'Please agree to all the terms and conditions before Sing Up';
        }
        setErrors(errors);
        if (!error && state.terms_and_conditions === true) {
            window.localStorage.setItem(storageKey, true);
            submitNewUserDetails(state);
        }
    }, [state, submitNewUserDetails]);

    return (
        <div className="kt-login__signin">
            <div className="kt-login__head">
                <div className="kt-login__title">
                    Additional user details
                </div>
            </div>
            <form onSubmit={submit} className="kt-form">
                <div className="input-group">
                    <input
                        className={classnames("form-control",{
                            'is-invalid': errors.firstName
                        })}
                        autoFocus
                        placeholder="First name" 
                        name="firstName"
                        onChange={handleChange}
                        value={state.firstName}
                    />
                    {
                        errors.firstName
                        ? <div className="invalid-feedback">{errors.firstName}</div>
                        : null
                    }
                </div>
                <div className="input-group">
                    <input 
                        className={classnames("form-control",{
                            'is-invalid': errors.lastName
                        })}
                        placeholder="Last name" 
                        name="lastName"
                        onChange={handleChange}
                        value={state.lastName}
                    />
                    {
                        errors.lastName
                        ? <div className="invalid-feedback">{errors.lastName}</div>
                        : null
                    }
                </div>
                <div className="kt-margin-t-30">
                    <Checkbox 
                        onChange={handleChange}
                        name="terms_and_conditions"
                        className={classnames({
                            'is-invalid': errors.terms_and_conditions
                        })}
                        type={['tick', 'brand']}
                        label={
                            <React.Fragment>
                                <div>
                                    <span>I agree with Hala's </span>
                                    <a
                                        href="https://www.hala.ai/terms-and-conditions"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        terms and conditions.
                                    </a>
                                </div>
                            </React.Fragment>
                        }
                    />
                    {
                        errors.terms_and_conditions
                        ? <div className="invalid-feedback">{errors.terms_and_conditions}</div>
                        : null
                    }
                </div>
                <div className="kt-margin-t-10">
                    <Checkbox 
                        onChange={handleChange}
                        name="newsletters"
                        type={['tick', 'brand']}
                        label="I'd like to receive occasional insights, Skill store updates, offers and product updates from Hala."
                    />
                </div>
                <div className="kt-login__actions">
                    <button 
                        type="submit"
                        className="btn btn-brand btn-elevate kt-login__btn-primary"
                        disabled={loading}
                    >Submit</button>
                </div>
            </form>
        </div>
    )
}

export default connect(
    state => ({
        loading: state.auth.loading
    }),
    {
        submitNewUserDetails
    }
)(NewUserForm);