import React, { useCallback, memo, useMemo } from 'react';
import { connect } from 'react-redux';
import Logger from '../../utils/logger';
import ActionSelectorDropdown from '../ActionSelector/Dropdown';
import ActionParameters from './ActionParameters';
import { onActionSelect, deleteAction } from '../../actions/nodeForm';
import ActionName from '../ActionName';
import FormGroup from '../../components/Forms/components/FormGroup';
import { 
    actionIdByConfId, actionParamsSelector
} from '../../reducers/forms/skillNode';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionResponse from './ActionResponse';
import './ActionConfig.sass';

const log = Logger('ActionConfig');

const DropdownToggle = memo(({id}) => id ? <ActionName id={id}/> : 'Select Action');

function ActionConfig({
    id,
    confId,
    onActionSelect,
    deleteAction,
    hasParams
}) {
    log.debug('render');
    const _onActionSelect = useCallback(action => {
        onActionSelect && onActionSelect({
            action, confId
        });
    }, [confId, onActionSelect]);
    const _onDel = useCallback(() => {
        deleteAction && deleteAction(confId);
    }, [confId, deleteAction]);
    const _toggle = useMemo(() => <DropdownToggle id={id} />, [id]);
    return (
        <div className="action-config">
            <ActionSelectorDropdown 
                id={id}
                onSelect={_onActionSelect}
                toggle={_toggle}
            />
            {
                hasParams
                ? <ActionParameters
                    id={id}
                    confId={confId}
                />
                : null
            }
            <FormGroup
                label="Action response"
                description="Action response will be saved into context variable"
                row={false}
                className="kt-padding-10 kt-margin-b-0"
            >
                <ActionResponse confId={confId} />
            </FormGroup>
            <FontAwesomeIcon 
                onClick={_onDel}
                className="remove-action" 
                icon={faTimes}
            />
        </div>
    )
}

export default connect(
    (state, props) => ({
        id: actionIdByConfId(state, props),
        hasParams: actionParamsSelector(state, props) ? true : false
    }),
    { onActionSelect, deleteAction }
)(ActionConfig);