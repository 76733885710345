import React from 'react';
import classNames from'classnames';
import './loader.sass';

const PulseLoader = ({width = 64, className, ...props}) => (
    <div {...props} className={classNames("pulse-loader", className)}>
        <svg width={width} viewBox="0 0 64 64">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="hl-line1">
                    <path d="M57.229593,53.4808941 L53.1444866,53.4808941 C53.1444866,42.576208 44.3044919,33.7362132 33.3998058,33.7362132 L33.3998058,29.6511068 C46.5606339,29.6511068 57.229593,40.320066 57.229593,53.4808941 Z" id="Oval" fill="#483BD4" fillRule="nonzero" mask="url(#mask-4)" transform="translate(45.314699, 41.566000) rotate(81.000000) translate(-45.314699, -41.566000) "></path>
                    <path d="M49.7381537,33.8623995 L45.6530473,33.8623995 C45.6530473,22.9577134 36.8130526,14.1177187 25.9083665,14.1177187 C15.0036803,14.1177187 6.16368565,22.9577134 6.16368565,33.8623995 L2.07857926,33.8623995 C2.07857926,20.7015714 12.7475384,10.0326123 25.9083665,10.0326123 C39.0691945,10.0326123 49.7381537,20.7015714 49.7381537,33.8623995 Z" id="Oval" fill="#483BD4" fillRule="nonzero" mask="url(#mask-2)" transform="translate(25.908366, 21.947506) rotate(-34.000000) translate(-25.908366, -21.947506) "></path>
                </g>
                <g className="hl-line2">
                    <path d="M32,64 L32,59.9148936 C47.41697,59.9148936 59.9148936,47.41697 59.9148936,32 C59.9148936,16.58303 47.41697,4.08510638 32,4.08510638 C16.58303,4.08510638 4.08510638,16.58303 4.08510638,32 L-7.10542736e-15,32 C-7.10542736e-15,14.326888 14.326888,0 32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 Z" id="Oval" fill="#483BD4" fillRule="nonzero" mask="url(#mask-6)" transform="translate(32.000000, 32.000000) rotate(95.000000) translate(-32.000000, -32.000000) "></path>
                </g>
                <g className="hl-line3">
                    <path d="M32,47.6595745 L32,43.5744681 C38.3924022,43.5744681 43.5744681,38.3924022 43.5744681,32 C43.5744681,27.9398893 41.4690711,24.2447609 38.0779275,22.1475439 C36.266413,21.0272319 34.1800295,20.4255319 32,20.4255319 C25.6075978,20.4255319 20.4255319,25.6075978 20.4255319,32 L16.3404255,32 C16.3404255,23.3514558 23.3514558,16.3404255 32,16.3404255 C34.9445962,16.3404255 37.7740798,17.1564309 40.2266143,18.673175 C44.8093474,21.507318 47.6595745,26.5096785 47.6595745,32 C47.6595745,40.6485442 40.6485442,47.6595745 32,47.6595745 Z" id="Oval" fill="#483BD4" fillRule="nonzero" mask="url(#mask-8)" transform="translate(32.000000, 32.000000) rotate(133.000000) translate(-32.000000, -32.000000) "></path>
                </g>
            </g>
        </svg>
    </div>
);

export default PulseLoader;