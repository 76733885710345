import React, { memo } from 'react';

function El() {
    return (
        <div>
            Page Not Found
        </div>
    )
}

export default memo(El);