import React, { memo } from 'react';
import DocInfo from './DocInfo';

function InfoBlock({
    icon, children
}) {
    return (
        <div className="row">
            <div className="col">
                <div className="alert alert-light alert-elevate">
                    {
                        icon
                        ? <div className="alert-icon">{icon}</div>
                        : null
                    }
                    <div className="alert-text">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};

export const DocInfoBlock = DocInfo;
export default memo(InfoBlock);