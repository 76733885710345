import React, { memo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import UserCard from '../UserCard';
import './index.sass';

function ChatMessages({
    data, ...props
}) {
    return (
        <div className="kt-chat chat-messages" {...props}>
            <div className="kt-chat__messages">
            {
                data.map((i, k) => (
                    <div key={k} className="kt-chat__message">
                        <div className={classnames("kt-chat__text", {
                            'kt-bg-light-success': !i.isRes,
                            'kt-bg-light-brand': i.isRes
                        })}>
                            <div className="kt-chat__user">
                                <span className="kt-chat__username">
                                    <UserCard 
                                        firstName={i.firstName} 
                                        lastName={i.lastName} 
                                        size="sm"
                                        circle
                                        type={!i.isRes ? 'success' : null}
                                    />
                                </span>
                                <span className="kt-chat__datetime">
                                    on { moment(i.date).format('llll') }
                                </span>
                            </div>
                            <div className="kt-chat__content">{ i.content }</div>
                        </div>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default memo(ChatMessages);