import React, { useEffect } from 'react';
import Logger from '../../utils/logger';
import Aside from '../Aside';
import Header from '../Header';
import BodyWrapper from '../BodyWrapper';
import MainRoutes from '../Router/MainRoutes';
import HeaderRoutes from '../Router/HeaderRoutes';
import AuthLayout from '../../components/AuthLayout';
import { connect } from 'react-redux';
import { getUserInfo } from '../../actions/users';
import NewUserForm from '../NewUserForm';
import WelcomeMessage from '../../components/WelcomeMessage';
import './index.sass';

const log = Logger('MainPage');

function MainPage({
    getUserInfo,
    isNewUser,
    loading
}) {
    log.debug('render');
    
    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);

    if (loading || isNewUser) {
        return (
            <AuthLayout loading={loading}>
                {
                    isNewUser
                    ? <NewUserForm/>
                    : null
                }
            </AuthLayout>
        )
    }

    return (
        <BodyWrapper>
            <Aside/>
            <div id="kt_wrapper" className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                <Header 
                    leftContent={<HeaderRoutes/>}
                />
                <MainRoutes/>
            </div>
            <WelcomeMessage/>
        </BodyWrapper>
    )
}

export default connect(
    state => ({
        isNewUser: state.auth.user.isNew || false,
        loading: state.auth.loading
    }),
    {
        getUserInfo
    }
)(MainPage);