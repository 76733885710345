import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
    moveNode, triggerMoveNode
} from '../../actions/node';
import classnames from 'classnames';
import './MoveNodeActions.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faUndo, 
    faStream,
    faSortSizeDownAlt,
    faSortSizeUp,
} from '@fortawesome/pro-duotone-svg-icons';

function MoveNodeActions({ 
    id, moveNodeId, move, cancel
}) {
    const [hover, setHover] = useState(false);
    const isCurrent = moveNodeId === id;
    const onMouseEnter = useCallback(() => setHover(true), []);
    const onMouseLeave = useCallback(() => setHover(false), []);
    const content = useMemo(() => {
        if (isCurrent) {
            return (
                <div className="row">
                    <div className="col col-xl-8">
                        Select the node to which you would like to move current node
                    </div>
                    <div className="col col-xl-4">
                        <button 
                            onClick={() => cancel()}
                            type="button" 
                            className="btn btn-label-danger btn-bold"
                        > 
                            <i>
                                <FontAwesomeIcon icon={faUndo}/>
                            </i>
                            Cancel
                        </button>
                    </div>
                </div>
            );
        }
        let moveObj = {
            id, moveNodeId
        };
        return (
            <div className="row">
                <div className="col col-xl-4">
                    <button 
                        onClick={() => move({...moveObj, type: 'above'})}
                        type="button" 
                        className="btn btn-label-success btn-bold"
                    > 
                        <i>
                            <FontAwesomeIcon icon={faSortSizeUp}/>
                        </i>
                        move node above
                    </button>
                </div>
                <div className="col col-xl-4">
                    <button 
                        onClick={() => move({...moveObj, type: 'below'})}
                        type="button" 
                        className="btn btn-label-success btn-bold"
                    > 
                        <i>
                            <FontAwesomeIcon icon={faSortSizeDownAlt}/>
                        </i>
                        move node below
                    </button>
                </div>
                <div className="col col-xl-4">
                    <button 
                        onClick={() => move({...moveObj, type: 'child'})}
                        type="button" 
                        className="btn btn-label-success btn-bold"
                    > 
                        <i>
                            <FontAwesomeIcon icon={faStream}/>
                        </i>
                        move node as a child
                    </button>
                </div>
            </div>
        );
    }, [isCurrent, cancel, move, moveNodeId, id]);

    return (
        <div 
            className={classnames("move-node-actions", {
                hover: hover && !isCurrent,
                isCurrent
            })}
            onMouseEnter={moveNodeId ? onMouseEnter : null} 
            onMouseLeave={moveNodeId ? onMouseLeave : null}
        >
            { content }
        </div>
    )
}

export default connect(
    state => ({
        moveNodeId: state.skill.moveNode
    }),
    {
        move: moveNode,
        cancel: triggerMoveNode
    }
)(MoveNodeActions);