import {
    typeNewEntity,
    typeCurrentEntity,
    typeUpdateEntityName,
    typeDeleteEntity,
    typeNewEntityValue,
    typeEntityValueName,
    typeDeleteEntitySynonym,
    typeAddEntitySynonym,
    typeDeleteEntityValue,
    typeAddValueToEntity
} from '../constants';
import uuid from 'uuid/v1';

export function newEntity(payload = {}) {
    if (!payload.id) payload.id = uuid();
    return {
        type: typeNewEntity,
        payload
    };
}

export function currentEntity(payload) {
    return {
        type: typeCurrentEntity,
        payload
    };
}

export function updateEntityName(name, entity) {
    return {
        type: typeUpdateEntityName,
        payload: {
            entity,
            name
        }
    };
}

export function deleteEntity(payload) {
    return {
        type: typeDeleteEntity,
        payload
    };
}

export function newEntityValue(entity) {
    return {
        type: typeNewEntityValue,
        payload: {
            entity,
            valueId: uuid()
        }
    };
}

export function entityValueName(name, valueId, entity) {
    return {
        type: typeEntityValueName,
        payload: {
            entity,
            valueId,
            name
        }
    };
}

export function deleteEntitySynonym(valueId, synonymId, entity) {
    return {
        type: typeDeleteEntitySynonym,
        payload: {
            entity,
            valueId,
            synonymId
        }
    };
}

export function addEntitySynonym(valueId, synonym, entity) {
    return {
        type: typeAddEntitySynonym,
        payload: {
            entity,
            valueId,
            synonym
        }
    };
}

export function deleteEntityValue(valueId, entity) {
    return {
        type: typeDeleteEntityValue,
        payload: {
            entity,
            valueId
        }
    };
}

export function addValueToEntity(entity, name) {
    return (dispatch, getState) => {
        const {entities} = getState();
        const exist = Object.keys(entities).find(id => {
            return entities[id].name === name;
        });
        if (!exist) dispatch({
            type: typeAddValueToEntity,
            payload: {
                entity,
                valueId: uuid(),
                name
            }
        });
    };
}