import React, {useMemo} from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../components/SubHeader';
import Toolbar from '../../components/SubHeader/Actions';
import { getIntegrationSelector } from '../../reducers/integrations';

function MySubHeader({
    formRef, id, loading, name
}) {
    const isNew = id ? false : true;
    const toolbar = useMemo(() => (
        <Toolbar 
            submit={() => formRef.current && formRef.current.submit()}
            loading={loading}
            submitText={isNew ? 'Save' : null}
        />
    ), [loading, formRef, isNew]);

    return (
        <SubHeader 
            toolbar={toolbar}
            title={isNew ? "New Integration" : `Edit Integration - ${name}`}
            desc={isNew ? 'Enter integration details and Save': null}
        />
    );
}

export default connect(
    (state, {id}) => {
        const item = getIntegrationSelector(state, {id});
        return {
            name: item ? item.name : id,
            loading: state.integrationsState.loading
        };
    },
    {}
)(MySubHeader);