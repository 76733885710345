import { connect } from 'react-redux';
import Header from '../../components/Header';
import { signOut } from '../../actions/auth';

export default connect(
    state => ({
        user: state.auth.user
    }),
    {
        signOut
    }
)(Header);