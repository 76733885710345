import { combineReducers } from 'redux';
import {
    layoutSetConfig
} from '../constants';
const initConfig = {
    subheader: false
};
const config = (state = initConfig, { type, payload }) => {
    switch (type) {
        case layoutSetConfig:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
};

export default combineReducers({
    config
});