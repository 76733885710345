import React, { memo, useMemo } from 'react';
import Logger from '../../utils/logger';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

const log = Logger('KTMenuLink');

const KTMenuLink = memo(({
    link, title, active, icon = null,
    subnav,
    submenu
}) => {
    log.debug('render', submenu);
    return (
        <li className={classnames('kt-menu__item', {
            'kt-menu__item--active': active,
            'kt-menu__item--submenu kt-menu__item--open kt-menu__item--here': active && submenu
        })}>
            <Link className="kt-menu__link" to={link}>
                {
                    icon
                    ? (<span className="kt-menu__link-icon">
                        {icon}
                    </span>)
                    : null
                }
                {
                    subnav
                    ? (
                        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                            <span></span>
                        </i>
                    ) : null
                }
                <span className="kt-menu__link-text">{title}</span>
            </Link>
            {
                submenu
                ? (
                    <div className="kt-menu__submenu ">
                        <ul className="kt-menu__subnav">
                            {submenu.map((i, key) => (
                                <KTMenuLinkR
                                    key={key}
                                    link={i.link}
                                    title={i.title}
                                    subnav={true}
                                />
                            ))}
                        </ul>
                    </div>
                ) : null
            }
        </li>
    )
});

const KTMenuLinkR = withRouter(({location, ...props}) => {
    const pathname = location.pathname;
    const link = props.link;
    const active = useMemo(() => {
        return pathname === link || pathname.split('/')[1] === link.split('/')[1]
    }, [link, pathname]);

    return <KTMenuLink 
        {...props}
        active={active}
    />;
})

export default KTMenuLinkR;