import React, { 
    useRef, useImperativeHandle,
    useMemo
} from 'react';
import _pick from 'lodash/pick';
import Joi from '@hapi/joi';
import { validateSchema } from '../validators';

function validateRefs(ref, refs) {
    return async () => {
        let errs = await ref.current.validateForm() || {};
        if (refs && refs.length) await Promise.all(refs.map(async r => {
            if (!r.current) return;
            const _errs = await r.current.validate() || {};
            errs = {
                ...errs,
                ..._errs
            };
        }));
        return errs;
    };
}

export const sensitiveString = '11111111111111111111111111111111111111111111111111111111111';

export function useFormRef(forwardedRef, refs) {
    const ref = useRef();
    const validate = useMemo(() => validateRefs(ref, refs), [ref, refs]);

    useImperativeHandle(forwardedRef, () => ({
        submit: async () => {
            try {
                const errors = Object.values(await validate());
                if (errors.length) {
                    throw new Error(errors[0]);
                }
                ref.current.submitForm();
            } catch(err) {
                console.error(err);
            }
        },
        getValues: () => {
            let vals = ref.current.state.values;
            if (refs && refs.length) refs.forEach(r => {
                if (!r.current) return;
                vals = {
                    ...vals,
                    ...r.current.getValues()
                };
            });
            return vals;
        },
        validate
    }));

    return ref;
}

export function useValues(values, initialValues) {
    const autoFocus = values ? false : true;
    const vals = useMemo(() => {
        if (values) {
            return {
                ...initialValues,
                ..._pick({
                    ...values,
                    clientId: sensitiveString,
                    clientSecret: sensitiveString,
                    apiKey: sensitiveString,
                    username: sensitiveString,
                    password: sensitiveString
                } , Object.keys(initialValues))
            };
        }
        return initialValues;
    }, [values, initialValues]);
    return {
        autoFocus,
        vals
    };
}

export function useFormValues(values, initialValues, showDynamic) {
    const autoFocus = values ? false : true;
    let initial = {};
    let schema = {};
    initialValues.forEach(el => {
        if (el.dynamic && (!showDynamic || !showDynamic.includes(el.key))) {
            return;
        }
        initial[el.key] = el.val;
        schema[el.key] = el.validate || Joi.any();
    });

    let vals = initial;
    if (values) {
        vals = {
            ...initial,
            ..._pick({
                ...values,
                clientId: sensitiveString,
                clientSecret: sensitiveString,
                apiKey: sensitiveString,
                username: sensitiveString,
                password: sensitiveString
            } , Object.keys(initial))
        };
    }

    return {
        autoFocus,
        vals,
        validateCb: validateSchema(Joi.object(schema))
    };
}

export function useInputPrefix(val) {
    return useMemo(() => {
        if (!val) return null;
        return (
            <span className="kt-font-sm">
                {val}
            </span>
        ) 
    }, [val]);
}