import {
    clearAnalyticsSkillsFilter,
    analyticsSetSkillsPageSize,
    analyticsGoToSkillsPage,
    fetchSkillsAnalyticsSuccess
} from '../../constants';
import { page } from '../users';

const init = {
    total: 0,
    currentPage: 1,
    pageSize: 10,
};

export default function (state = init, {type, payload}){
    switch (type) {
        case fetchSkillsAnalyticsSuccess:
            return {
                ...state,
                total: payload.data.total
            };
        case clearAnalyticsSkillsFilter:
            return init;
        case analyticsSetSkillsPageSize:
            return {
                ...state,
                pageSize: payload
            };
        case analyticsGoToSkillsPage:
            return {
                ...state,
                currentPage: page(
                    payload,
                    state.currentPage, 
                    state.total,
                    state.pageSize
                )
            };
        default:
            return state;
    }
}