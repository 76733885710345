import React from 'react';
import SubHeader from '../../components/SubHeader';
import { connect } from 'react-redux';
import { getCurrentSkill } from '../../reducers/skills';
import Toolbar from './SubHeaderToolbar';

export default connect(
    (state, props) => {
		const skill = getCurrentSkill(state);
		return {
            title: skill ? skill.name : "New Skill",
            desc: skill ? null: 'Enter skill details and submit',
            toolbar: <Toolbar syncRef={props.syncRef}/>
		}
	},
    {}
)(SubHeader);