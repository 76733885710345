import React, { memo, useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import ActionSelector from './index';
import CustomToggle from '../../components/Dropdown/CustomToggle';

function ActionSelectorDropdown({
    id,
    onSelect,
    toggle,
    dropdownProps
}) {
    const [show, setShow] = useState(false);
    const _onToggle = useCallback(e => setShow(e), []);
    const _onSelect = useCallback(action => {
        if (onSelect) onSelect(action);
        setShow(false);
    }, [onSelect]);

    return (
        <Dropdown
            show={show}
            onToggle={_onToggle}
            drop="down"
            {...dropdownProps}
        >
            <Dropdown.Toggle as={CustomToggle}>
                { toggle }
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-anim">
                <ActionSelector 
                    onSelect={_onSelect} 
                    id={id} 
                />
            </Dropdown.Menu>			
        </Dropdown>
    );
}

export default memo(ActionSelectorDropdown);