import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import SkillForm from '../../components/Forms/Skill';
import { getCurrentSkill } from '../../reducers/skills';
import { createSkill, updateSkill } from '../../actions/skill';
import PortletFormWrap from '../../components/PortletForm/Wrap';
import { useHistory } from 'react-router-dom';

function SkillOverviewPage({
    skill, 
    createSkill, 
    updateSkill, 
    syncRef,
    showDynamic
}) {
    const { push } = useHistory();
    const { _id } = skill;
    const onSubmit = useCallback(data => {
        const reqData = {
            data,
            onSuccess: ({data: {_id}}) => push(`/skills/${_id}`)
        };
        if (_id) updateSkill(_id, reqData);
        else createSkill(reqData);
    }, [createSkill, _id, updateSkill, push]);

    return (
        <PortletFormWrap>
            <SkillForm 
                forwardedRef={syncRef} 
                onSubmit={onSubmit}
                values={skill}
                showDynamic={showDynamic}
            />
        </PortletFormWrap>
    )
}

SkillOverviewPage.propTypes = {
    createSkill: PropTypes.func.isRequired,
    updateSkill: PropTypes.func.isRequired,
    skill: PropTypes.object.isRequired
};

export default connect(
    state => ({
        skill: getCurrentSkill(state) || {},
        showDynamic: state.auth.user.isRoot ? ['isDefaultForClients'] : null
    }),
    {
        createSkill,
        updateSkill
    }
)(SkillOverviewPage);