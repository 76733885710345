
import React, { memo, useMemo } from 'react';

export const Form = memo(({children}) => (
    <form className="kt-form kt-form--label-right">
        {children}
    </form>
))

function FormWrap({nested, children}) {
    
    const Wrap = useMemo(() => {
        if (nested) return React.Fragment;
        return Form;
    }, [nested]);

    return (
        <Wrap>
            {children}
        </Wrap>
    )
}

export default memo(FormWrap);