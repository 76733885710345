import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

function KTInputIcon({
    className,
    icon,
    iconPos='right',
    placeholder='',
    onChange,
    tpl,
    value,
    ...props
}) {

    const input = useMemo(() => (
        <input value={value} onChange={onChange} type="text" className="form-control" placeholder={placeholder}/>
    ), [onChange, placeholder, value]);

    switch(tpl) {
        case 'ktSc':
            return (
                <div className="kt-sc">
                    <div className="kt-sc__bottom kt-padding-0">
                        <div className="kt-sc__form">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        {icon}
                                    </div>
                                </div>
                                {input}
                            </div>
                        </div>
                    </div>
                </div>
            )
        default:
        return (
            <div 
                className={classnames(`kt-input-icon kt-input-icon--${iconPos}`, className)}
                {...props}
            >
                {input}
                <span className={`kt-input-icon__icon kt-input-icon__icon--${iconPos}`}>
                    <span>{ icon } </span>
                </span>
            </div> 
        )
    }
}

export default memo(KTInputIcon);