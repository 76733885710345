import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateEntityName } from '../../actions/entity';
import EditableString from '../../components/EditableString';

function EntityName({id, updateEntityName, ...props}) {
    const onEdit = useCallback(name => updateEntityName(name, id), [id, updateEntityName]);
    return <EditableString id={id} onEdit={onEdit} {...props}/>
}

export default connect(
    state => ({
        id: state.currentEntity,
        text: state.entities[state.currentEntity].name
    }),
    {
        updateEntityName
    }
)(EntityName);