import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import NavMenu from '../../components/Dropdown/NavMenu';
import { faTrash, faFileEdit } from '@fortawesome/pro-solid-svg-icons';
import {
    deleteSkill
} from '../../actions/skill';

const log = Logger('SkillItemMenu');

function SkillItemMenu({
    id,
    deleteSkill
}) {
    log.debug('render');

    return <NavMenu
        data={[{
            text: 'Edit',
            icon: faFileEdit,
            link: `/skills/${id}`
        },{
            text: 'Delete',
            icon: faTrash,
            onClick: () => deleteSkill(id)
        }]}
    />
}

SkillItemMenu.propTypes = {
    id: PropTypes.string.isRequired,
    deleteSkill: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        deleteSkill
    }
)(SkillItemMenu);