import React, { memo } from 'react';
import classnames from 'classnames';

function Input({ 
    error,
    className,
    forwardedRef,
    ...props
}) {
    return (
        <input 
            ref={forwardedRef}
            className={classnames("form-control", className, {
                'is-invalid': error
            })} 
            {...props}
        />
    )
}

export default memo(Input);