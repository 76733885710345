import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { 
    getTrainingModel,
    getTrainingModelStatus,
    saveTrainingModel,
    trainModel
} from '../../actions/trainModel';
import { Button } from 'react-bootstrap';

const log = Logger('TrainingDataStatus');

function TrainingDataStatus({
    isFetched,
    getData,
    getStatus,
    saveData,
    trainModel,
    loading,
    training
}) {
    log.debug('render');

    useLayoutEffect(() => {
        if (isFetched) getStatus();
        else getData();
    }, [
        isFetched, getData, getStatus
    ]);

    if (loading || training) {
        return loading ? 'Loading...' : 'Training...';
    } 

    return (
        <React.Fragment>
            <Button onClick={trainModel} variant="btn" className="btn-label-warning btn-bold kt-margin-r-10">
                Train Model
            </Button>
            <Button onClick={saveData} variant="btn" className="btn-label-primary btn-bold">
                Save
            </Button>
        </React.Fragment>
    );
}

TrainingDataStatus.propTypes = {
    isFetched: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired,
    getStatus: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
    trainModel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    training: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        isFetched: state.training.isFetched,
        loading: state.training.loading,
        training: state.training.training
    }),
    {
        getData: getTrainingModel,
        getStatus: getTrainingModelStatus,
        saveData: saveTrainingModel,
        trainModel
    }
)(TrainingDataStatus);