export const fetchSkill = 'fetchSkill';
export const fetchSkillSuccess = 'fetchSkillSuccess';
export const fetchSkillError = 'fetchSkillsError';
export const typeCleanSkillData = 'cleanSkillData';

export const typeActiveNode = 'setActiveNode';
export const typeAddChildNode = 'addChildNode';
export const typeAddNodeAbove = 'addNodeAbove';
export const typeAddNodeBelow = 'addNodeBelow';
export const typeMoveNode = 'moveNode';
export const typeTriggerMoveNode = 'triggerMoveNode';
export const typeCopyNodeTree = 'copyNodeTree';
export const typePasteToNode = 'pasteToNode';
export const typeDeleteNode = 'deleteNode';
export const typeUpdateNode = 'updateNode';

export const fetchTrainingModel = 'fetchTrainingModel';
export const fetchTrainingModelSuccess = 'fetchTrainingModelSuccess';
export const fetchTrainingModelError = 'fetchTrainingModelError';
export const typeSaveTrainingModel = 'saveTrainingModel';
export const typeSaveTrainingModelSuccess = 'saveTrainingModelSuccess';
export const typeSaveTrainingModelError = 'saveTrainingModelError';  
export const fetchTrainingModelStatus = 'fetchTrainingModelStatus';
export const fetchTrainingModelStatusSuccess = 'fetchTrainingModelStatusSuccess';
export const fetchTrainingModelStatusError = 'fetchTrainingModelStatusError';
export const typeTrainModel = 'trainModel';
export const typeTrainModelSuccess = 'trainModelSuccess';
export const typeTrainModelError = 'trainModelError';

export const typeNewEntity = 'newEntity';
export const typeCurrentEntity = 'currentEntity';
export const typeDeleteEntity = 'deleteEntity';
export const typeUpdateEntityName = 'updateEntityName';
export const typeNewEntityValue = 'newEntityValue';
export const typeEntityValueName = 'entityValueName';
export const typeDeleteEntitySynonym = 'deleteEntitySynonym';
export const typeAddEntitySynonym = 'addEntitySynonym';
export const typeDeleteEntityValue = 'deleteEntityValue';
export const typeAddValueToEntity = 'addValueToEntity';

export const typeNewIntent = 'typeNewIntent';
export const typeCurrentIntent = 'currentIntent';
export const typeDeleteIntent = 'deleteIntent';
export const typeUpdateIntentName = 'updateIntentName';
export const typeCreateUtterance = 'createUtterance';
export const typeUpdateUtterance = 'updateUtterance';
export const typeDeleteUtterance = 'deleteUtterance';
export const typeCurrentUtterance = 'currentUtterance';

export const fetchSkills = 'fetchSkills';
export const fetchSkillsSuccess = 'fetchSkillsSuccess';
export const fetchSkillsError = 'fetchSkillsError';

export const typeCreateSkill = 'createSkill';
export const typeCreateSkillSuccess = 'createSkillSuccess';
export const typeCreateSkillError = 'createSkillError';
export const typeSaveSkill = 'saveSkill';
export const typeSaveSkillSuccess = 'saveSkillSuccess';
export const typeSaveSkillError = 'saveSkillError';
export const typeDeleteSkill = 'deleteSkill';
export const typeDeleteSkillSuccess = 'deleteSkillSuccess';
export const typeUpdateSkill = 'updateSkill';
export const typeUpdateSkillSuccess = 'updateSkillSuccess';
export const typeUpdateSkillError = 'updateSkillError';

export const API = 'API';
export const apiFetch = 'apiFetch';
export const apiFetchSuccess = 'apiFetchSuccess';
export const apiFetchError = 'apiFetchError';

export const errorMessage = 'errorMessage';
export const successMessage = 'successMessage';

export const layoutSetConfig = 'layoutSetConfig';

export const fetchIntegrations = 'fetchIntegrations';
export const fetchIntegrationsSuccess = 'fetchIntegrationsSuccess';
export const fetchIntegrationsError = 'fetchIntegrationsError';

export const fetchAvailableIntegrations = 'fetchAvailableIntegrations';
export const fetchAvailableIntegrationsSuccess = 'fetchAvailableIntegrationsSuccess';
export const typeCreateIntegration = 'createIntegration';
export const typeCreateIntegrationSuccess = 'createIntegrationSuccess';
export const typeCreateIntegrationError = 'createIntegrationError';
export const typeUpdateIntegration = 'updateIntegration';
export const typeUpdateIntegrationSuccess = 'updateIntegrationSuccess';
export const typeUpdateIntegrationError = 'updateIntegrationError';
export const typeDeleteIntegration = 'deleteIntegration';
export const typeDeleteIntegrationSuccess = 'deleteIntegrationSuccess';
export const typeDeleteIntegrationError = 'deleteIntegrationError';

export const typeCreateAction = 'createAction';
export const typeCreateActionSuccess = 'createActionSuccess';
export const typeCreateActionError = 'createActionError';
export const fetchActions = 'fetchActions';
export const fetchActionsSuccess = 'fetchActionsSuccess';
export const fetchActionsError = 'fetchActionsError';
export const typeUpdateAction = 'updatAction';
export const typeUpdateActionSuccess = 'updatActionSuccess';
export const typeUpdateActionError = 'updatActionError';
export const typeDeleteAction = 'deleteAction';
export const typeDeleteActionSuccess = 'deleteActionSuccess';
export const typeDeleteActionError = 'deleteActionError';

export const nodeFormItemChange = 'nodeFormItemChange';
export const nodeFormActionSelect = 'nodeFormActionSelect';
export const nodeFormDeleteAction = 'nodeFormDeleteAction';
export const nodeFormSetActionParameters = 'nodeFormSetActionParameters';
export const nodeFormAddAction = 'nodeFormAddAction';
export const nodeFormActionResponse = 'nodeFormActionResponse';
export const nodeFormContextChange = 'nodeFormContextChange';
export const nodeFormOutputChange = 'nodeFormOutputChange';
export const nodeFormUpdateBehavior = 'nodeFormUpdateBehavior';

export const typeFilterUsers = 'filterUsers';
export const userListGoToPage = 'userListGoToPage';
export const userListPageSize = 'userListPageSize';
export const fetchUsers = 'fetchUsers';
export const fetchUsersSuccess = 'fetchUsersSuccess';
export const fetchUsersError = 'fetchUsersError';
export const fetchUserInfo = 'fetchUserInfo';
export const fetchUserInfoSuccess = 'fetchUserInfoSuccess';
export const fetchUserInfoError = 'fetchUserInfoError';
export const saveNewUserDetails  = 'saveNewUserDetails';
export const saveNewUserDetailsSuccess  = 'saveNewUserDetailsSuccess';
export const saveNewUserDetailsError  = 'saveNewUserDetailsError';
export const typeDeleteUser = 'deleteUser';
export const typeDeleteUserSuccess = 'deleteUserSuccess';
export const typeDeleteUserError = 'deleteUserError';
export const typeCreateUser = 'createUser';
export const typeCreateUserSuccess = 'createUserSuccess';
export const typeCreateUserError = 'createUserError';
export const typeUpdateUser = 'updateUser';
export const typeUpdateUserSuccess = 'updateUserSuccess';
export const typeUpdateUserError = 'updateUserError';

export const ANALYTICS_FILTER_BY_DATE = 'ANALYTICS_FILTER_BY_DATE';
export const ANALYTICS_FILTER_BY_USER = 'ANALYTICS_FILTER_BY_USER';
export const ANALYTICS_FILTER_BY_SKILL = 'ANALYTICS_FILTER_BY_SKILL';
export const ANALYTICS_FILTER_BY_STATUS = 'ANALYTICS_FILTER_BY_STATUS';
export const ANALYTICS_FILTER_BY_TECHNICAL_USERS = 'ANALYTICS_FILTER_BY_TECHNICAL_USERS';
export const fetchAnalytics = 'fetchAnalytics';
export const fetchAnalyticsSuccess = 'fetchAnalyticsSuccess';
export const fetchAnalyticsError = 'fetchAnalyticsError';
export const fetchUsersAnalytics = 'fetchUsersAnalytics';
export const fetchUsersAnalyticsSuccess = 'fetchUsersAnalyticsSuccess';
export const fetchUsersAnalyticsError = 'fetchUsersAnalyticsError';
export const fetchSkillsAnalytics = 'fetchSkillsAnalytics';
export const fetchSkillsAnalyticsSuccess = 'fetchSkillsAnalyticsSuccess';
export const fetchSkillsAnalyticsError = 'fetchSkillsAnalyticsError';
export const clearAnalyticsSkillsFilter = 'clearAnalyticsSkillsFilter';
export const analyticsSetSkillsPageSize = 'analyticsSetSkillsPageSize';
export const analyticsGoToSkillsPage = 'analyticsGoToSkillsPage';

export const typeSignOut = 'signOut';