import React, { memo, useState, useLayoutEffect } from 'react';
import Logger from '../../utils/logger';
import { Storage } from 'aws-amplify';

const log = Logger('S3Image');

function S3Image({src}) {
    log.debug('render');
    const [url, setUrl] = useState(null);

    useLayoutEffect(() => {
        Storage.get(src)
        .then(url => setUrl(url));
    }, [src]);

    if (!url) return null;
    return <img src={url} alt=""/>;
}

export default memo(S3Image);