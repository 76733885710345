import React, { memo } from 'react';
import Checkbox from '../../Checkbox';
import FormGroup from '../components/FormGroup';

function FormCheckbox({ 
    field, 
    form: {
        errors
    },
    sensitive,
    description,
    label,
    chLabel,
    error,
    ...props
}) {
    const _error = error || errors[field.name];
    
    return (
        <FormGroup 
            label={label}
            error={_error}
            description={description}
        >
            <div className="kt-checkbox-inline">
                <Checkbox 
                    {...field}
                    label={chLabel}
                    {...props}
                />
            </div>
        </FormGroup>
    )
}

export default memo(FormCheckbox);