import React, { memo } from 'react';
import { connect } from 'react-redux';
import ActionSelectorDropdown from '../ActionSelector/Dropdown';
import { addAction } from '../../actions/nodeForm';

const Toggle = <button type="button" className="btn btn-label-brand btn-sm btn-bold dropdown-toggle">Add Action</button>;
const dropdownProps = {
    alignRight: true
};
const Dropdown = memo(props => <ActionSelectorDropdown 
    toggle={Toggle}
    dropdownProps={dropdownProps}
    {...props}
/>);

export default connect(
    null,
    {
        onSelect: addAction
    }
)(Dropdown);