import React, { 
    memo, useState, useRef,
    useCallback, useMemo
} from 'react';
import { Dropdown } from 'react-bootstrap';
import Logger from '../../utils/logger';
import NavMenu from '../Dropdown/NavMenu';
import CustomToggle from '../Dropdown/CustomToggle';
import EntitySelector from '../../containers/EntitySelector';
import IntentSelector from '../../containers/IntentSelector';

const log = Logger('ContextSelector');

function ContextSelector({
    value,
    onChange,
    placeholder,
    ...props
}) {
    log.debug('render');
    const [show, setShow] = useState(false);
    const _input = useRef();
    const focus = useCallback(() => _input.current.focus(), [_input]);
    const setValue = useCallback(val => {
        if (onChange) onChange(val);
    }, [onChange]);
    const _triggerHide = useCallback(() => setShow(false), []);
    const _triggerShow = useCallback(() => setShow(true), []);
    const _onKeyUp = useCallback(e => {
        if (!e.target.value) _triggerShow()
        else _triggerHide();
    }, [_triggerHide, _triggerShow]);
    const _onToggle = useCallback(e => setShow(e), []);
    const _onChange = useCallback(e => setValue(e.target.value), [setValue]);
    const rootMenu = useMemo(() => {
        return [{
            text: 'intent',
            onClick: () => setValue('intent.')
        },{
            text: 'entity',
            onClick: () => setValue('entity.')
        },{
            text: 'context',
            onClick: () => {
                setValue('context.');
                _triggerHide();
                focus();
            }
        }, {
            text: 'user input',
            onClick: () => {
                setValue('input.text');
                _triggerHide();
            }
        }, {
            text: 'Not Recognized',
            onClick: () => {
                setValue('not_recognized');
                _triggerHide();
            }
        }];
    }, [setValue, _triggerHide, focus]);
    const _onSelect = useCallback(name => {
        if (name) {
            name = value.split('.')[0] + '.' + name;
            _triggerHide();
        }
        setValue(name);
    }, [_triggerHide, value, setValue]);
    const _menu = useMemo(() => {
        const _val = value.split('.');
        switch(_val[0]) {
            case 'entity':
                return <EntitySelector value={_val[1]} onSelect={_onSelect}/>;
            case 'intent':
                return <IntentSelector value={_val[1]} onSelect={_onSelect}/>;
            default:
                return <NavMenu data={rootMenu} />;
        }
    }, [rootMenu, value, _onSelect]);

    return (
        <Dropdown
            show={show}
            onToggle={_onToggle}
            className="context-selector"
        >
            <Dropdown.Toggle as={CustomToggle}>
                <input 
                    ref={_input}
                    value={value} 
                    onChange={_onChange} 
                    onKeyUp={_onKeyUp}
                    type="text"
                    className="form-control"
                    onClick={_triggerShow}
                    placeholder={placeholder}
                    autoComplete="off"
                    {...props}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-anim">
                { _menu }
            </Dropdown.Menu>			
	    </Dropdown>
    )
}

export default memo(ContextSelector);