import React, { memo, useMemo } from 'react';
import Logger from '../../utils/logger';
import classnames from 'classnames';
import './index.sass';

const log = Logger('Portlet');

function Portlet({ 
    title, 
    titleIcon,
    children, 
    toolbar,
    onClick,
    className,
    bodyFit = false,
    heightFluid = false,
    mobile = false,
    ...props
}) {
    log.debug('render');

    const head = useMemo(() => {
        if (!title && !toolbar) return null;
        return (
            <div className="kt-portlet__head">
                {
                    title
                    ? (<div className="kt-portlet__head-label">
                        {
                            titleIcon
                            ? <span className="kt-portlet__head-icon">{titleIcon}</span>
                            : null
                        }
                        {
                            typeof title === 'string'
                            ? <h3 className="kt-portlet__head-title">{title}</h3>
                            : <div className="kt-portlet__head-title">{title}</div>
                        }
                        
                    </div>) : null
                }
                {
                    toolbar
                    ? <div className="kt-portlet__head-toolbar">{toolbar}</div>
                    : null
                }    
            </div>
        );
    }, [title, toolbar, titleIcon]);

    const body = useMemo(() => {
        if (!children) return null;
        return <div className={classnames("kt-portlet__body", {
            'kt-portlet__body--fit': bodyFit
        })}>
            { children }
        </div>
    }, [children, bodyFit]);

    return (
        <div 
            onClick={onClick} 
            className={classnames("kt-portlet",className, {
                'kt-portlet--height-fluid': heightFluid,
                'kt-portlet--mobile': mobile
            })}
            {...props}
        >
            { head }
            { body }
        </div>
    );
}
    
export default memo(Portlet);