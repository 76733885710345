import Analytics from '@aws-amplify/analytics';
// import uuid from 'uuid/v4';

// const stKey = 'tempAddress';
// let tempId = window.localStorage.getItem(stKey);
// if (!tempId) {
    // tempId = uuid();
    // window.localStorage.setItem(stKey, tempId);
// }

export function updateEndpoint(user) {
    let d = {
        channelType: 'EMAIL'
        // attributes: {
        //     tempId: [tempId]
        // }
    };
    if (user) {
        d.address = user.email;
        d.userId = user.sub;
        d.userAttributes = {
            email: [user.email],
            sub: [user.sub]
        };
    }
    Analytics.updateEndpoint(d);
}