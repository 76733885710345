import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getSkills, createSkill } from '../../actions/skill';
import PageItemsGrid from '../PageItemsGrid';
import Logger from '../../utils/logger';
import KTProject1 from '../../components/KTProject1';
import ItemMenu from './ItemMenu';

const log = Logger('SkillsPage');

function SkillsPage({ data, fetchData  }) {
    log.debug('render');

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const item = useCallback(el => (
        <KTProject1 
            description={el.description}
            title={el.name}
            onTitle={`/skills/${el._id}`}
            tags={el.tags}
            toolbarMenu={<ItemMenu id={el._id} />}
        />
    ), []);

    const onFilter = useCallback((el, filter) => {
        if (el.name.toLowerCase().indexOf(filter) !== -1) {
            return true;
        } else if (el.description && el.description.toLowerCase().indexOf(filter) !== -1) {
            return true;
        } else if (el.tags.length && el.tags.some(t => t.toLowerCase().indexOf(filter) !== -1)) {
            return true;
        }
        return false;
    }, []);

    return (
        <PageItemsGrid 
            title="Skills"
            item={item}
            data={data}
            actionBrandTo="/skills/new"
            actionBrandTitle="Create skill"
            onFilter={onFilter}
        />
    );
}

SkillsPage.propTypes = {
    data: PropTypes.array.isRequired,
    fetchData: PropTypes.func.isRequired
};
    
export default connect(
    state => ({
        data: state.skills
    }), 
    {
        fetchData: getSkills,
        submitNew: createSkill
    }
)(SkillsPage);