import React, { memo } from 'react';
import Logger from '../../utils/logger';
import Portlet from './index';
import { PropTypes } from 'prop-types';

const log = Logger('PortletNotificationList');

function PortletNotificationList({
    ids,
    Item,
    ...props
}) {
    log.debug('render');

    if (!ids.length) {
        return null;
    }

    return (
        <Portlet {...props}>
            <div className="kt-notification">
                {
                    ids.map(id => (
                        <Item key={id} id={id}/>
                    ))
                }
            </div>
        </Portlet>
    )
}

PortletNotificationList.propTypes = {
    ids: PropTypes.array.isRequired,
    Item: PropTypes.object.isRequired
};

export default memo(PortletNotificationList);