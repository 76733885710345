import {
    fetchSkillsSuccess,
    typeCreateSkillSuccess,
    typeDeleteSkillSuccess,
    typeUpdateSkillSuccess
} from '../constants';

export default function(state = [], { type, payload }) {
    switch (type) {
        case typeCreateSkillSuccess:
            return [
                payload.data,
                ...state
            ];
        case fetchSkillsSuccess: 
            return [
                ...payload.data
            ];
        case typeUpdateSkillSuccess:
            return state.map(el => {
                    if (el._id === payload.data._id) {
                        return {
                            ...el,
                            ...payload.data
                        };
                    }
                    return el;
                });
        case typeDeleteSkillSuccess:
            return state.filter(el => el._id !== payload.id);
        default:
            return state;
    }
}

export function getCurrentSkill(state) {
    if (!state.skill.id) return null;
    return state.skills.find(el => el._id === state.skill.id);
}