import React from 'react';
import { connect } from 'react-redux';
import { onOutputChange } from '../../actions/nodeForm';
import { formOutputSelector } from '../../reducers/forms/skillNode';
import Json from '../../components/Forms/components/Json';

function NodeOutputJsonEditor({
    value, onChange
}) {

    if (typeof value !== 'string') {
        value = '[]';
    }

    return (
        <Json
            name="output"
            value={value}
            onChange={onChange}
        />
    )
}

export default connect(
    state => ({
        value: formOutputSelector(state)
    }),
    {
        onChange: onOutputChange
    }
)(NodeOutputJsonEditor);