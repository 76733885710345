import React, { memo } from 'react';
import Logger from '../../utils/logger';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

const log = Logger('HeaderMenuLink');

const El = memo(({link, title, active}) => {
    log.debug('render');
    return (
        <li className={classnames('kt-menu__item', {
            'kt-menu__item--active': active
        })}>
            <Link className="kt-menu__link" to={link}>
                <span className="kt-menu__link-text">{title}</span>
            </Link>
        </li>
    )
});

export default withRouter(({link, title, location}) => {
    return <El link={link} title={title} active={location.pathname === link}/>;
})