import React, { memo, useMemo, useCallback } from 'react';
import classnames from 'classnames';

const _cl = 'kt-datatable__pager-link';
const _clDisabled = `${_cl}--disabled`;
const _clActive = `${_cl}--active`;
export const dots = '...';
const Icon = memo(({name}) => <i className={`flaticon2-${name}`}></i>);

function PaginationLink({
    type = dots,
    disabled,
    active,
    onClick
}) {

    const value = useMemo(() => {
        switch(type) {
            case 'first':
                return <Icon name="fast-back" />;
            case 'prev':
                return <Icon name="back" />;
            case 'next':
                return <Icon name="next" />;
            case 'last':
                return <Icon name="fast-next" />;
            default:
                return type;
        }
    }, [type]);

    const isDots = useMemo(() => type === dots, [type]);

    const _onClick = useCallback(() => onClick && onClick(type) ,[type, onClick]);

    return (
        <li>
            <div onClick={isDots || disabled ? null : _onClick}
                className={classnames(_cl, {
                    [`${_cl}--${type}`]: type,
                    [_clActive]: active,
                    [_clDisabled]: disabled,
                    'no-hover': isDots
                })}
            >
                { value }
            </div>
        </li>
    );
}

export default memo(PaginationLink);