import React, { 
    memo, useState, useEffect,
    useCallback, useRef
} from 'react';
import { Formik, Field } from 'formik';
import FInput from '../formik/Input';
import FSelect from '../formik/Select';
import { validateSchema, required } from '../validators';
import { useFormRef, useValues, useInputPrefix } from '../formik/hooks';
import Joi from '@hapi/joi';
import api from '@aws-amplify/api';

const initialValues = { 
    url: '',
    action: '',
};

const validateCb = validateSchema(Joi.object({
    url: required('Action WSDL URL'),
    action: required('SOAP Action'),
}));

async function fetchSoapAction(url, id) {
    return await api.post('api', `/actions/soap/${id}`, {
        body: {url}
    });
}

const initState = {
    options: null,
    loading: false,
    error: null
};

function SoapAction({ 
    forwardedRef, 
    values, 
    integration 
}) {
    const urlRef = useRef();
    const [{
        options, loading, error
    }, setState] = useState(initState);
    const [url, setUrl] = useState('');
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    const baseUrl = integration.baseUrl;
    const urlPrefix = useInputPrefix(baseUrl);
    const integrationId = integration.value;
    const onChange = useCallback(e => setUrl(e.target.value), []);

    useEffect(() => { 
        const tId = setTimeout(async() => {
            if (!url) {
                setState(initState);
                return;
            }
            setState(({options}) => ({
                options, 
                error: null,
                loading: true
            }));
            let options = null;
            let error = null;
            try {
                const res = await fetchSoapAction(url, integrationId);
                options = res.map(value => ({
                    value,
                    label: value
                }));
            } catch(err) {
                error = 'Invalid WSDL url';
            }
            setState({
                options,
                loading: false,
                error
            });
        }, 800);
        return () => clearTimeout(tId);
    }, [url, integrationId]);

    useEffect(() => {
        if (values && values.url) {
            setUrl(values.url);
        }
    },[values]);

    useEffect(() => {
        if (error) urlRef.current.focus();
    },[error]);
    
    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {() => (
                <React.Fragment>
                    <Field 
                        forwardedRef={urlRef}
                        name="url" 
                        autoFocus={autoFocus}
                        label="WSDL path"
                        description="Specify action WSDL path (example: /service-path)"
                        component={FInput} 
                        prepend={urlPrefix}
                        disabled={loading}
                        error={error}
                        loading={loading}
                        onChange={onChange}
                    />
                    { options ? <Field 
                        name="action" 
                        component={FSelect} 
                        label="SOAP Action"
                        description="Specify SOAP method"
                        options={options}
                        convertToValues
                    /> : null }
                </React.Fragment>
            )}
        </Formik>
    )
}

export default memo(SoapAction);