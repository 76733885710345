import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { setActiveNode } from '../../actions/node';
import SkillNodeForm from '../SkillNodeForm';

const log = Logger('SkillNodePage');

function SkillNodePage({
    node,
    setActiveNode,
    syncRef
}) {
    log.debug('render');
    
    useEffect(() => {
        setActiveNode(node);
        return () => setActiveNode(null);
    }, [setActiveNode, node]);

    if (!node) return null;
    return <SkillNodeForm syncRef={syncRef}/>;
}

SkillNodePage.propTypes = {
    setActiveNode: PropTypes.func.isRequired
};

export default connect(
    (state, {match}) => ({
        node: state.nodes[match.params.nodeId]
    }),
    {
        setActiveNode
    }
)(SkillNodePage);