import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import EditableString from '../../components/EditableString';
import { entityValueName } from '../../actions/entity';

const log = Logger('EntityValueName');

function EntityValueName({id, entityValueName, name, entity}) {
    log.debug('render');

    const onEdit = useCallback((name, valueId) => entityValueName(name, valueId, entity), [entityValueName, entity]);

    return (
        <EditableString 
            text={name}
            id={id}
            onEdit={onEdit}
            placeholder="Type Entity Value"
        />
    )
}

EntityValueName.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    entityValueName: PropTypes.func.isRequired
};

export default connect(
    (state, {id}) => ({
        name: state.entities[state.currentEntity].items[id].name,
        entity: state.currentEntity
    }),
    {
        entityValueName
    }
)(EntityValueName);