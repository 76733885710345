import { connect } from 'react-redux';
import LastUpdated from '../../components/LastUpdated';
import { getAnalytics, fetchUsers, fetchSkills } from '../../actions/analytics';
import { withRouter } from 'react-router-dom';

export default withRouter(connect(
    state => ({
        date: state.analytics.date,
        loading: state.analytics.loading,
        from: state.analytics.filters.from, 
        to: state.analytics.filters.to,
        page: state.analytics.skillsFilter.currentPage,
        pageSize: state.analytics.skillsFilter.pageSize,
    }),
    (dispatch, {location}) => {
        switch(location.pathname) {
            case '/dashboard':
                return {
                    refresh: d => dispatch(getAnalytics(d))
                };
            case '/dashboard/skills':
                return {
                    refresh: d => dispatch(fetchSkills(d))
                };
            case '/dashboard/users':
                return {
                    refresh: d => dispatch(fetchUsers(d))
                };
            default:
                return {};
        }
    },
    ({date, loading, from, to, page, pageSize}, {refresh}, {location}) => ({
        date, loading,
        onRefresh: () => {
            let params = {from, to};
            if (location.pathname === '/dashboard/skills') {
                params = {
                    ...params,
                    page, pageSize
                };
            }
            return refresh(params);
        }
    })
)(LastUpdated));