import React, { memo } from 'react';
import Logger from '../../utils/logger';
import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { ReactComponent as AngleRight } from '../../icons/AngleRight.svg';
import './index.sass';

const log = Logger('KTNotificationItem');

function KTNotificationItem({
    onClick,
    active,
    name,
    description
}) {
    log.debug('render');
    return (
        <div 
            onClick={onClick} 
            className={classnames("kt-notification__item",{
                'active': active
            })}
        >
            <div className="kt-notification__item-details">
                <div className="kt-notification__item-title">
                    {name}
                </div>
                <div className="kt-notification__item-time">
                    {description}
                </div>
            </div>
            <AngleRight/>
        </div>
    )
}

KTNotificationItem.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool
};

export default memo(KTNotificationItem);