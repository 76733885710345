import Joi from '@hapi/joi';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';

export const EmailValidate = Joi.string().required()
// .email()
// .messages({
//     'any.required': 'Email is required',
//     'string.email': 'Email is invalid'
// });
.error(errs => errs.map(er => {
    er.message = 'Email is required';
    return er;
}));

export const required = name => Joi.string().required()
.error(errs => errs.map(er => {
    er.message = `${name} is required`;
    return er;
}));

export function validateSchema(schema) {
    return values => {
        let errors = {};
        const {error} = schema.validate(values, { abortEarly: false });
        if (error && error.details) {
            error.details.forEach(({path, message}) => {
                errors[path[0]] = message;
            });
        }
        return errors;
    };
}

export function jIsEmail(value, helpers){
    if (value && !isEmail(value)) {
        return helpers.error('any.email');
    }
    return undefined;
}

export function jIsUrl(value, helpers){
    if (value && !isURL(value)) {
        return helpers.error('any.url');
    }
    return undefined;
}

const __messages = {
    'any.url': 'Invalid URL',
    'any.email': 'Invalid email address',
    'any.required': 'This value is required',
    'string.empty': 'This value is required',
    'object.base': 'This value is required'
};

export function jError(messages) {
    let _messages = messages || {};
    return errs => errs.map(err => {
        err.message = _messages[err.code] || __messages[err.code] || err.message;
        return err;
    });
}
