import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';
import CustomToggle from '../Dropdown/CustomToggle';

const values = ['10','25','50','100'];

function PaginationPageSize({
    onSelect,
    value
}) {
    return (
        <Dropdown
            onSelect={onSelect}
            className="kt-datatable__pager-size"
        >
            <Dropdown.Toggle as={CustomToggle}>
                <button className="btn dropdown-toggle btn-light">{value}</button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-anim">
                {
                    values.map((v,k) => <Dropdown.Item 
                        key={k} 
                        eventKey={v}
                    >{v}</Dropdown.Item>)
                }
            </Dropdown.Menu>			
	    </Dropdown>
    )
}

export default memo(PaginationPageSize);