import {
    fetchAnalyticsSuccess
} from '../../constants';

const init = {
    apiCalls: 0,
    conversations: 0,
    users: 0,
    topSkills: [],
    topUsers: []
};

export default function (state = init, {type, payload}){
    switch (type) {
        case fetchAnalyticsSuccess:
            return {
                ...state,
                ...payload.data
            };
        default:
            return state;
    }
}