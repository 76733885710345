import React, { memo } from 'react';
import PageContent from '../PageContent';
import Subheader from './Subheader';
import UsersTable from './UsersTable';

const subheader = <Subheader/>;

function UserListPage() {
    return (
        <PageContent subheader={subheader}>
            <UsersTable/>
        </PageContent>
    )
}

export default memo(UserListPage);