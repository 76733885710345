import {
    typeNewIntent,
    typeCurrentIntent,
    typeDeleteIntent,
    typeCreateUtterance,
    typeUpdateUtterance,
    typeDeleteUtterance,
    typeCurrentUtterance,
    typeUpdateIntentName
} from '../constants';
import uuid from 'uuid/v1';

export function newIntent() {
    return {
        type: typeNewIntent,
        payload: uuid()
    };
}

export function setCurrentIntent(payload) {
    return {
        type: typeCurrentIntent,
        payload
    };
}

export function saveUtterance(payload, currentIntent, currentUtterance) {
    if (currentUtterance) {
        return updateUtterance(currentIntent, currentUtterance, payload);
    } else {
        return createUtterance(currentIntent, payload);
    }
}

export function createUtterance(intent, data) {
    return {
        type: typeCreateUtterance,
        payload: {
            intent,
            id: uuid(),
            data
        }
    };
}

export function updateUtterance(intent, id, data) {
    return {
        type: typeUpdateUtterance,
        payload: {
            intent, id, data
        }
    };
}

export function deleteUtterance(id, intent) {
    return {
        type: typeDeleteUtterance,
        payload: {
            intent,
            id
        }
    };
}

export function setActiveUtterance(payload) {
    return {
        type: typeCurrentUtterance,
        payload
    };
}

export function deleteIntent(payload) {
    return {
        type: typeDeleteIntent,
        payload
    };
}

export function updateIntentName(name, intent) {
    return {
        type: typeUpdateIntentName,
        payload: {
            intent,
            name
        }
    };
}