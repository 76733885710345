import React, { memo, useMemo } from 'react';
import Logger from '../../../utils/logger';
import classnames from 'classnames';

const log = Logger('Forms-FormGroup');

function El({ 
    children, 
    label,
    error,
    description,
    row = true,
    className,
    ...props
}) {
    log.debug('render');

    const lbCls = useMemo(() => {
        if (row) {
            return 'col-xl-3 col-lg-3 col-form-label';
        }
        return null;
    }, [row]);

    const grCls = useMemo(() => {
        if (row) {
            return 'col-lg-9 col-xl-6';
        }
        return null;
    }, [row]);

    return (
        <div 
            className={classnames("form-group", className, {
                row: row ? true : false
            })}
            {...props}
        >
            <label className={lbCls}>{label}</label>
            <div className={grCls}>
                { children }
                {
                    error
                    ? <div className="error invalid-feedback">{error}</div>
                    : null
                }
                {
                    description
                    ? <span className="form-text text-muted">{description}</span>
                    : null
                }
            </div>
        </div>
    )
}

export default memo(El);