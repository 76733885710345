import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import Dropdown from '../../components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import ItemMenu from './ItemMenu';

const log = Logger('NodeItemDropdown');

function El({id}) {
    log.debug('render');

    return (
        <Dropdown
            toggleClass="btn-icon"
            toggle={
                <FontAwesomeIcon icon={faEllipsisV}/>
            }
        > 
            <ItemMenu id={id}/>
        </Dropdown>
    )
}

El.propTypes = {
    id: PropTypes.string.isRequired
};

export default memo(El);