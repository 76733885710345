import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import Portlet from '../../components/Portlet';

import Toolbar from './EntityContentToolbar';
import EntityName from './EntityName';
import ValuesList from './ValuesList';
import { prefix } from '../../components/EntityName';

const log = Logger('EntityContent');

function EntityContent({noCurrent}) {
    log.debug('render');

    if (noCurrent) {
        return null;
    }
    
    return (
        <Portlet
            className="title-fluid body-scroll"
            title={<EntityName prefix={prefix}/>}
            toolbar={<Toolbar/>}
            bodyFit={true}
        >
            <ValuesList/>
        </Portlet>
    )
}

EntityContent.propTypes = {
    noCurrent: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        noCurrent: state.currentEntity ? false : true
    }),
    {
    }
)(EntityContent);