import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import NodeItem from './NodeItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import './index.sass';

function preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
}

function NodeTreeItem({id, childIds, skillId, isMoveNode}) {
    const stId = `node_${skillId}_${id}`;
    const [_show, setShow] = useState(window.localStorage.getItem(stId) !== 'false');
    const show = useMemo(() => _show && !isMoveNode, [isMoveNode, _show]);
    const children = useMemo(() => {
        return childIds.map((childId, key) => 
            <Node key={key} id={childId} />
        );
    }, [childIds]);

    const toggleShow = useCallback(e => {
        preventDefault(e);
        setShow(st => {
            const _st = !st;
            window.localStorage.setItem(stId, _st);
            return _st;
        });
    }, [stId]);

    const titlePrefix = useMemo(() => (
        <div className="toggle-nodes" onClick={toggleShow}>
            <FontAwesomeIcon icon={show ? faChevronDown : faChevronRight }/>
        </div>  
    ), [toggleShow, show]);

    if (id === 'root') {
        return children;
    }

    return (
        <div className="tree-view">
            <NodeItem id={id} titlePrefix={
                children && children.length && !isMoveNode ? titlePrefix : null
            } />
            {
                show
                ? children
                : null
            }
        </div>
    )
}

NodeTreeItem.propTypes = {
    id: PropTypes.string.isRequired,
    childIds: PropTypes.array.isRequired
};

const Node = connect(
    (state, props) => {
        const node = state.nodes[props.id] || {};
        return {
            childIds: node.childIds,
            skillId: state.skill.id,
            isMoveNode: state.skill.moveNode === props.id
        }
    },
    {}
)(NodeTreeItem);

export default Node;