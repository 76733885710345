import React, { memo } from 'react';
import Logger from '../../utils/logger';
import { Dropdown } from 'react-bootstrap';
import EntityMenu from './EntityMenu';
import './EntityDropdown.sass';
import cls from 'classnames';

const log = Logger('EntityDropdown');

function EntityDropdown({
    onToggle,
    onSelect,
    value,
    show
}) {
    log.debug('render');
    return (
        <Dropdown 
            className="entity-dropdown"
            show={show} 
            onToggle={onToggle}
        >
            <div className={cls("dropdown-menu-anim dropdown-menu", {
                show
            })}>    
                <EntityMenu focus={show} value={value} onSelect={onSelect}/>
            </div>			
	    </Dropdown>
    )
}

export default memo(EntityDropdown);