import React from 'react';
import { connect } from 'react-redux';
import { 
    actionDetailedNameSelector
} from '../../reducers/actions';
import DetailedName from '../../components/DetailedName';
import { ReactComponent as ThunderCircle } from '../../icons/ThunderCircle.svg';

const defImage = <ThunderCircle/>;

export default connect(
    (state, props) => {
        const data = actionDetailedNameSelector(state, props);
        if (!data) return {};
        switch(data.description) {
            case 'quickbooks':
                data.description = 'QuickBooks';
                break;
            default:
                data.description = data.description || 'Rest API';
                break;
        }
        if (!data.image) data.image = defImage;
        return data;
    },
    {}
)(DetailedName);