import React, { 
    memo, 
    useRef, 
    useEffect, 
    useCallback,
    useState,
    useMemo
} from 'react';
import shave from 'shave';

function TruncateText({children, maxHeight = 10}) {
    const ref = useRef();
    // eslint-disable-next-line
    const [tId, setTId] = useState(null);
    const update = useCallback(() => shave(ref.current, maxHeight), [maxHeight]);

    const resizeUpdate = useCallback(() => {
        setTId(tId => {
            if (tId) clearTimeout(tId);
            return setTimeout(update, 200);
        });
    },[update]);

    useEffect(() => {
        update();
        window.addEventListener("resize", resizeUpdate);
        return () => window.removeEventListener("resize", resizeUpdate);
    }, [resizeUpdate, update]);

    return useMemo(() => (
        <span ref={ref}>
            { children }
        </span>
    ), [children]);
}

export default memo(TruncateText);