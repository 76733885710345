import React, { memo } from 'react';
import Logger from '../../utils/logger';
import MainPage from '../MainPage';
import Notification from '../Notification';

const log = Logger('Router');

function El() {
    log.debug('render');
    return (
        <React.Fragment>
            <Notification/>
            <MainPage/>
        </React.Fragment>
    )
}

export default memo(El);