import React, { memo, useCallback } from 'react';
import Logger from '../../../utils/logger';
import FormGroup from './FormGroup';

const log = Logger('Forms-Input');

function FormsInput({ 
    label, 
    value, 
    error, 
    description,
    name,
    placeholder,
    row,
    onChange,
    type="text"
}) {
    log.debug('render');
    const _onChange = useCallback(e => {
        e.preventDefault();
        if (onChange) onChange({
            [name]: e.target.value
        });
    }, [onChange, name]);
    return (
        <FormGroup 
            row={row}
            label={label}
            error={error}
            description={description}
        >
            <input 
                value={value || ''} 
                onChange={_onChange} 
                type={type}
                className={`form-control${error ? ' is-invalid': ''}`} 
                name={name}
                placeholder={placeholder}
                autoComplete={`new-${name}`} 
            />
        </FormGroup>
    )
}

export default memo(FormsInput);