import React, { memo, useCallback } from 'react';
import Logger from '../../../utils/logger';
import AceEditor from 'react-ace';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

const log = Logger('Forms-Json');

function El({
    value, 
    name,
    onChange
}) {
    log.debug('render');

    const _onChange = useCallback(value => {
        if (onChange) onChange(value);
    }, [onChange]);

    return (
        <AceEditor
            mode="json"
            theme="github"
            onChange={_onChange}
            name={name}
            value={value}
            width="100%"
            editorProps={{
                $blockScrolling: true
            }}
            setOptions={{
                tabSize: 2,
                autoScrollEditorIntoView: true,
                minLines: 5,
                maxLines: 12
            }}
        />
    )
}

export default memo(El);