import {
    typeFilterUsers,
    userListGoToPage,
    userListPageSize,
    fetchUsers,
    fetchUsersSuccess,
    fetchUsersError,
    typeCreateUser,
    typeCreateUserError,
    typeCreateUserSuccess,
    typeUpdateUser,
    typeUpdateUserError,
    typeUpdateUserSuccess,
    typeDeleteUser,
    typeDeleteUserSuccess,
    typeDeleteUserError
} from '../constants';
import produce from "immer";

const initState = {
    list: [],
    loading: false,
    filter: '',
    total: 0,
    currentPage: 1,
    pageSize: 10,
    refresh: 1
};

export function page(val, currentPage, total, pageSize) {
    var page = val;
    const totalPages = getTotalPages(total, pageSize)
    switch(val) {
        case 'first':
            page = 1;
            break;
        case 'prev':
            page = currentPage <= 1 ? 1 : currentPage - 1;
            break;
        case 'next': {
            const n = currentPage + 1;
            page = n >= totalPages ? totalPages : n;
            break;  
        } 
        case 'last':
            page = totalPages;
            break; 
        default:
            break;         
    } 
    return page;
}

export function isNew(val) {
    return val === 'new';
}

export const selectUserById = (state, {id}) => 
    state.users.list.find(el => el._id === id);

function getTotalPages(_total, pageSize) {
    const total = parseInt(_total);
    if (total === 0) return 1;
    return Math.ceil(total/parseInt(pageSize));
}

export default produce((state, {type, payload}) => {
    switch (type) {
        case typeFilterUsers:
            state.filter = payload;
            break;
        case typeDeleteUserSuccess: 
            state.loading = false;
            state.refresh = state.refresh + 1;
            state.list = [];
            state.total = 0;
            break;
        case typeUpdateUserSuccess:
            state.loading = false;
            state.list = state.list.map(el => {
                if (el._id === payload.data._id) {
                    return {
                        ...el,
                        ...payload.data
                    };
                }
                return el;
            });
            break;
        case typeCreateUserSuccess:
            state.loading = false;
            state.total = 1;
            state.list = [{
                ...payload.data
            }];
            break;
        case userListGoToPage:
            state.currentPage = page(
                payload, 
                state.currentPage, 
                state.total,
                state.pageSize
            );
            break;
        case userListPageSize:
            state.pageSize = payload;
            break;
        case fetchUsers:
        case typeCreateUser:
        case typeUpdateUser:
        case typeDeleteUser:
            state.loading = true;
            break;
        case fetchUsersSuccess:
            state.loading = false;
            state.list = payload.data.users;
            state.total = payload.data.total;
            break;
        case typeUpdateUserError:
        case typeCreateUserError:
        case typeDeleteUserError:
        case fetchUsersError:
            state.loading = false;
            break;
        default:
            break;
    }
}, initState);