import React, { 
    memo, useRef, useMemo
} from 'react';
import PageContent from '../PageContent';
import PortletFormWrap from '../../components/PortletForm/Wrap';
import SubHeader from './Subheader';
import FormWrap from './FormWrap';
import { useParams } from 'react-router-dom';

function IntegrationPage() {
    const formRef = useRef();
    const { id, name } = useParams();
    const subheader = useMemo(() => (
        <React.Fragment>
            <SubHeader formRef={formRef} id={id}/>
        </React.Fragment>
    ), [id]);

    return (
        <PageContent
            subheader={subheader}
        >
            <PortletFormWrap>
                <FormWrap 
                    formRef={formRef} 
                    name={name}
                    id={id}
                />
            </PortletFormWrap>
        </PageContent>
    );
}

export default memo(IntegrationPage);