import React, { memo } from 'react';
import { 
    faUserHeadset, faBooks, 
    faGavel,faQuestion
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faSlackHash
} from '@fortawesome/free-brands-svg-icons';
import { Drop } from '../Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InfoSection() {
    return (
        <Drop
            toggleProps={{
                className:"kt-header__topbar-icon"
            }}
            toggle={<FontAwesomeIcon style={{width: '20px',height: '20px'}} icon={faQuestion} />}
            alignRight
            className="kt-header__topbar-item"
            menuItems={[{
                link: 'https://docs.hala.ai',
                text: 'Documentation',
                type: 'external',
                icon: faBooks
            },{
                link: 'mailto:support@hala.ai?subject=Hala Support Query',
                text: 'Support',
                type: 'external',
                icon: faUserHeadset
            },{
                link: 'https://join.slack.com/t/hala-support/shared_invite/enQtNzc3MjUxNDQ2NTc3LTAxY2I1MDYxNDA5Mzg5NTI2NTM1YWY0YWIyMjcxZmU1Mzk2ODRlZmU5ZDgyMTA4NTUwNWY3NzRkZWYwODZhOTA',
                text: 'Slack Channel',
                type: 'external',
                icon: faSlackHash
            },{
                link: 'https://www.hala.ai/terms-and-conditions',
                text: 'Terms of Usage',
                type: 'external',
                icon: faGavel
            }]}
        />
    )
}

export default memo(InfoSection);