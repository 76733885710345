import React, { memo } from 'react';
import './index.sass';

function PageLayout1({left, right}) {
    return (
        <div className="row layout-v1">
            <div className="col-xl-4">
                {left}
            </div>
            <div className="col-xl-8">
                {right}
            </div>
        </div>
    )
}

export default memo(PageLayout1);