import React, { memo } from 'react';
import { Formik, FastField } from 'formik';
import FInput from '../formik/Input';
import Wrap from '../components/FormWrap';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import { validateSchema, required } from '../validators';

const initialValues = { 
    apiKey: ''
};

const validateCb = validateSchema(Joi.object({
    apiKey: required('Token'),
}));

function BearerAuthForm({
    onSubmit, forwardedRef, 
    values, nested
}) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {() => (
                <Wrap nested={nested}>
                    <FastField 
                        name="apiKey" 
                        label="Token"
                        autoFocus={autoFocus}
                        description="Your API Bearer Token"
                        component={FInput} 
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(BearerAuthForm);