import {
    typeCreateAction,
    fetchActions,
    typeUpdateAction,
    typeDeleteAction
} from '../constants';
import { apiAction } from './api';

export function createAction(props) {
    return apiAction({
        path: `/actions`,
        method: 'post',
        name: typeCreateAction,
        ...props
    });
}

export function updateAction(id, props) {
    return apiAction({
        path: `/actions/${id}`,
        method: 'post',
        name: typeUpdateAction,
        ...props
    });
}

export function getActions() {
    return apiAction({
        path: `/actions`,
        name: fetchActions
    });
}

export function deleteAction(id) {
    return apiAction({
        path: `/actions/${id}`,
        method: 'del',
        name: typeDeleteAction,
        payload: {
            id
        }
    });
}