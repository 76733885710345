import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { addChildNode } from '../../actions/node';
import Node from '../Node';
import './index.sass';

function Nodes({ 
	addNewNode, 
	hasNodes
}) {
	const reqNewNode = useCallback(() => addNewNode(), [addNewNode]);

	if (hasNodes) {
		return (<Node id="root"/>);
	}

	return (
		<button onClick={reqNewNode} className="btn btn-primary">
			Add node
		</button>
	);
}

Nodes.propTypes = {
	addNewNode: PropTypes.func.isRequired
};
        
export default connect(
    state => ({
		hasNodes: Object.keys(state.nodes).length > 1
	}), 
    {
		addNewNode: addChildNode
    }
)(Nodes);