import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PageContent from '../PageContent';
import SubHeader from './SubHeader';
import UserForm from './UserForm';
import PortletFormWrap from '../../components/PortletForm/Wrap';

function UserPage({ id }) {
    const ref = useRef();
    return (
        <PageContent 
			subheader={<SubHeader id={id} syncRef={ref}/>}
		>
            <PortletFormWrap>
                <UserForm id={id} syncRef={ref} />
            </PortletFormWrap>
        </PageContent>
    )
}

export default connect(
    state => ({
    }),
    {},
    (s,d,{match}) => ({
        id: match.params.id
    })
)(UserPage);