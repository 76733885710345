import React, { memo } from 'react';
import classnames from 'classnames';

function KTWidget({
    className,
    type,
    actions,
    title,
    description
}) {
    return (
        <div className={classnames('kt-widget', {
            [`kt-widget--${type}`]: type
        }, className)}>
            <div className="kt-widget__top">
                {/* kt-widget__media */}
                <div className="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest kt-font-light">
                    Skill
                </div>
                <div className="kt-widget__content">
                    <div className="kt-widget__head">
                        <div className="kt-widget__title">
                            { title }
                        </div>
                        <div className="kt-widget__action">
                            { actions }
                        </div>
                    </div>
                    <div className="kt-widget__info">
                        <div className="kt-widget__desc">
                            { description }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(KTWidget);