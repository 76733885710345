import React, {memo} from 'react';
import Portlet from '../../components/Portlet';
import Pagination from './Pagination';
import Table from '../../components/Table';
import TableBody from './TableBody';
import Fetcher from './Fetcher';
import Span from '../../components/Static/Span';
import Tags from '../../components/Static/Tags';
import { UserCardLink as UserCard } from '../../components/UserCard';
// import OnlineStatus from '../../components/OnlineStatus';
import RowActions from './RowActions';
import { userPropsByListKey, stateProps } from '../../connect/users';
import LoaderBlock from '../../components/LoaderBlock';

const Loader = stateProps(u => ({
    loading: u.loading
}))(LoaderBlock);

const columns = [{
    Head: 'User',
    width: '300px',
    Cell: userPropsByListKey(u => ({
        firstName: u.firstName,
        lastName: u.lastName,
        position: u.position,
        link: `/users/${u._id}`
    }))(UserCard)
},{
    Head: 'E-mail',
    width: '200px',
    Cell: userPropsByListKey(u => ({
        string: u.email
    }))(Span)
},{
    Head: 'Roles',
    width: '300px',
    Cell: userPropsByListKey(u => ({
        tags: u.roles
    }))(Tags)
},{
    width: '100px',
    Head: 'Actions',
    Cell: RowActions
}
// ,{
//     width: '100px',
//     Head: 'Status',
//     Cell: OnlineStatus
// }
];

function UsersTable() {
    return (
        <Portlet
            className="table-type1"
            mobile={true}
            bodyFit={true}
        >
            <Fetcher/>
            <Table columns={columns}>
                <TableBody columns={columns}/>
            </Table>
            <Pagination/>
            <Loader/>
        </Portlet>
    )
}

export default memo(UsersTable);