import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { setSubheader } from '../../actions/layout';
import classnames from 'classnames';
import InfoRoutes from '../Router/InfoRoutes';
import './index.sass';

const log = Logger('PageContent');

function PageContent({ 
    subheader,
    header, 
    children, 
    setSubheader, 
    className,
    fixedHeight
}) {
    log.debug('render');

    const hasSubheader = subheader ? true : false;

    useLayoutEffect(() => {
        if (hasSubheader) {
            setSubheader(true);
            return () => setSubheader(false);
        }
    }, [ hasSubheader, setSubheader ]);

    return (
        <div className={classnames('metronic kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor', className, {
            'fixed-height': fixedHeight,
            'has-subheader': hasSubheader
        })}>
            { hasSubheader ? subheader : null }
            <div className={classnames("kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor", {
                'kt-content--fit-top': header ? true : false
            })}>
                {
                    header
                    ? header
                    : null
                }
                <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
                    <InfoRoutes/>
                    { children }
                </div>
            </div>
        </div>
    )
}

PageContent.propTypes = {
    setSubheader: PropTypes.func.isRequired
};

export default connect(
    null,
    { 
        setSubheader 
    }
)(PageContent);