import {
    typeNewIntent,
    typeCreateUtterance,
    typeUpdateUtterance,
    typeDeleteUtterance,
    typeDeleteIntent,
    typeUpdateIntentName,
    typeDeleteEntity,
    fetchTrainingModelSuccess,
    fetchTrainingModel
} from '../constants';
import _get from 'lodash/get';

function utterances(state, type, payload){
    switch (type) {
        case typeDeleteUtterance: {
            delete state[payload.id];
            return {
                ...state,
                ids: state.ids.filter(id => id !== payload.id)
            };
        }
        case typeCreateUtterance:
            return {
                ...state,
                [payload.id]: {
                    id: payload.id,
                    data: payload.data
                },
                ids: [
                    payload.id,
                   ...state.ids
                ]
            };
        case typeUpdateUtterance: 
            return {
                ...state,
                [payload.id]: {
                    id: payload.id,
                    data: payload.data
                }
            };
        default:
            return state;
    }
}

function intent(state, type, payload){
    switch (type) {
        case typeDeleteUtterance:
        case typeUpdateUtterance:
        case typeCreateUtterance:
            return {
                ...state,
                items: utterances(state.items, type, payload)
            };
        case typeUpdateIntentName: {
            let name = payload.name.trim().replace(/ /g,"_").replace(/[^a-zA-Z0-9_-]/g, "");
            return {
                ...state,
                name: name || state.id
            };
        }
        default:
            return state;
    }
}

export default function(state = {}, { type, payload }){
    switch (type) {
        case fetchTrainingModelSuccess: 
            return {
                ...payload.data.intents
            };
        case typeDeleteEntity: {
            Object.keys(state).forEach(id => {
                Object.keys(state[id].items).forEach(_id => {
                    if (_id === 'ids') return;
                    let { blocks, entityMap } = state[id].items[_id].data;
                    Object.keys(entityMap).forEach(key => {
                        if (entityMap[key].data.entity === payload) {
                            delete state[id].items[_id].data.entityMap[key];
                            state[id].items[_id].data.blocks[0].entityRanges = 
                                blocks[0].entityRanges.filter(el => parseInt(el.key) !== parseInt(key));
                        }
                    });
                });    
            });
            return {
                ...state,
            };
        } 
        case typeUpdateIntentName:
        case typeDeleteUtterance:
        case typeUpdateUtterance:
        case typeCreateUtterance: {
            return {
                ...state,
                [payload.intent]: intent(state[payload.intent], type, payload)
            };
        }
        case typeDeleteIntent: {
            delete state[payload];
            return {
                ...state
            };
        }
        case typeNewIntent: {
            return {
                [payload]: {
                    id: payload,
                    name: payload,
                    items: {
                        ids: []
                    }
                },
                ...state
            };
        }
        case fetchTrainingModel:
            return {};
        default:
            return state;
    }
}

export function getFirstUtterance(node) {
    const first = node.items.ids[0] || null;
    return _get(node.items[first], 'data.blocks[0].text', '');
}

export function getCurrentUtterance(state) {
    if (!state.currentIntent || !state.currentUtterance) {
        return null;
    }
    return state.intents[state.currentIntent].items[state.currentUtterance];
}