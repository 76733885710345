import React, { memo, useCallback, useMemo } from 'react';
import Logger from '../../../utils/logger';
import _isArray from 'lodash/isArray';

const log = Logger('Forms-Input');

function FormsInput({  
    value, 
    error,
    onChange,
}) {
    log.debug('render');
    const _onChange = useCallback((type, id) => {
        return e => {
            let r = value ? [...value] : [];
            if (id > r.length - 1) {
                r.push({
                    [type]: e.target.value
                });
            } else {
                r = value.map((el, k) => {
                    if (k === id) {
                        if (!e.target.value) {
                            delete el[type];
                            return {...el};
                        }
                        return {
                            ...el,
                            [type]: e.target.value
                        };
                    }
                    return el;
                });
                r = r.filter(el => Object.keys(el).length);
            }
            if (onChange) onChange(r);
        };
    }, [onChange, value]);

    const values = useMemo(() => {
        const _v = _isArray(value) ? [...value] : [];
        _v.push({});
        return _v;
    }, [value]);

    return values.map((el, key) => (
        <div key={key} className="row kt-margin-b-10">
            <div className="col-lg-6">
                <input 
                    type="text"
                    className={`form-control${error ? ' is-invalid': ''}`} 
                    placeholder="Key"
                    value={el.key || ''}
                    onChange={_onChange('key', key)} 
                />
            </div>
            <div className="col-lg-6">
                <input 
                    type="text"
                    className={`form-control${error ? ' is-invalid': ''}`} 
                    placeholder="Value"
                    value={el.value || ''}
                    onChange={_onChange('value', key)} 
                />
            </div>
        </div>
    )) 
}

export default memo(FormsInput);