import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import EntityMenuItem from './EntityMenuItem';
import { newEntity } from '../../actions/entity';
import uuid from 'uuid/v1';

const log = Logger('EntityMenu');

function EntityMenu({data, value, onSelect, focus, newEntity}) {
    log.debug('render');
    const [search, setSearch] = useState('');
    const sInput = useRef();

    useEffect(() => {
        if (focus) sInput.current.focus();
    }, [focus]);

    const valueName = useMemo(() => {
        if (!value) return null;
        let name = '';
        Object.keys(data).find(k => {
            if (data[k].id === value) {
                name = data[k].name;
                return true;
            }
            return false;
        });
        return name;
    }, [value, data]);

    const _data = useMemo(() => {
        var dataArr = Object.keys(data).map(k => ({
            name: data[k].name,
            id: data[k].id
        }));
        if (!search) {
            if (value) 
                return dataArr.filter(({id}) => id!==value);
            if (dataArr.length) 
                return dataArr;
            return null;
        }
        return dataArr.filter(({name}) => {
            let _el = name.toLowerCase();
            let _search = search.toLowerCase();
            if (_el === _search) return false;
            return _el.indexOf(_search) !== -1; 
        });
    }, [data, search, value]);

    const onSearch = useCallback(e => setSearch(e.target.value), []);
    const _onSelect = useCallback(item => {
        if (search) {
            if (item && !item.id && item.name === search) {
                item.id = uuid();
                newEntity(item);
            }
            setSearch('');
        }
        onSelect(item);
    }, [search, onSelect, newEntity]);
    const onKeyDown = useCallback(e => {
        if (e.key === 'Enter' && search) _onSelect({id: null, name: search});
    }, [search, _onSelect]);

    return ( 
        <React.Fragment>
            <div className="search">
                <div className="kt-searchbar">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-search">
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </span>
                        </div>
                        <input 
                            autoFocus
                            ref={sInput}
                            onChange={onSearch}
                            onKeyDown={onKeyDown}
                            value={search} 
                            type="text" 
                            className="form-control" 
                            placeholder={_data ? "Search" : "Entity Name"}
                            aria-describedby="basic-addon1"
                        />
                    </div>
                </div>
            </div>
            {
            _data
            ? <ul className="kt-nav">
                {
                    search
                    ? <EntityMenuItem 
                        name={search} 
                        id={null}
                        onSelect={_onSelect} 
                        active={true}
                    />
                    : null
                }
                {
                    value && !search
                    ? <EntityMenuItem 
                        name={valueName} 
                        id={value}
                        onSelect={_onSelect} 
                        current={true}
                    />
                    : null
                }
                {
                    _data.map(({name, id}, key) => (
                        <EntityMenuItem 
                            key={key}
                            name={name} 
                            id={id}
                            onSelect={_onSelect}
                        />
                    ))
                }
            </ul>
            : null
            }
        </React.Fragment>
    )
}

EntityMenu.propTypes = {
    data: PropTypes.object.isRequired,
    newEntity: PropTypes.func.isRequired
};

export default connect(
    state => ({
        data: state.entities
    }),
    { newEntity }
)(EntityMenu);