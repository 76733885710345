import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Logger from '../../utils/logger';
import ActionGonfig from './ActionGonfig';
import { 
    actionsLengthSelector 
} from '../../reducers/forms/skillNode';
import './ActionsSelector.sass';

const log = Logger('ActionsSelector');

function ActionsSelector({
    itemsN
}) {
    log.debug('render');    
    const items = useMemo(() => {
        let els = [];
        for (var i = 0; i < itemsN; i++) {
            els.push(<div key={i} className="col-6">
                <ActionGonfig confId={i}/>
            </div>);
        }
        return els;
    }, [itemsN]);

    if (!items.length) {
        return null;
    }

    return (
        <div className={`actions-list row${itemsN%2 === 0 ? ' rowf' : ''}`}>
            { items }
        </div>
    )
}

export default connect(
    state => ({
        itemsN: actionsLengthSelector(state)
    }),
    {}
)(ActionsSelector);