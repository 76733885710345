import React, { useLayoutEffect } from 'react';
import Logger from '../../utils/logger';
import HeaderMenu from '../../components/Header/HeaderMenu';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getSkillData, cleanSkillData, getSkills } from '../../actions/skill';
import { getTrainingModel } from '../../actions/trainModel';
import { getActions } from '../../actions/actions';
import { getAvailableIntegrations, getIntegrations } from '../../actions/integrations';

const log = Logger('SkillPagaHeaderMenu');

function SkillPagaHeaderMenu({
    skillId, fetchData, reset,
    hasSkills, getSkills,
    fetchTrainingData, trainingDataIsFetched,
    fetchActions, actionsIsFetched,
    fetchIntegrations, fetchAvailableIntegrations, integrationsIsFetched
}) {
    log.debug('render');

    useLayoutEffect(() => {
        if (skillId !== 'new') fetchData(skillId);
        return () => reset();
    }, [skillId, fetchData, reset]);

    useLayoutEffect(() => {
        if (!hasSkills) getSkills();
    }, [hasSkills, getSkills]);

    useLayoutEffect(() => {
        if (!trainingDataIsFetched) fetchTrainingData();
    }, [fetchTrainingData, trainingDataIsFetched]);

    useLayoutEffect(() => {
        if (!actionsIsFetched) fetchActions();
    }, [fetchActions, actionsIsFetched]);

    useLayoutEffect(() => {
        if (!integrationsIsFetched) {
            fetchAvailableIntegrations();
            fetchIntegrations();
        }
    }, [fetchIntegrations, fetchAvailableIntegrations, integrationsIsFetched]);

    if (skillId === 'new') {
        return null;
    }

    return (
        <HeaderMenu
            items={[{
                title: 'General',
                link: `/skills/${skillId}/general`
            },{
                title: 'Dialogue nodes',
                link: `/skills/${skillId}`
            }
            // ,{
            //     title: 'Publish skill',
            //     link: `/skills/${skillId}/publish`
            // }
        ]}
        />
    )
}

SkillPagaHeaderMenu.propTypes = {
	fetchData: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    getSkills: PropTypes.func.isRequired,
    hasSkills: PropTypes.bool.isRequired,
    fetchTrainingData: PropTypes.func.isRequired,
    trainingDataIsFetched: PropTypes.bool.isRequired,
    fetchActions: PropTypes.func.isRequired,
    actionsIsFetched: PropTypes.bool.isRequired,
    fetchIntegrations: PropTypes.func.isRequired,
    integrationsIsFetched: PropTypes.bool.isRequired,
    fetchAvailableIntegrations: PropTypes.func.isRequired
};

export default connect(
    state => ({
        hasSkills: state.skills.length ? true : false,
        trainingDataIsFetched: state.training.isFetched,
        actionsIsFetched: state.actions.length ? true : false,
        integrationsIsFetched: state.availableIntegrations.length ? true : false
    }), 
    {
		fetchData: getSkillData,
        reset: cleanSkillData,
        getSkills,
        fetchTrainingData: getTrainingModel,
        fetchActions: getActions,
        fetchIntegrations: getIntegrations,
        fetchAvailableIntegrations: getAvailableIntegrations
    }
)(SkillPagaHeaderMenu);