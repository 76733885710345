import React, { memo, useMemo } from 'react';
import Logger from '../../utils/logger';
import KTInputIcon from '../KTInputIcon';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';

const log = Logger('SubHeader');

function SubHeader({ 
    title, 
    toolbar, 
    leftToolbar,
    onSearch,
    searchValue,
    desc
}) {
    log.debug('render');

    const _leftToolbar = useMemo(() => {
        const data = [];
        if (desc) data.push(<div key="desc" className="kt-subheader__desc">{desc}</div>);
        if (onSearch) data.push(
            <KTInputIcon
                key="search"
                className="kt-subheader__search kt-margin-l-20"
                placeholder='Search...'
                onChange={onSearch}
                value={searchValue}
                icon={<i className="kt-font-sm"><SearchIcon/></i>}
            />
        );
        if (leftToolbar) data.push(<React.Fragment key="lToolbar">{leftToolbar}</React.Fragment>);
        return data;
    }, [leftToolbar, onSearch, desc, searchValue]);

    return (
        <div id="kt_subheader" className="metronic kt-subheader kt-grid__item">
            <div className="kt-container kt-container--fluid">
            <div className="kt-subheader__main">
                { 
                    title
                    ? <h3 className="kt-subheader__title">{title}</h3>
                    : null
                }
                {
                    title && _leftToolbar.length
                    ? <div className="kt-subheader__separator kt-subheader__separator--v"></div>
                    : null
                }
                {
                    _leftToolbar.length
                    ? (
                        <div className="kt-subheader__group">
                            { _leftToolbar }
                        </div>
                    ) : null
                }
            </div>
            <div className="kt-subheader__toolbar">
                <div className="kt-subheader__wrapper">
                    { toolbar }
                </div>
            </div>
            </div>
        </div>
    )
}

export default memo(SubHeader);