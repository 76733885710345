import React, { memo, useLayoutEffect } from 'react';
import bgImage from '../../images/bg-3.jpg';
import { ReactComponent as HalaIcon }  from '../../icons/hala-logo-original.svg';
// import PulseLoader from '../PulseLoader';
import './index.sass';

const _cl = 'authLayout';
function AuthLayout({children, loading}) {

    useLayoutEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add(_cl);
        return () =>  body.classList.remove(_cl);
    }, []);

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--signin"
            style={{
                backgroundImage: `url(${bgImage})`,
                minHeight: '100vh'
            }}
        >
            <div className="kt-login__wrapper">
                <div className="kt-login__container">
                    <div className="kt-login__logo">
                        {/* {
                            loading
                            ? <PulseLoader/>
                            : null
                        } */}
                        <HalaIcon style={{margin: '0 auto'}}/>
                    </div>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default memo(AuthLayout);