import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import PageItemsGrid from '../PageItemsGrid';
import KTProject1 from '../../components/KTProject1';
import ItemMenu from './ItemMenu';

const log = Logger('IntegrationsPage');

function IntegrationsPage({
    data
}) {
    log.debug('render');

    const item = useCallback(el => (
        <KTProject1 
            title={el.name || el.general.name}
            onTitle={`/integrations/${el.type}/${el.id}`}
            toolbarMenu={<ItemMenu id={el.id} type={el.type} />}
        />
    ), []);

    const onFilter = useCallback((el, filter) => {
        if ((el.name || el.general.name).toLowerCase().indexOf(filter) !== -1) {
            return true;
        } else if (el.type.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        return false;
    }, []);

    return (
        <PageItemsGrid
            title="Integrations"
            item={item}
            data={data}
            actionBrandTo="/integrations/new"
            actionBrandTitle="Add Integration"
            onFilter={onFilter}
        />
    )
}

IntegrationsPage.propTypes = {
    data: PropTypes.array.isRequired
};

export default connect(
    state => ({
        data: state.integrations
    }),
    {}
)(IntegrationsPage);