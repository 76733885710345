import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import Utterance from './Utterance';
import './UtterancesList.sass';

const log = Logger('UtterancesList');

function UtterancesList({utterances}) {
    log.debug('render');
    return (
        <div className="kt-widget1 kt-widget1_1 utterances-list">
            {
                utterances.map(id => (
                    <Utterance key={id} id={id} />
                ))
            }
        </div>
    )
}

UtterancesList.propTypes = {
    utterances: PropTypes.array.isRequired
};

export default connect(
    state => {
        const intent = state.intents[state.currentIntent];
        return {
            utterances: intent ? intent.items.ids : []
        }
    },
    {
    }
)(UtterancesList);