import {
    EditorState,
    Modifier,
    ContentState,
    SelectionState,
    convertFromRaw
} from 'draft-js';

export function moveSelectionToEnd(editorState) {
    let contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const length = firstBlock.getLength();
    let newSelectionState = editorState.getSelection().merge({
        anchorOffset: length,
        focusOffset: length
    });
    return EditorState.forceSelection(editorState, newSelectionState);
}

export function createStrategy(type){
    return function(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === type
            );
        }, callback);
    };
}

export function addEntityOnSelection(
    editorState, 
    type, 
    mutability = 'IMMUTABLE', 
    data = {}
) {
    var selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    if (!selectedText.trim()) {
        throw new Error('no selection');
    }
    let contentStateWithEntity = currentContent.createEntity(
        type,
        mutability,
        {
            selectedText,
            start,
            end,
            ...data
        }
    );

    contentStateWithEntity = Modifier.applyEntity(
        contentStateWithEntity,
        selectionState,
        contentStateWithEntity.getLastCreatedEntityKey()
    );

    editorState= EditorState.push(
        editorState,
        contentStateWithEntity,
        'add-entity'
    );

    return editorState;
}

export function createEmptyState(rawContent, decorator) {
    if (rawContent) {
        if (!rawContent.entityMap) {
            rawContent.entityMap = {};
        }
        return EditorState.createWithContent(convertFromRaw(rawContent), decorator);
    }
    return EditorState.createEmpty(decorator);
}

export function clearState(editorState) {
    editorState = EditorState.push(editorState, ContentState.createFromText(''));
    return EditorState.forceSelection(editorState, editorState.getCurrentContent().getSelectionAfter());
}

export function getEntities(editorState, condition) {
    const content = editorState.getCurrentContent();
    const entities = [];
    content.getBlocksAsArray().forEach(block => {
        block.findEntityRanges(
            character => {
                const ent = character.getEntity();
                if (ent !== null) {
                    const entity = content.getEntity(ent);
                    return condition ? condition(entity) : true;
                }
                return false;
            },
            (start, end) => entities.push({start, end}));
    });
    return entities;
}

export function removeEntities(editorState, condition) {
    var entities = getEntities(editorState, condition);
    if (entities.length) {
        entities.forEach(e => {
            let contentState = editorState.getCurrentContent();
            let selectionState = editorState.getSelection();
            selectionState = selectionState.merge({
                anchorOffset: e.start,
                focusOffset: e.end
            });
            editorState = EditorState.push(
                editorState,
                Modifier.applyEntity(
                    contentState,
                    selectionState,
                    null
                ),
                'remove-entity'
            );
        });
    }
    return editorState; 
}

export function getEntityAtSelection(editorState){
    const selectionState = editorState.getSelection();
    const selectionKey = selectionState.getStartKey();
    const contentstate = editorState.getCurrentContent();
    const block = contentstate.getBlockForKey(selectionKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());
    if (entityKey) {
        return {
            entityKey,
            data: contentstate.getEntity(entityKey).getData()
        };
    }
    return {};
}

export function clear(editorState){
    let contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: firstBlock.getKey(),
      focusOffset: firstBlock.getLength(),
      hasFocus: true
    });
    contentState = Modifier.removeRange(contentState, allSelected, 'backward');
    editorState = EditorState.push(editorState, contentState, 'remove-range');
    editorState = EditorState.forceSelection(editorState, contentState.getSelectionAfter());
    return editorState;
}