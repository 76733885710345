import React, { memo, useMemo, useState, useCallback, useRef, useLayoutEffect } from 'react';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import SelectorItem from './Item';
import './index.sass';

const log = Logger('Selector');

function Selector({  
    data,
    onFilter,
    renderItem,
    onSelect,
    value,
    width = 300,
    height = 200
}) {
    log.debug('render');
    const [search, setSearch] = useState('');
    const sInput = useRef();

    useLayoutEffect(() => {
        sInput.current.focus();
    }, []);

    const reqSearch = useCallback(e => setSearch(e.target.value), []);
    const _onSelect = useCallback(item => {
        if (search) setSearch('');
        if (onSelect) onSelect(item);
    }, [search, onSelect]);
    const _data = useMemo(() => {
        if (!search || !onFilter) {
            if (value) 
                return data.filter(el => el!==value);
            return data;
        }
        const _search = search.toLowerCase();
        return data.filter(el => onFilter(el, _search));
    }, [data, search, onFilter, value]);
    const onKeyDown = useCallback(e => {
        if (e.key === 'Enter' && search) {
            if (_data[0]) _onSelect(_data[0]);
        }
    }, [search, _data, _onSelect]);
    
    return ( 
        <div className="selector">
            <div className="search">
                <div className="kt-searchbar">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fa fa-search">
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </span>
                        </div>
                        <input 
                            autoFocus
                            ref={sInput}
                            onChange={reqSearch}
                            onKeyDown={onKeyDown}
                            value={search} 
                            type="text" 
                            className="form-control" 
                            placeholder="Search"
                        />
                    </div>
                </div>
            </div>
            <ul className="kt-nav" 
                style={{
                    width: `${width}px`,
                    maxHeigth: `${height}px`
                }}
            >
                {
                    value && !search
                    ? <SelectorItem 
                        value={value}
                        onSelect={_onSelect} 
                        current={true}
                    >    
                        { renderItem(value) }
                    </SelectorItem>
                    : null
                }
                {
                    _data.map((el, key) => (
                        <SelectorItem 
                            key={key}
                            value={el}
                            onSelect={_onSelect}
                            active={search && key === 0}
                        >
                            { renderItem(el) }
                        </SelectorItem>
                    ))
                }
            </ul>
        </div>
    )
}

Selector.propTypes = {
    data: PropTypes.array
};

export default memo(Selector);