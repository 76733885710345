import React, { memo, useCallback } from 'react';
import Logger from '../../utils/logger';
import { faTimesCircle, faCheck} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const log = Logger('SelectorItem');

function SelectorItem({value, onSelect, active, current, children}) {
    log.debug('render');

    const onClick = useCallback(() => {
        onSelect(current ? '' : value);
    }, [onSelect, current, value]);

    return (
        <li onClick={onClick} className={classnames("kt-nav__item", {
            active: active || current
        })}>
            <div className="kt-nav__link">
                <span className="kt-nav__link-text">
                    { children }
                </span>
                <span className="kt-nav__link-badge">
                    <i>
                        <FontAwesomeIcon icon={current ? faTimesCircle : faCheck}/>
                    </i>
                </span>
            </div>
        </li>
    )
}

export default memo(SelectorItem);