import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { deleteEntity } from '../../actions/entity';
import DeleteIconConfirmation from '../../components/DeleteIconConfirmation';

function EntityContentToolbar({id, deleteEntity, ...props}) {
    const onYes = useCallback(() => deleteEntity(id), [id, deleteEntity]);
    return <DeleteIconConfirmation id={id} onYes={onYes} {...props}/>
}

export default connect(
    state => ({
        id: state.currentEntity
    }),
    { deleteEntity }
)(EntityContentToolbar);