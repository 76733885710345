import React, { memo } from 'react';
import Logger from '../../utils/logger';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const log = Logger('TextTooltip');

function TextTooltip({
    text, position, children, ...props
}) {
    log.debug('render');
    return (
        <OverlayTrigger
            placement={position}
            {...props}
            overlay={
                <Tooltip>
                    {text}
                </Tooltip>
            }
        >
            { children }
        </OverlayTrigger>
    )
}

export default memo(TextTooltip);