import React, {
    useEffect ,useRef, useMemo, 
    useState, useCallback,
    useLayoutEffect
} from 'react';
import {
    BoldButton,
    ItalicButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    FAIconButton
} from '../../components/Editor/buttons';
import { faPlus, faCheck, faImage, faVideo, faBallotCheck } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createImagePlugin from 'draft-js-image-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import { composeDecorators } from 'draft-js-plugins-editor';
import createVideoPlugin from 'draft-js-video-plugin';
import createQuickReplyPlugin from '../../utils/draftJs/quickReplyPlugin';
import createToolbarPlugin from '../../utils/draftJs/toolbarPlugin';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-alignment-plugin/lib/plugin.css';
import classnames from 'classnames';
import { EditorState } from 'draft-js';

const toolbarPlugin = createToolbarPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const alignmentPlugin = createAlignmentPlugin();
const quickReplyPlugin = createQuickReplyPlugin();

const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin({ decorator });
const ToolbarRoot = toolbarPlugin.Toolbar;

export const AlignmentTool = alignmentPlugin.AlignmentTool;
export const plugins = [
    toolbarPlugin,
    focusPlugin,
    alignmentPlugin,
    resizeablePlugin,
    imagePlugin,
    videoPlugin,
    quickReplyPlugin
];

const theme = {
    button: 'btn btn-clean btn-icon',
    active: 'active'
};

export function Toolbar() {
    const focus = useCallback(({getEditorState, setEditorState}) => {
        return () => {
            const state = getEditorState();
            if (!state.getSelection().getHasFocus()) {
                setEditorState(EditorState.moveFocusToEnd(state));
            }
        };
    }, []);

    return (
        <ToolbarRoot>
            {
                externalProps => (
                    <div onMouseDown={focus(externalProps)} className="btn-group">
                        <BoldButton {...externalProps} theme={theme}/>
                        <ItalicButton {...externalProps} theme={theme}/>
                        <UnderlineButton {...externalProps} theme={theme}/>
                        <UnorderedListButton {...externalProps} theme={theme}/>
                        <OrderedListButton {...externalProps} theme={theme}/>
                    </div>
                )
            }
        </ToolbarRoot>
    )
}

export function SideToolbar() {
    return (
        <ToolbarRoot>
            {
                externalProps => (
                    <SideToolbarD {...externalProps}/>
                )
            }
        </ToolbarRoot>
    )
}

function preventBubblingUp(e) {
    e.preventDefault();;
}

function ToolbarInput({
    onBlur,
    onSave
}) {
    const [value, setValue] = useState('https://via.placeholder.com/300');
    const save = useCallback(() => onSave(value), [onSave, value]);
    const onKeyDown = useCallback(e => {
        if (e.key === 'Enter') save();
    }, [save]);
    const onChange = useCallback(e => setValue(e.target.value),[]);
    return (
        <div className="kt-input-icon kt-input-icon--right">
            <input 
                autoFocus
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={onKeyDown}
                type="text" 
                value={value}
                className="form-control" 
                placeholder="url..."
            />
			<span className="kt-input-icon__icon kt-input-icon__icon--right">
			    <span>
                    <FontAwesomeIcon 
                        onClick={save} 
                        icon={faCheck} 
                        onMouseDown={preventBubblingUp}
                    />
                </span>
			</span>
        </div>
    )
}

function getNoneTopByKey(key) {
    const node = document.querySelector(
        `[data-offset-key="${key}-0-0"]`
    );
    return `${node ? (node.offsetTop - 8) : 0}px`;
}

function preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
}

export function SideToolbarD({getEditorState, setEditorState}) {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    const ref = useRef();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
    const key = currentBlock.getKey();
    const hasFocus = selection.getHasFocus();
    const [showInput, setShowInput] = useState(false);
    const [showToolbar, setShowToolbar] = useState(false);
    const reqHideInput = useCallback(() => setShowInput(false), []);
    const onAddImage = useCallback(url => {
        reqHideInput();
        setEditorState(
            showInput === 'video'
            ? videoPlugin.addVideo(editorState, {src: url})
            : imagePlugin.addImage(editorState, url)
        );
        setShowToolbar(false);
    }, [showInput, reqHideInput, editorState, setEditorState]);

    const addQuickReply = useCallback(() => {
        setShowToolbar(false);
        setEditorState(quickReplyPlugin.addBlock(editorState));
    }, [editorState, setEditorState]);

    const toggleShowBlocks = useCallback(() => setShowToolbar(st => !st), []);

    let style = {
        top: getNoneTopByKey(key),
        display: (hasFocus || showToolbar) ? 'block' : 'none'
    };

    useLayoutEffect(() => {
        ref.current.style.top = getNoneTopByKey(key);
        // eslint-disable-next-line 
    }, [style]);
    useEffect(() => {
        if (!showToolbar && showInput) setShowInput(false);
    }, [showToolbar, showInput]);

    const inputToolbar = useMemo(() => (
        <ToolbarInput
            onBlur={reqHideInput}
            onSave={onAddImage}
        />
    ),[reqHideInput, onAddImage]);

    const toolbar = useMemo(() => (
        <ToolbarRoot>
            {
                () => (
                    <div className="btn-group">
                        <FAIconButton 
                            onClick={() => setShowInput('image')}
                            icon={faImage}
                            label="Image"
                        />
                        <FAIconButton 
                            onClick={() => setShowInput('video')}
                            icon={faVideo}
                            label="Video"
                        />
                        <FAIconButton 
                            onClick={addQuickReply}
                            icon={faBallotCheck}
                            label="Quick Reply"
                        />
                    </div>
                )
            }
        </ToolbarRoot>
    ), [addQuickReply]);

    return (
        <div onMouseDown={preventDefault} ref={ref} style={style} className="editor-side-toolbar">
            <div 
                onClick={toggleShowBlocks}
                className={classnames("btn btn-clean btn-icon btn-circle toolbar-icon", {
                    'active': showToolbar
                })}
            >
                <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="toolbar-wrap">
                {
                    showToolbar
                    ? (
                        showInput
                        ? inputToolbar
                        : toolbar
                    ) : null
                }
            </div>
        </div>
    )
}