import {
    fetchTrainingModel,
    typeSaveTrainingModel,
    typeTrainModel,
    fetchTrainingModelStatus
} from '../constants';
import { apiAction } from './api';
import uuid from 'uuid/v1';

export const trainModel = () => {
    return apiAction({
        path: `/training/exec`,
        name: typeTrainModel
    });
};

export const getTrainingModelStatus = () => {
    return apiAction({
        path: `/training/model/status`,
        name: fetchTrainingModelStatus
    });
};

export const getTrainingModel = () => {
    return apiAction({
        path: `/training/model`,
        name: fetchTrainingModel,
        transform: transformData
    });
};

export const saveTrainingModel = () => ((dispatch, getState) => {
    const {entities, intents} = getState();
    dispatch(apiAction({
        path: `/training/model`,
        method: 'post',
        name: typeSaveTrainingModel,
        data: {
            entities: formatEntities(entities),
            intents: formatIntents(intents)
        }
    }));
});

function formatEntities(data) {
    data = JSON.parse(JSON.stringify(data));
    const ents = [];
    Object.keys(data).forEach(id => {
        const el = data[id];
        const vals = el.items ? Object.keys(el.items) : [];
        ents.push({
            id,
            data: {
                entity: el.name.trim(),
                values: vals.map(id => ({
                    value: el.items[id].name.trim(),
                    synonyms: (el.items[id].synonyms || []).map(v => v.trim())
                }))
            }
        });   
    });
    return ents;
}

function formatIntents(intents) {
    const data = JSON.parse(JSON.stringify(intents));
    const ints = [];
    Object.keys(data).forEach(id => {
        const el = data[id];
        if (el.items) {
            delete el.items.ids;
        } else {
            el.items = {};
        }
        const vals = Object.keys(el.items);
        ints.push({
            id,
            data: {
                intent: el.name.trim(),
                examples: vals.map(id => {
                    const { blocks = [], entityMap = {} } = el.items[id].data;
                    const res = {
                        text: blocks[0].text.trim()
                    };
                    const eMap = Object.keys(entityMap);
                    if (eMap.length) {
                        res.mentions = eMap.map(k => ({
                            entity: entityMap[k].data.entity,
                            location: [entityMap[k].data.start, entityMap[k].data.end]
                        }));
                    }
                    return res;
                })
            }
        });
    });
    return ints;
}

function transformData(data) {
    if (data.entities && data.entities[0]) {
        const entities = {};
        data.entities.forEach(el => {
            entities[el.id] = {
                id: el.id,
                name: el.data.entity,
                items: {}
            };
            if (el.data.values) {
                el.data.values.forEach(val => {
                    const id = uuid();
                    entities[el.id].items[id] = {
                        id,
                        name: val.value,
                        synonyms: val.synonyms
                    };
                });
            }
        });
        data.entities = entities;
    }

    if (data.intents && data.intents[0]) {
        const intents = {};
        data.intents.forEach((el,k1) => {
            intents[el.id] = {
                id: el.id,
                name: el.data.intent,
                items: {
                    ids: []
                }
            };
            if (el.data.examples) {
                el.data.examples.forEach((e,k2) => {
                    const id = uuid();
                    intents[el.id].items.ids.push(id);
                    let entityRanges = [];
                    let entityMap = {};
                    if (e.mentions) {
                        e.mentions.forEach((m, key) => {
                            entityRanges.push({
                                offset: m.location[0],
                                length: m.location[1] - m.location[0],
                                key
                            });
                            entityMap[key] = {
                                type: "ENTITY",
                                mutability: "IMMUTABLE",
                                data: {
                                    selectedText: e.text.slice(m.location[0], m.location[1]),
                                    start: m.location[0],
                                    end: m.location[1],
                                    entity: m.entity
                                }
                            };
                        });
                    }
                    intents[el.id].items[id] = {
                        id,
                        data: {
                            blocks: [{
                                key: `${k1}f${k2}`,
                                text: e.text,
                                type: "unstyled",
                                depth: 0,
                                inlineStyleRanges: [],
                                data: {},
                                entityRanges
                            }],
                            entityMap
                        }
                    };
                });
            }
        });
        data.intents = intents;
    }
    return data;
}