import React, { memo, useCallback } from 'react';
import Logger from '../../utils/logger';
import EntityName from '../../components/EntityName';
import { faTimesCircle, faCheck} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const log = Logger('EntityMenuItem');

function EntityMenuItem({name, id, onSelect, active, current}) {
    log.debug('render');

    const onClick = useCallback(() => {
        onSelect(current ? null : {
            id, name
        });
    }, [onSelect, current, id, name]);

    return (
        <li onClick={onClick} className={classnames("kt-nav__item", {
            active: active || current
        })}>
            <div className="kt-nav__link">
                <span className="kt-nav__link-text">
                    <EntityName name={name}/>
                </span>
                <span className="kt-nav__link-badge">
                    <i>
                        <FontAwesomeIcon icon={current ? faTimesCircle : faCheck}/>
                    </i>
                </span>
            </div>
        </li>
    )
}

export default memo(EntityMenuItem);