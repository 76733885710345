import React, { memo } from 'react';
import { Formik, FastField } from 'formik';
import FInput from '../formik/Input';
import FSelect from '../formik/Select';
import Wrap from '../components/FormWrap';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import { validateSchema, required } from '../validators';

const authDataPlaces = [{
    value: 'headers',
    label: 'Request Headers'
}, {
    value: 'params',
    label: 'Request Params'
}];

const initialValues = { 
    apiKey: '',
    authParam: '',
    authParamPlace: authDataPlaces[0].value
};

const validateCb = validateSchema(Joi.object({
    apiKey: required('Api Key'),
    authParam: required('Api Key name'),
    authParamPlace: Joi.any(),
}));

function ApiKeyAuthForm({
    onSubmit, forwardedRef, 
    values, nested
}) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {() => (
                <Wrap nested={nested}>
                    <FastField 
                        name="apiKey" 
                        label="API Key"
                        autoFocus={autoFocus}
                        description="Specify API Key"
                        component={FInput} 
                    />
                    <FastField 
                        name="authParam" 
                        label="API Key parameter name"
                        description={`Your api key will be send via this parameter. Please check your API provider documentation. (Example: "access_key").`}
                        component={FInput} 
                    />
                    <FastField 
                        name="authParamPlace" 
                        component={FSelect} 
                        label="Add to"
                        description="Add authorization data to"
                        options={authDataPlaces}
                        convertToValues
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(ApiKeyAuthForm);