import React, { memo, useMemo } from 'react';
import S3Image from '../S3Image';
import './index.sass';

function DetailedName({
    name,
    description,
    image
}) {

    const _image = useMemo(() => {
        if (!image) return null;
        if (typeof image === 'string') return <S3Image src={image}/>;
        else return image;
    }, [image]);

    return (
        <div className="detailed-name">
            {
                _image
                ? (
                    <div className="kt-media kt-media--sm">
                        { _image }
                    </div>
                ) : null
            }
            <div className="detailed-name-info">
                <div className="detailed-name-value">
                    { name }
                </div>
                {
                    description
                    ? (
                        <div className="detailed-name-description">
                            { description }
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default memo(DetailedName);