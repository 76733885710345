import React, { 
    memo, useMemo, useState, 
    useCallback, useRef, useEffect, useLayoutEffect 
} from 'react';
import { Dropdown } from 'react-bootstrap';
import NavMenu from './NavMenu';

function _call(fn, ...params) {
    switch (typeof fn) {
        case 'boolean':
            return fn; 
        case 'function':
            return fn(...params); 
        default:
            return false;
    }
}

function DynamicDropdown({
    onSelect,
    options,
    toggle,
    toggleProps,
    value,
    ...props
}) {
    const ref = useRef();
    const [content, render] = useState(null);
    const [show, setShow] = useState(null);
    const _onToggle = useCallback(val => setShow(val), []);
    const _onSelect = useCallback((val, close = true) => {
        onSelect(val);
        if (close) setShow(false);
    }, [onSelect]);
    const _options = useMemo(() => options.map(el => {
        let _onClick = el.onClick;
        let _disabled = el.disabled;
        let _active = el.active;
        let close = true;
        const preventClose = () => close=false;
        el = {...el};
        el.disabled = _call(_disabled, value);
        el.active = _call(_active, value);
        el.onClick = e => {
            let res = null;
            switch (typeof _onClick) {
                case 'object':
                case 'string':
                    res = _onClick;
                    break;
                case 'function':
                    res = _onClick({
                        render, setShow, onSelect: _onSelect,
                        preventClose
                    }, e);
                    break;
                default:
                    break;
            }
            if (res) _onSelect(res, close);
        };
        return el;
    }), [options, _onSelect, value]);

    useLayoutEffect(() => {
        if (ref.current && content) {
            ref.current.scheduleUpdate && ref.current.scheduleUpdate();
        }
    }, [content]);
    useEffect(() => {
        if (!show) render(null);
    }, [show]);

    return (
        <Dropdown 
            show={show}
            onToggle={_onToggle}
            {...props}
        >
            <Dropdown.Toggle 
                childBsPrefix="btn-custom"
                bsPrefix="btn btn-clean"
                className="btn-label-brand btn-sm btn-bold dropdown-toggle"
                {...toggleProps}
            >
                {toggle}
            </Dropdown.Toggle>
            <Dropdown.Menu ref={ref} className="dropdown-menu-anim">
                {
                    content
                    ? content
                    : <NavMenu 
                        style={{minWidth: '16rem'}}
                        data={_options}
                    />
                }
            </Dropdown.Menu>			
	    </Dropdown>
    )
}

export default memo(DynamicDropdown);