import React, { memo, useMemo } from 'react';

function PaginationPageDetail({
    currentPage,
    pageSize,
    total
}) {
    const value = useMemo(() => {
        const from = parseInt(pageSize) * (parseInt(currentPage)-1);
        const to = from + parseInt(pageSize);
        return `${from + 1} - ${to > total ? total : to} of ${total}`;
    }, [currentPage, pageSize, total]);


    return useMemo(() => (
        <span className="kt-datatable__pager-detail">Showing {value}</span>
    ), [value]);
}

export default memo(PaginationPageDetail);