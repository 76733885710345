import {
    nodeFormActionSelect,
    nodeFormDeleteAction,
    nodeFormSetActionParameters,
    nodeFormAddAction,
    nodeFormActionResponse,
    nodeFormContextChange,
    nodeFormOutputChange,
    nodeFormUpdateBehavior
} from '../constants';

export const onActionSelect = payload => ({
    type: nodeFormActionSelect,
    payload
});

export const deleteAction = payload => ({
    type: nodeFormDeleteAction,
    payload
});

export const setActionParameters = payload => ({
    type: nodeFormSetActionParameters,
    payload
});

export const addAction = payload => ({
    type: nodeFormAddAction,
    payload
});

export const onActionResponseChange = payload => ({
    type: nodeFormActionResponse,
    payload
});

export const onContextChange = payload => ({
    type: nodeFormContextChange,
    payload
});

export const onOutputChange = payload => ({
    type: nodeFormOutputChange,
    payload
});

export const updateNodeBehavior = payload => ({
    type: nodeFormUpdateBehavior,
    payload
});