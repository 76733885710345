import React, { memo, useState, useCallback } from 'react';
import { Dropdown as RootDropdown } from 'react-bootstrap';
import CustomToggle from './CustomToggle';
import NavMenu from './NavMenu';
import classnames from 'classnames';

// const CustomToggle = forwardRef(({ onClick, children, ...props }, ref) => {

//     const _onClick = useCallback(e => {
//         e.preventDefault();
//         e.stopPropagation();
//         onClick(e);
//     }, [onClick]);

//     return (
//         <button 
//             onClick={_onClick} 
//             ref={ref} 
//             type="button" 
//             {...props} 
//             className="btn btn-clean btn-icon" 
//         >
//             { children }
//         </button>
//     )
// })

function Dropdown({ 
    toggle, 
    children, 
    className, 
    toggleClass,
    disabled,
    ...props
}) {
    const [show, setShow] = useState(false);
    const onClick = useCallback(e => {
        e.stopPropagation();
        setShow(false);
    }, [setShow]);

    const onRootClick = useCallback(e => e.stopPropagation(), []);

    return (
        <RootDropdown 
            className={className}
            show={show} 
            onToggle={show => setShow(show)}
            onClick={onRootClick}
            {...props}
        >
            <RootDropdown.Toggle 
                childBsPrefix="btn-custom"
                bsPrefix="btn btn-clean"
                className={toggleClass}
                disabled={disabled}
            >
                { toggle }
            </RootDropdown.Toggle>
            <RootDropdown.Menu onClick={onClick} className="dropdown-menu-anim">
                { children }
            </RootDropdown.Menu>			
	    </RootDropdown>
    )
}

export const dispatchClick = () => document.dispatchEvent(new MouseEvent('click'));

export const Drop = memo(function({
    toggleProps,
    menuProps,
    toggle,
    children,
    menuItems,
    ...props
}) {
    return (
        <RootDropdown {...props}>
            <RootDropdown.Toggle 
                as={CustomToggle}
                {...toggleProps}
            >
                {toggle}
            </RootDropdown.Toggle>
            <RootDropdown.Menu {...menuProps} 
                className={classnames("dropdown-menu-anim", menuProps ? menuProps.className : null)}
            >
                {
                    menuItems
                    ? <NavMenu data={menuItems}/>
                    : null
                }
                { children }
            </RootDropdown.Menu>
        </RootDropdown>
    )
})

export default memo(Dropdown);