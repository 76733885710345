import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Portlet from '../../components/Portlet';
import IntentEditorWrap from './IntentEditorWrap';
import UtterancesList from './UtterancesList';
import Toolbar from './IntentContentToolbar';
import { prefix } from '../../components/IntentName';
import IntentName from './IntentName';

function IntentContent({noCurrent}) {
    if (noCurrent) {
        return null;
    }
    
    return (
        <Portlet
            className="title-fluid body-scroll"
            title={<IntentName prefix={prefix}/>}
            toolbar={<Toolbar/>}
        >
            <IntentEditorWrap/>
            <UtterancesList/>
        </Portlet>
    )
}

IntentContent.propTypes = {
    noCurrent: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        noCurrent: state.currentIntent ? false : true
    }),
    {
    }
)(IntentContent);