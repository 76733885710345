import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import NavMenu from '../../components/Dropdown/NavMenu';
import {
    addChildNode,
    addNodeAbove,
    addNodeBelow,
    triggerMoveNode,
    deleteNode,
    copyNodeTree,
    pasteToNode
} from '../../actions/node';
import { 
    faStream,
    faSortSizeDownAlt,
    faSortSizeUp,
    faTrash,
    faArrows,
    faPaste,
    faCopy
} from '@fortawesome/pro-duotone-svg-icons';

function El({
    id,
    addChildNode,
    addNodeAbove,
    addNodeBelow,
    moveNode,
    deleteNode,
    cantPaste,
    copyNodeTree,
    pasteToNode
}) {
    return <NavMenu
        style={{minWidth: '200px'}}
        data={[{
            text: 'Add child node',
            onClick: () => addChildNode(id),
            icon: faStream
        },{
            text: 'Add node above',
            onClick: () => addNodeAbove(id),
            icon: faSortSizeUp
        },{
            text: 'Add node below',
            onClick: () => addNodeBelow(id),
            icon: faSortSizeDownAlt
        },{
            text: 'Copy',
            onClick: () => copyNodeTree(id),
            icon: faCopy
        },{
            text: 'Paste',
            disabled: cantPaste,
            onClick: () => pasteToNode(id),
            icon: faPaste 
        },{
            text: 'Move',
            onClick: () => moveNode(id),
            icon: faArrows
        },{
            text: 'Delete',
            onClick: () => deleteNode(id),
            icon: faTrash
        }]}
    />
}

El.propTypes = {
    id: PropTypes.string.isRequired,
    addChildNode: PropTypes.func.isRequired,
    addNodeAbove: PropTypes.func.isRequired,
    addNodeBelow: PropTypes.func.isRequired,
    moveNode: PropTypes.func.isRequired,
    deleteNode: PropTypes.func.isRequired
};

export default connect(
    state => ({
        cantPaste: state.skill.copyTree ? false : true
    }),
    {
        addChildNode,
        addNodeAbove,
        addNodeBelow,
        moveNode: triggerMoveNode,
        deleteNode,
        copyNodeTree,
        pasteToNode
    }
)(El);