import { 
    useEffect, useCallback,
    useState, useMemo
} from 'react';

export default function({
    store,
    children
}) {
    const [n, _up] = useState(0);
    const update = useCallback(() => _up(n => n+1), []);
    const childrenProps = useMemo(() => ({
        getEditorState: store.getItem('getEditorState'),
        setEditorState: store.getItem('setEditorState')
    // eslint-disable-next-line
    }), [n]);

    useEffect(() => {
        store.subscribeToItem('selection', update);
        return () => store.unsubscribeFromItem('selection', update);
    }, [update, store]);
  
    if (children) {
        return children(childrenProps);
    }
    return null;
}