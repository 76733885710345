import React, { memo } from 'react';
import SkillsPage from '../SkillsPage';
import SkillPage from '../SkillPage';
import IntentsPage from '../IntentsPage';
import EntitiesPage from '../EntitiesPage';
import PageNotFound from '../PageNotFound';
import MyIntegrationsPage from '../MyIntegrationsPage';
import ActionsPage from '../ActionsPage';
import IntegrationPage from '../IntegrationPage';
import ActionPage from '../ActionPage';
import UserListPage from '../UserListPage';
import UserPage from '../UserPage';
import Analytics from '../Analytics';
import IntegrationsPage from '../IntegrationsPage';
// import SkillsStore from '../SkillsStore';
import { 
    Switch, 
    Route,
    Redirect
} from 'react-router-dom';

function MainRoutes() {
    return (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Redirect exact from="/sign-up" to="/" />
            <Route exact path="/skills" component={ SkillsPage } />
            <Route path="/skills/:skillId" component={SkillPage} />
            <Route exact path="/intents" component={IntentsPage} />
            <Route exact path="/entities" component={EntitiesPage} />
            <Route exact path="/integrations" component={MyIntegrationsPage} />
            <Route exact path="/integrations/new" component={IntegrationsPage} />
            <Route path="/integrations/:name/:id?">
                <IntegrationPage/>
            </Route>
            <Route exact path="/actions" component={ActionsPage} />
            <Route path="/actions/:id" component={ActionPage} />
            {/* <Route exact path="/skills-store" component={SkillsStore} /> */}
            <Route exact path="/users" component={UserListPage} />
            <Route exact path="/users/:id" component={UserPage} />
            <Route path="/dashboard" component={Analytics} />
            <Route exact path="/404" component={ PageNotFound } />
            <Redirect to="/404"/>        
        </Switch>
    )
}

export default memo(MainRoutes);