import React, { memo, useRef, useCallback } from 'react';
import { Formik, FastField } from 'formik';
import FInput from './formik/Input';
import FSelect from './formik/Select';
import { required } from './validators';
import { useFormRef, useFormValues } from './formik/hooks';
import Wrap from './components/FormWrap';
import AuthForm from './Rest/AuthForm';

const authTypes = [{
    value: 'noAuth',
    label: 'No Auth'
},{
    value: 'basic',
    label: 'Basic Auth'
}];

const initial = [{
    key: 'name',
    val: '',
    validate: required('Name')
}, {
    key: 'type',
    val: 'ibm-sg'
}, {
    key: 'apiKey',
    val: '',
    validate: required('Secure Gateway Token')
}, {
    key: 'authType',
    val: authTypes[0].value
}];

function IBMSecureGatewayForm({ onSubmit, forwardedRef, values }) {
    const authRef = useRef();
    const ref = useFormRef(forwardedRef, [authRef]);
    const {vals, autoFocus, validateCb} = useFormValues(values, initial);
    const _onSubmit = useCallback(() => {
        if (onSubmit) onSubmit(forwardedRef.current.getValues());
    }, [onSubmit, forwardedRef]);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmit}
        >
            {({values: {authType}}) => (
                <Wrap>
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Integration Name"
                        description="Specify integration name"
                        component={FInput} 
                    />
                    <FastField 
                        name="apiKey" 
                        label="Secure Gateway Token"
                        description="Secure Gateway Token provided by Hala"
                        type="password"
                        component={FInput}
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="authType" 
                        component={FSelect} 
                        label="Authorization"
                        description="Select authorization type"
                        options={authTypes}
                        convertToValues
                    />
                    <AuthForm 
                        forwardedRef={authRef} 
                        type={authType}
                        values={values}
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(IBMSecureGatewayForm);