import Auth from '@aws-amplify/auth';

let config;
if (process.env.NODE_ENV === 'development') {
    config = require(`./config.${process.env.NODE_ENV}.js`).default
} else {
    config = {};
}

const awsmobile = {
    Auth: {
        identityPoolId: 'us-east-1:1a2b132e-14c0-44e4-a317-d256b2f5b9eb',
        region: 'us-east-1',
        userPoolId: 'us-east-1_cKkQOpkAQ',
        userPoolWebClientId: '6hv4pvroeqck1u4okke6f30ksq',
        mandatorySignIn: false
    },
    API: {
        endpoints: [
            {
                name: "api",
                endpoint: (() => {
                    if (process.env.NODE_ENV === 'production') {
                        return "https://wl.hala.ai";
                    }
                    return "http://localhost:9000";
                })(),
                "custom_header": async () => { 
                    return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
                }
            }
        ]
    },
    'aws_user_files_s3_bucket': 'hala-us-data',
    'aws_user_files_s3_bucket_region': 'us-west-1',
    chatUrl: "https://app.hala.ai",
    ...config
};

export default awsmobile;