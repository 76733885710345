import React, { memo } from 'react';
import moment from 'moment';
import { faSync } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.sass';

function LastUpdated({
    date, onRefresh,
    loading
}) {
    return (
        <span className="last-updated">
            <span>Last updated: </span>
            <span className="date">{ moment(date || Date.now()).format("D MMM YYYY, H:mm:ss") }</span>
            <button onClick={onRefresh} type="button" className="btn btn-clean btn-bold btn-upper">
                <FontAwesomeIcon icon={faSync} spin={loading}/> Refresh
            </button>
        </span>
    )
}

export default memo(LastUpdated);