import {
    typeDeleteIntent,
    typeDeleteEntity
} from '../constants';

export default function(state = null, { type, payload }) {
    switch (type) {
        case typeDeleteEntity:
        case typeDeleteIntent: {
            const isEntities = type === typeDeleteEntity;
            const stCurrKey = isEntities ? 'currentEntity' : 'currentIntent';
            const stKey = isEntities ? 'entities' : 'intents';
            const ids = Object.keys(state[stKey]);
            if (!state[stCurrKey] && ids.length) {
                return {
                    ...state,
                    [stCurrKey]: ids[0]
                };
            }
            return state;
        }
        default:
            return state;
    }
}