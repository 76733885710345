import React, { memo } from 'react';
import SkillPublish from '../containers/SkillPublish';
import { useParams } from 'react-router-dom';

function SkillPublishPage() {
    const { skillId } = useParams();

    return (
        <>
            <SkillPublish  skillId={skillId}/>
        </>
    )
}

export default memo(SkillPublishPage);