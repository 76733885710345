import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../../components/Icon';
import { ReactComponent as DoubleCheck } from '../../icons/DoubleCheck.svg';
import './index.sass';

const log = Logger('EditableString');

function EditableString({
    text, id, onEdit, prefixIcon, prefix, editIfNoText = true,
    placeholder
}) {
    log.debug('render');
    const [newText, setNewText] = useState(text);
    const [edit, setEdit] = useState(false);
    const [blur, setBlur] = useState(null);
    const activateEdit = useCallback(() => setEdit(true),[]);
    const onChange = useCallback(e => setNewText(e.target.value), []);
    const onBlur = useCallback(() => {
        setBlur(setTimeout(() => setEdit(false), 10));
    }, []);
    const save = useCallback(() => {
        onEdit(newText, id);
        setEdit(false);
    }, [newText, onEdit, id]);
    const handleKeyDown = useCallback(e => {
        if (e.key === 'Enter') {
            onChange(e);
            save();
        }
    }, [onChange, save]);

    const _prefix = useMemo(() => {
        if (prefixIcon)
            return <FontAwesomeIcon icon={prefixIcon}/>;
        if (prefix)
            return prefix;
        return null;
    }, [prefixIcon, prefix]);

    const showEdit = useMemo(() => (
        <div className="editable-string-edit input-group input-group-sm kt-input-icon kt-input-icon--right">
            {
                _prefix
                ?<div className="input-group-prepend">
                    <span className="input-group-text">
                        { _prefix }
                    </span>
                </div>
                : null
            }
            <input 
                onKeyDown={handleKeyDown}
                autoFocus 
                onBlur={onBlur}
                onChange={onChange} 
                value={newText} 
                type="text" 
                placeholder={placeholder}
                className="form-control" 
            />
            <span className="kt-input-icon__icon kt-input-icon__icon--right">
			    <Icon onClick={save}><DoubleCheck/></Icon>
			</span>
		</div>
    ),[newText, onChange, save, handleKeyDown, onBlur, _prefix, placeholder]);

    const showName = useMemo(() => (
        <span onClick={activateEdit} className="editable-string">
            { _prefix }
            { text }
            <FontAwesomeIcon icon={faPencil}/>
        </span>
    ), [text, activateEdit, _prefix]);

    useEffect(() => {
        setEdit(false);
    }, [id]);

    useEffect(() => {
        if (editIfNoText && !text) setEdit(true);
    }, [editIfNoText, text])

    useEffect(() => {
        return () => {
            if (blur) clearTimeout(blur);
        } 
    }, [blur]);

    useEffect(() => {
        if (!edit && newText !== text) {
            setNewText(text);
        }
    }, [edit, newText, text, id]);

    return edit ? showEdit : showName;
}

EditableString.propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired
};

export default memo(EditableString);