import React from 'react';
import { connect } from 'react-redux';
import { 
    getNodeById, ROOT
} from '../../reducers/nodes';
import DetailedName from '../../components/DetailedName';
import { 
    faChevronDoubleDown, 
    faChevronRight
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const rootImage = <FontAwesomeIcon size="lg" icon={faChevronDoubleDown}/>;
const image = <FontAwesomeIcon size="lg" icon={faChevronRight}/>;

export const nameConnector = connect(
    (state, props) => {
        const {id, title, conditions} = getNodeById(state, props) || {};
        if (id === ROOT) return {
            name: ROOT.toUpperCase(),
            description: 'root node',
            image: rootImage
        };
        return {
            name: title,
            description: conditions,
            image
        };
    },
    {}
);

export default nameConnector(DetailedName);

export const NodeName = nameConnector(({name, icon}) => (
    <React.Fragment>
        {
            icon
            ? <FontAwesomeIcon icon={icon}/>
            : null
        }
        { name }
    </React.Fragment>
));