import React, { useState, useCallback, useEffect, useRef } from 'react';
import Logger from '../../utils/logger';
import { Editor, convertToRaw, CompositeDecorator, EditorState } from 'draft-js';
import {
    addEntityOnSelection,
    createStrategy,
    createEmptyState,
    removeEntities,
    getEntityAtSelection,
    clearState,
    moveSelectionToEnd,
    clear
} from '../../components/Editor/utils';
import Entity from './Entity';
import { saveUtterance, setActiveUtterance } from '../../actions/intent'; 
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { ReactComponent as Close } from '../../icons/Close.svg';
import { ReactComponent as DoubleCheck } from '../../icons/DoubleCheck.svg';
import { getCurrentUtterance } from '../../reducers/intents';
import Icon from '../../components/Icon';
import './IntentEditor.sass';

const log = Logger('IntentEditor');
const entType = 'ENTITY';
const strategy = createStrategy(entType);

function entitiesCondition(ent) {
    return ent.type === entType && ent.data.entity === null;
}

function createDecorations(props = {}) {
    return new CompositeDecorator([{
        strategy,
        component: Entity,
        props
    }]);
}

function applayDecorations(editorState, data = {}) {
    const decorator = createDecorations(data);
    return EditorState.set(editorState, {decorator});
}

function IntentEditor({
    saveUtterance,
    setActive,
    data,
    intentId,
    currentUtterance
}) {
    log.debug('render');

    const editor = useRef();
    const [disable, setDisable] = useState(false);
    const [close, setClose] = useState(false);
    const [state, setState] = useState(
        createEmptyState(data, createDecorations())
    );

    useEffect(() => {
        setState(state => {
            return clear(state);
        });
    }, [intentId]);

    const onClose = useCallback(() => setClose(true), []);

    const __onChange = useCallback(newState => {
        let selection = newState.getSelection();
        if (!selection.isCollapsed()) {
            try {
                newState = addEntityOnSelection(newState, entType);
                setDisable(true);
                newState = applayDecorations(newState, {
                    onClose,
                    current: newState.getCurrentContent().getLastCreatedEntityKey()
                });
                newState = moveSelectionToEnd(newState);
            } catch(err) {
            }
        } else {
            const { entityKey } = getEntityAtSelection(newState);
            if (entityKey) {
                setDisable(true);
                newState = moveSelectionToEnd(newState);
            }
            newState = applayDecorations(newState, {
                // onClose: entityKey ? onClose : null,
                current: entityKey || null
            });
        }
        return newState;
    }, [onClose]);

    const onChange = useCallback(state => {
        setState(__onChange(state));
    }, [__onChange]);

    useEffect(() => {
        if (close) {
            setClose(false);
            let newState = removeEntities(state, entitiesCondition);
            onChange(moveSelectionToEnd(newState));
            setDisable(false);
        }
    }, [close, state, onChange]);

    useEffect(() => {
        if (!close) editor.current.focus();
    }, [close]);

    const save = useCallback(() => {
        let newState = __onChange(state);
        const raw = convertToRaw(newState.getCurrentContent());
        if (!raw.blocks[0].text) return false;
        saveUtterance(raw, intentId, currentUtterance);
        onChange(clearState(newState));
        if (disable) setDisable(false);
    }, [saveUtterance, state, __onChange, onChange, disable, intentId, currentUtterance]);

    const reqClose = useCallback(() => {
        if (data) setActive(null);
        onChange(clearState(state));
        if (disable) setDisable(false);
    }, [onChange, state, disable, data, setActive]);

    const handleKeyCommand = useCallback(e => {
        if (e === 'split-block') {
            save();
            return 'handled';  
        }
        return 'not-handled';
    }, [save]);

    return (
        <div className="kt-section intent-editor">
            <div className="kt-section__content kt-section__content--solid">
                <Editor 
                    ref={editor}
                    readOnly={disable}
                    editorState={state} 
                    onChange={onChange}
                    handleKeyCommand={handleKeyCommand}
                />
                <div className="actions">
                    <Icon onClick={save}><DoubleCheck/></Icon>
                    <Icon onClick={reqClose}><Close/></Icon>
                </div>
            </div>         
        </div> 
    )
}

IntentEditor.propTypes = {
    saveUtterance: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    intentId: PropTypes.string.isRequired,
};

export default connect(
    state => {
        const item = getCurrentUtterance(state);
        return {
            data: item ? item.data : null,
            intentId: state.currentIntent,
            currentUtterance: state.currentUtterance
        };
    },
    {
        saveUtterance,
        setActive: setActiveUtterance
    }
)(IntentEditor);