import React, { 
    memo, useRef, useEffect, useState, useMemo,
    useLayoutEffect, useCallback
} from 'react';
import Logger from '../../utils/logger';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './index.sass';

const log = Logger('SchemaEditor');

function SchemaEditor({
    value,
    onChange,
    onChangeKey,
    mode,
    expand,
    label
}) {
    log.debug('render');
    const ref = useRef();
    const [editor, setEditor] = useState();
    const _onChange = useCallback(val => {
        onChange(onChangeKey ? {
            [onChangeKey]: val
        } : val);
    }, [onChange, onChangeKey]); 

    useEffect(() => {
        let newEditor = new JSONEditor(ref.current, {
            search: false,
            mainMenuBar: false,
            statusBar: false,
            navigationBar: false,
            enableSort: false,
            enableTransform: false,
            onChangeJSON: _onChange
        });
        setEditor(newEditor);
        return () => newEditor.destroy();
    }, [_onChange]);

    useLayoutEffect(() => {
        if (!editor) return;
        if (value) editor.update(value);
        if (mode) editor.setMode(mode);
        if (label) editor.setName(label);
    },[editor, value, mode, label]);

    useLayoutEffect(() => {
        if (!editor) return;
        if (expand) editor.expandAll();
    },[expand, editor]);

    return useMemo(() => (
        <div className="schema-editor" ref={ref}></div>
    ), []);
}

export default memo(SchemaEditor);