import {
    typeCreateIntegrationSuccess,
    fetchIntegrationsSuccess,
    typeDeleteIntegrationSuccess,
    typeUpdateIntegrationSuccess
} from '../constants';
import { createSelector } from 'reselect';
import { getAvailableIntegrationsSelector, getIntegrationByKey } from './availableIntegrations';

export default function(state = [], { type, payload }) {
    switch (type) {
        case fetchIntegrationsSuccess:
            return [
                ...payload.data
            ];
        case typeCreateIntegrationSuccess:
            return [
                payload.data,
                ...state,
            ];
        case typeDeleteIntegrationSuccess:
            return state.filter(el => el.id !== payload.id);
        case typeUpdateIntegrationSuccess:
            return state.map(el => {
                if (el.id === payload.data.id) {
                    return {
                        ...payload.data
                    };
                }
                return el;
            });
        default:
            return state;
    }
}


export const getIdFromProps = (_, props) => props.id;
export const getIntegration = (items, id) => items.find(el => el.id === id) || null;

export const getIntegrationsSelector = state => state.integrations;
export const getIntegrationSelector = createSelector(
    [getIntegrationsSelector, getIdFromProps], getIntegration
);

export const getMergedIntegrationsSelector = createSelector(
    [getIntegrationsSelector, getAvailableIntegrationsSelector],
    (ints, avInts) => ints.map(el => {
        el.integration = getIntegrationByKey(avInts, el.type);
        return el;
    })
);

export const getIntegrationsDetailedName = item => {
    if (!item) return null;
    return {
        name: item.name,
        image: null
    };
};