import React, { memo, useMemo, useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import Portlet from '../../components/Portlet';
import PageContent from '../PageContent';
import SubHeader from '../../components/SubHeader';
import Logger from '../../utils/logger';
import { Link } from 'react-router-dom';

const log = Logger('SkillsPage');

function PageItemsGrid({ 
    data,  
    title,
    item,
    actionBrandTo,
    actionBrandTitle,
    onFilter
}) {
    log.debug('render');
    const [filter, setFilter] = useState('');
    const onSearch = useCallback((e) => {
        setFilter(e.target.value || null);
    }, []);

    const _data = useMemo(() => {
        if (!filter) return data;
        return data.filter(el => onFilter(el, filter.toLowerCase().trim()));
    }, [filter, data, onFilter]);

    const length = _data.length;

    const subheaderToolbar = useMemo(() => (
        <Link className="btn btn-label-brand btn-bold" to={actionBrandTo}>{actionBrandTitle}</Link>
    ),[actionBrandTo, actionBrandTitle]);

    const subheader = useMemo(() => {
        return <SubHeader
            title={title}
            onSearch={onSearch}
            desc={`${length} Total`}
            toolbar={subheaderToolbar}
        />;
    }, [length, onSearch, title, subheaderToolbar]);

    return (
        <PageContent 
            subheader={subheader}
        >
            <div className="row">
                { 
                    _data.map((el,key) => (
                        <div key={key} className="col-lg-6 col-xl-4">
                            <Portlet
                                heightFluid={true}
                                bodyFit={true}
                            >
                                { item(el) }
                            </Portlet>
                        </div>
                    ))
                }     
            </div>
        </PageContent>
    );
}

PageItemsGrid.propTypes = {
    data: PropTypes.array.isRequired
};
    
export default memo(PageItemsGrid);