import produce from "immer";
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
    nodeFormItemChange,
    nodeFormActionSelect,
    nodeFormDeleteAction,
    nodeFormSetActionParameters,
    nodeFormAddAction,
    nodeFormActionResponse,
    nodeFormContextChange,
    typeActiveNode,
    nodeFormOutputChange,
    nodeFormUpdateBehavior
} from '../../constants';
import {
    actionParameters
} from '../actions';

const transformContext = d => {
    if (!d) return [];
    let _d = [];
    Object.keys(d).forEach(key => {
        _d.push({
            key,
            value: d[key]
        });
    });
    return _d;
};

export default function(state = {}, { type, payload }) {
    return produce(state, draft => {
        switch (type) {
            case typeActiveNode: 
                if (payload) {
                    return {
                        title: payload.title,
                        conditions: payload.conditions,
                        actions: payload.actions,
                        context: transformContext(payload.context),
                        output: payload.output,
                        skipUserInput: payload.skipUserInput,
                        jumpTo: payload.jumpTo
                    };
                }
                return {};
            case nodeFormUpdateBehavior:
                if (typeof payload.jumpTo !== 'undefined') draft.jumpTo = payload.jumpTo;
                if (typeof payload.skipUserInput !== 'undefined') {
                    draft.skipUserInput = payload.skipUserInput;
                    draft.__setSkipUserInput = true;
                    draft.jumpTo = null;
                }
                break;
            case nodeFormItemChange:
                draft[payload.name] = payload.value;
                break;
            case nodeFormActionResponse: 
                draft.actions[payload.confId].result_variable = payload.value;
                break;
            case nodeFormActionSelect:
                if (payload.action) {
                    draft.actions[payload.confId] = getActionData(payload.action);
                } else {
                    draft.actions.splice(payload.confId,1);
                }
                break;
            case nodeFormAddAction:
                if (!draft.actions) draft.actions = [];
                if (!draft.actions.length && !draft.__setSkipUserInput) {
                    draft.skipUserInput = true;
                }
                draft.actions.push(payload ? getActionData(payload) : {});
                break;
            case nodeFormDeleteAction:
                draft.actions.splice(payload,1);
                break;
            case nodeFormSetActionParameters:
                draft.actions[payload.confId].parameters = payload.params;
                break;
            case nodeFormContextChange:
                draft.context = payload;
                break;
            case nodeFormOutputChange:
                draft.output = payload;
                break;
            default:
                return draft;
        }
    });
}

export const getActionData = action => ({
    id: action.id,
    type: action.type,
    parameters: actionParameters(action)
});

export const formSelector = createSelector(
    state => state.forms.skillNode,
    form => form
);

export const formActionsSelector = createSelector(
    [ formSelector ],
    form => form.actions || []
);

export const formBehaviorSelector = createSelector(
    [ formSelector ],
    form => ({
        skipUserInput: form.skipUserInput,
        jumpTo: form.jumpTo
    })
);

export const actionsLengthSelector = createSelector(
    [ formActionsSelector ],
    actions => actions.length
);

export const confIdFromProps = (_, props) => props.confId;

export const actionSelector = createSelector(
    formActionsSelector,
    confIdFromProps,
    (actions, confId) => actions[confId]
);

export const actionParamsSelector = createSelector(
    actionSelector,
    action => action.parameters
);

export const formContextSelector = createSelector(
    formSelector,
    form => form.context || []
);

export const formOutputSelector = createSelector(
    formSelector,
    form => form.output
);

export const actionResSelector = createSelector(
    actionSelector,
    action => action.result_variable
);

export const actionIdByConfId = createSelector(
    formActionsSelector,
    confIdFromProps,
    (actions, confId) => _get(actions, `[${confId}].id`, null)
);

const onChange = (dispatch, type, name) => e => {
    dispatch({
        type,
        payload: {
            name,
            value: e.target ? e.target.value : e
        }
    }); 
};

export function skillNodeFormConnector() {
    return connect(
        (state, props) => {
            const v = formSelector(state);
            return {
                value: v[props.name] || ''
            };
        },
        (dispatch, props) => ({
            onChange: onChange(dispatch, nodeFormItemChange, props.name)
        })
    );
}