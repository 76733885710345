import React, { memo } from 'react';
import ServicenowForm from './ServicenowForm';
import SapForm from './SapForm';
import AWSForm from './AWSForm';
import IBMWatson from './IBMWatson';

function AuthForm({
    type,
    ...props
}) {
    let Comp = null;
    switch(type) {
        case 'rest-api':
        case 'servicenow':
            Comp = ServicenowForm;
            break;
        case 'sap':
        case 'ibm-sg':
            Comp = SapForm;
            break;
        case 'aws':
            Comp = AWSForm;
            break;
        case 'ibm-watson':
            Comp = IBMWatson;
            break;
        default:
            return null;
    }

    return (
        <Comp 
            nested 
            {...props}
        />
    )
};

export default memo(AuthForm);