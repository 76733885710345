import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import SubHeader from '../../components/SubHeader';
import { newIntent } from '../../actions/intent';
import { Button } from 'react-bootstrap';
import TrainingDataStatus from '../TrainingDataStatus';

const log = Logger('IntentsSubHeader');

function IntentsSubHeader({newIntent}) {
    log.debug('render');
    return <SubHeader
        title="Intents"
        toolbar={<TrainingDataStatus/>}
        leftToolbar={
            <Button onClick={newIntent} variant="btn" className="btn-label-success btn-bold">
                New Utterance
            </Button>
        }
    />
}

IntentsSubHeader.propTypes = {
    newIntent: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        newIntent
    }
)(IntentsSubHeader);