import {
    fetchAnalytics, fetchAnalyticsSuccess, fetchAnalyticsError,
    fetchSkillsAnalytics, fetchSkillsAnalyticsSuccess, fetchSkillsAnalyticsError,
    fetchUsersAnalytics, fetchUsersAnalyticsSuccess, fetchUsersAnalyticsError
} from '../../constants';

export default function (state = false, {type}){
    switch (type) {
        case fetchAnalytics:
        case fetchUsersAnalytics:
        case fetchSkillsAnalytics:
            return true;
        case fetchAnalyticsSuccess:
        case fetchAnalyticsError:
        case fetchSkillsAnalyticsSuccess:
        case fetchSkillsAnalyticsError:
        case fetchUsersAnalyticsSuccess:
        case fetchUsersAnalyticsError:
            return false;
        default:
            return state;
    }
}