import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateIntentName } from '../../actions/intent';
import EditableString from '../../components/EditableString';

function IntentName({id, updateIntentName, ...props}) {
    const onEdit = useCallback(name => updateIntentName(name, id), [id, updateIntentName]);
    return <EditableString id={id} onEdit={onEdit} {...props}/>
}

export default connect(
    state => ({
        id: state.currentIntent,
        text: state.intents[state.currentIntent].name
    }),
    {
        updateIntentName
    }
)(IntentName);