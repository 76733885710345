import React from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../components/SubHeader';
import SubHeaderActions from '../../components/SubHeader/Actions';
import { filterUsers } from '../../actions/users';

const toolbar = <SubHeaderActions 
    submit="/users/new"
    submitText="Add User"
/>;

const onSearch = e => filterUsers(e.target.value);

export default connect(
    state => ({
        title: 'Users',
        desc: `${state.users.total} Total`,
        toolbar: toolbar,
        searchValue: state.users.filter
    }),
    {
        onSearch: onSearch
    }
)(SubHeader);