import React, { memo } from 'react';
import { Formik, FastField } from 'formik';
import FInput from './formik/Input';
import LongText from './formik/LongText';
import TagsInput from './formik/TagsInput';
import Checkbox from './formik/Checkbox';
import Wrap from './components/FormWrap';
import { required } from './validators';
import { useFormRef, useFormValues } from './formik/hooks';
import Joi from '@hapi/joi';

const initial = [{
    key: 'name',
    val: '',
    validate: required('Name')
}, {
    key: 'description',
    val: ''
}, {
    key: 'tags',
    val: []
}, {
    key: 'isDefaultForClients',
    val: false,
    validate: Joi.bool(),
    dynamic: true
}];

function SkillForm({ 
    onSubmit, forwardedRef, 
    values, showDynamic
}) {
    
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus, validateCb} = useFormValues(values, initial, showDynamic);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {({values: {
                isDefaultForClients
            }}) => (
                <Wrap>
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Name *"
                        description="Skill name"
                        component={FInput} 
                    />
                    <FastField 
                        name="description" 
                        component={LongText} 
                        label="Desctiption"
                        description="Skill description"
                        rows="5"
                    />
                    <FastField 
                        name="tags" 
                        component={TagsInput} 
                        label="Tags"
                        addOnBlur
                    />
                    {
                        typeof isDefaultForClients !== 'undefined'
                        ? <FastField 
                            name="isDefaultForClients" 
                            component={Checkbox} 
                            chLabel="Use this skill as default on new client initialization."
                            type={['bold', 'success']}
                        />
                        : null
                    }
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(SkillForm);