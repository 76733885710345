import React from 'react';
import { SignUp } from 'aws-amplify-react';
import { I18n } from '@aws-amplify/core';
import Joi from '@hapi/joi';
import { required, jIsEmail, jError } from '../Forms/validators';
import classnames from 'classnames';
import Auth from '@aws-amplify/auth';
import Checkbox from '../Checkbox';
import Analytics from '@aws-amplify/analytics';
import { updateEndpoint } from '../../utils/analytics';

const signUpSchema = Joi.object({
    username: Joi.string().required().custom(jIsEmail).error(jError({
        'string.empty': 'Email is required',
        'any.required': 'Email is required'
    })),
    password: required('Password'),
    firstName: required('First name'),
    lastName: required('Last name'),
    terms_and_conditions: Joi.any(),
    newsletters: Joi.any()
});

const signUpFields = [{
    label: 'Email',
    key: 'username',
    required: true,
    type: 'text',
    placeholder: 'Email',
    autoFocus: true
},{
    label: 'Password',
    key: 'password',
    required: true,
    type: 'password',
    placeholder: 'Password'
},{
    label: 'First name',
    key: 'firstName',
    required: true,
    type: 'text',
    placeholder: 'First name'
},{
    label: 'Last name',
    key: 'lastName',
    required: true,
    type: 'text',
    placeholder: 'Last name'
}];

class MySignUp extends SignUp {

    constructor(props) {
        super(props);
        this.state = { errors: {} };
    }

    signUp() {
        const {
            username, password, firstName, lastName,
            terms_and_conditions, newsletters
        } = this.inputs;

        Analytics.record({
            name: 'signUpClick',
            attributes: { username }
        });

        const authData = {
            username, password, firstName, lastName,
            terms_and_conditions, newsletters
        };
        const {error} = signUpSchema.validate(authData, { 
            abortEarly: false 
        });
        let errors = {};
        if (error) {
            error.details.forEach(({message, path}) => {
                errors[path[0]] = message;
            });
        }
        if (terms_and_conditions !== true) {
            errors.terms_and_conditions = 'Please agree to all the terms and conditions before Sing Up';
        }
        this.setState({errors});

        if (!error && terms_and_conditions === true) {
            Auth.signUp({
                username,
                password,
                attributes: {}
            })
            .then(d => {
                updateEndpoint({
                    email: username,
                    sub: d.userSub
                });
                Analytics.record({
                    name: 'signUpSuccess',
                    attributes: {
                        sub: d.userSub
                    }
                });
                this.changeState('confirmSignUp', authData);
            })
            .catch(err => {
                Analytics.record({
                    name: 'signUpError',
                    attributes: {
                        error: err.message
                    }
                });
                this.error(err);
            });
        } else {
            Analytics.record({
                name: 'signUpClientErrors',
                attributes: errors
            });
        }
    }
    
    showComponent() {
        const {errors} = this.state;
        return (
            <div className="kt-login__signin">
                <div className="kt-login__head">
                    <div className="kt-login__title">
                        {I18n.get(this.header)}
                    </div>
                </div>
                <form className="kt-form">
                    {signUpFields.map(field => (
                        <div 
                            key={field.key}
                        >
                            <input
                                className={classnames(
                                    "form-control", {
                                        'is-invalid': errors[field.key]
                                    })
                                }
                                autoFocus={field.autoFocus}
                                placeholder={I18n.get(field.placeholder)}
                                type={field.type}
                                name={field.key}
                                required={field.required}
                                onChange={this.handleInputChange}
                            />
                            {
                                errors[field.key]
                                ? <div className="invalid-feedback">{errors[field.key]}</div>
                                : null
                            }
					    </div>
                    ))}
                    <div className="kt-margin-t-30">
                        <Checkbox 
                            onChange={this.handleInputChange}
                            name="terms_and_conditions"
                            className={classnames({
                                'is-invalid': errors.terms_and_conditions
                            })}
                            type={['tick', 'brand']}
                            label={
                                <React.Fragment>
                                    <div>
                                        <span>I agree with Hala's </span>
                                        <a
                                            href="https://www.hala.ai/terms-and-conditions"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            terms and conditions.
                                        </a>
                                    </div>
                                </React.Fragment>
                            }
                        />
                        {
                            errors.terms_and_conditions
                            ? <div className="invalid-feedback">{errors.terms_and_conditions}</div>
                            : null
                        }
                    </div>
                    <div className="kt-margin-t-10">
                        <Checkbox 
                            onChange={this.handleInputChange}
                            name="newsletters"
                            type={['tick', 'brand']}
                            label="I'd like to receive occasional insights, Skill store updates, offers and product updates from Hala."
                        />
                    </div>
					<div className="kt-login__actions">
                        <button 
                            onClick={this.signUp}
                            type="button"
                            className="btn btn-brand btn-elevate kt-login__btn-primary"
                        >
                            {I18n.get('Create Account')}
                        </button>
					</div>
				</form>
                <div className="kt-login__account">
                    <span className="kt-login__account-msg">
                        {I18n.get('Have an account? ')}
                    </span>
                    &nbsp;&nbsp;	
                    <span className="kt-login__account-link"
                        onClick={() => this.changeState('signIn')}
                    >
                        {I18n.get('Sign in')}
                    </span>
                </div>
            </div>
        )
	}
}

export default MySignUp;