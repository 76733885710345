import React, { memo } from 'react';
import ActionButton from '../ActionButton';
import { useHistory } from 'react-router-dom';

function SubHeaderActions({
    loading,
    back,
    submit,
    submitText,
    backText
}) {
    const { goBack } = useHistory();
    back = back || goBack;
    return (
        <React.Fragment>
            {
                back
                ? <ActionButton action={back} className="btn-default btn-bold">{backText || 'Back'}</ActionButton>
                : null
            }
            {
                submit
                ? <ActionButton 
                    loading={loading} 
                    action={submit} 
                    className="btn-label-brand btn-bold"
                >{submitText || 'Save Changes'}</ActionButton>
                : null
            }
        </React.Fragment>
    )
}

export default memo(SubHeaderActions);