import React, { memo } from 'react';
import Logger from '../../utils/logger';
import SkillHeaderMenu from '../SkillPage/HeaderMenu';
import { 
    Switch, 
    Route
} from 'react-router-dom';
import IntegrationsRoot from './Integrations';
import AnalyticsHeaderMenu from '../Analytics/HeaderMenu';

const log = Logger('HeaderRoutes');

function HeaderRoutes() {
    log.debug('render');
    return (
        <Switch>
            <Route path="/skills/:skillId" component={({match}) => {
                return <SkillHeaderMenu skillId={match.params.skillId}/>;
            }}/>
            <Route path="/integrations" component={IntegrationsRoot}/>
            <Route path="/actions" component={IntegrationsRoot}/>
            <Route path="/dashboard" component={AnalyticsHeaderMenu}/>
        </Switch>
    )
}

export default memo(HeaderRoutes);