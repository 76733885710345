import { connect } from 'react-redux';
import UserForm from '../../components/Forms/User';
import { isNew, selectUserById } from '../../reducers/users';
import { createUser, updateUser } from '../../actions/users';
import { success } from '../../actions/notification';
import { withRouter } from 'react-router-dom';

export default withRouter(connect(
    (state, {syncRef, ...props}) => ({
        forwardedRef: syncRef,
        values: selectUserById(state, props) || null
    }),
    (dispatch, {id, history}) => {
        const onSuccess = ({data}) => {
            const name = `${data.firstName} ${data.lastName}`;
            let msg = `User ${name} was successfully updated`;
            if (isNew(id)) {
                msg = `User ${name} was successfully created`;
                history.push(`/users`);
            }
            dispatch(success(msg));
        };
        const onSubmit = data => dispatch(
            isNew(id)
            ? createUser({data, onSuccess})
            : updateUser(id, {data, onSuccess})
        );
        return {
            onSubmit
        };
    }
)(UserForm));