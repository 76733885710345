import { combineReducers } from 'redux';
import loading from './loading';
import filters from './filters';
import info from './info';
import users from './users';
import skills from './skills';
import date from './date';
import skillsFilter from './skillsFilter';

export default combineReducers({
    loading,
    filters,
    info,
    users,
    date,
    skills,
    skillsFilter
});