import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import NotificationList from '../../components/Portlet/NotificationList';
import EntitiesListItem from './EntitiesListItem';

const log = Logger('EntitiesList');

function EntitiesList({items}) {
    log.debug('render');
    return <NotificationList 
        className="scroll" 
        ids={Object.keys(items)} 
        Item={EntitiesListItem} 
    />  
}

EntitiesList.propTypes = {
    items: PropTypes.object.isRequired
};

export default connect(
    state => ({
        items: state.entities
    }),
    {}
)(EntitiesList);