import {
    fetchActionsSuccess,
    fetchActionsError,
    fetchActions,
    typeCreateAction,
    typeCreateActionSuccess,
    typeCreateActionError,
    typeDeleteAction,
    typeDeleteActionSuccess,
    typeDeleteActionError,
    typeUpdateAction,
    typeUpdateActionSuccess,
    typeUpdateActionError
} from '../constants';

const init = {
    loading: false
};

export default function(state = init, { type }) {
    switch (type) {
        case fetchActionsSuccess:
        case fetchActionsError:
        case typeCreateActionSuccess:
        case typeCreateActionError:
        case typeDeleteActionSuccess:
        case typeDeleteActionError:
        case typeUpdateActionSuccess:
        case typeUpdateActionError:
            return {
                ...state,
                loading: false
            };
        case fetchActions:
        case typeCreateAction:
        case typeDeleteAction:
        case typeUpdateAction:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}