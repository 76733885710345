import {
    typeSignOut
} from '../constants';
import Auth from '@aws-amplify/auth';

const AUTH_SOURCE_KEY = 'amplify-react-auth-source';

export function signOut() {
    return dispatch => {
        dispatch({type: typeSignOut});
		try {
			localStorage.removeItem(AUTH_SOURCE_KEY);
		} catch (e) {
			console.error(
				`Failed to parse the info from ${AUTH_SOURCE_KEY} from localStorage with ${e}`
			);
		}
        if (!Auth || typeof Auth.signOut !== 'function') {
			throw new Error(
				'No Auth module found, please ensure @aws-amplify/auth is imported'
			);
		}
		Auth.signOut();
    };
}