import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react';
import { I18n } from '@aws-amplify/core';

class MyConfirmSignUp extends ConfirmSignUp {

    showComponent() {
		const username = this.usernameFromAuthData();
        return (
            <div className="kt-login__signin">
                <div className="kt-login__head">
                    <div className="kt-login__title">
                        {I18n.get('Confirm Sign Up')}
                    </div>
                </div>
                <form className="kt-form">
                    <div className="input-group">
                        <input
                            className="form-control" 
                            placeholder={I18n.get('Username')}
							key="username"
							name="username"
							onChange={this.handleInputChange}
							disabled={username}
							value={username ? username : ''}
                        />
					</div>
                    <div className="input-group">
                        <input
                            className="form-control" 
							autoFocus
							placeholder={I18n.get('Enter your Confirmation Code')}
							key="code"
							name="code"
							autoComplete="off"
							onChange={this.handleInputChange}
						/>
                    </div>
                    <div className="row kt-login__extra">
						<div className="col">
                            <span>{I18n.get('Lost your code? ')}</span>
                            <span className="kt-login__link"
                                onClick={this.resend}
                            >{I18n.get('Resend Code')}</span>
						</div>
					</div>
					<div className="kt-login__actions">
                        <button 
                            onClick={this.confirm}
                            type="button"
                            className="btn btn-brand btn-elevate kt-login__btn-primary"
                        >
                            {I18n.get('Confirm')}
                        </button>
					</div>
				</form>
                <div className="kt-login__account">	
                    <span className="kt-login__account-link"
                        onClick={() => this.changeState('signIn')}
                    >
                        {I18n.get('Back to Sign In')}
                    </span>
                </div>
            </div>
        )
	}
}

export default MyConfirmSignUp;