import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import Item from './ValuesListItem';
import { Button } from 'react-bootstrap';
import { newEntityValue } from '../../actions/entity';

const log = Logger('ValuesList');

function ValuesList({items, newEntityValue, entity}) {
    log.debug('render');

    const ids = Object.keys(items);
    const onClick = useCallback(() => newEntityValue(entity), [newEntityValue, entity]);

    return (
        <div className="kt-widget1 kt-widget1_1">
            <Button onClick={onClick} className="btn-sm" variant="primary">New Value</Button>
            {
                ids.map(id => (
                    <Item key={id} id={id}/>
                ))
            }
        </div>
    )
}

ValuesList.propTypes = {
    items: PropTypes.object.isRequired
};

export default connect(
    state => ({
        items: state.entities[state.currentEntity].items,
        entity: state.currentEntity
    }),
    { newEntityValue }
)(ValuesList);