import React, { memo } from 'react';
import ApiKeyForm from './ApiKey';
import BearerForm from './Bearer';
import BasicForm from './Basic';
import Oauth2Form from './Oauth2';

export default memo(({
    type,
    ...props
}) => {
    let Comp = null;
    switch(type) {
        case 'apiKey':
            Comp = ApiKeyForm;
            break;
        case 'bearer':
            Comp = BearerForm;
            break;
        case 'basic':
            Comp = BasicForm;
            break;
        case 'oauth2':
            Comp = Oauth2Form;
            break;
        default:
            return null;
    }

    return (
        <Comp nested {...props}/>
    )
});