import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getNodeName } from '../../reducers/nodes';
import './NodeItemTitle.sass';

function NodeItemTitle({
    title, conditions, nodeName, skipUserInput
}) {

    const behavior = useMemo(() => {
        if (nodeName) {
            return `Redirect to: ${nodeName}`;
        } else if (skipUserInput) {
            return 'Skip the user response';
        }
        return 'Wait for user response';
    }, [nodeName, skipUserInput]);

    return (
        <div className="node-item-title">
            <span className="kt-label-font-color-1">Name: </span>
            <span className="node-title">
                {
                    title
                    ? title
                    : <span className="node-no-title">No Name</span>
                }
            </span>
            <br/>
            <span className="kt-label-font-color-1">Condition: </span>
            <span className="kt-font-primary">
                {
                    conditions
                    ? conditions 
                    : <span className="node-no-title">---</span>
                }
            </span>
            <br/>
            <span className="kt-label-font-color-1">Behavior: </span>
            <span className="kt-font-primary">
                { behavior }
            </span>
        </div>
    )
}

NodeItemTitle.propTypes = {
    title: PropTypes.string
};

export default connect(
    (state, {id}) => {
        const node = state.nodes[id] || {};
        const { skipUserInput, jumpTo } = node;
        let nodeName = null;
        if (jumpTo) nodeName = getNodeName(state, {id: jumpTo});
        return {
            title: node.title,
            conditions: node.conditions,
            nodeName,
            skipUserInput
        }
    },
    {}
)(NodeItemTitle);