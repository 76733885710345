
import {
    typeNewEntity,
    typeUpdateEntityName,
    typeDeleteEntity,
    typeNewEntityValue,
    typeEntityValueName,
    typeDeleteEntitySynonym,
    typeAddEntitySynonym,
    typeDeleteEntityValue ,
    fetchTrainingModelSuccess,
    fetchTrainingModel,
    typeAddValueToEntity
} from '../constants';
import uuid from 'uuid/v1';

function synonyms(state, type, payload){
    switch (type) {
        case typeDeleteEntitySynonym:
            return state.filter((v, k) => k !== payload.synonymId);
        case typeAddEntitySynonym:
            return [
                ...state,
                payload.synonym
            ];
        default:
            return state;
    }
}

function value(state, type, payload){
    switch (type) {
        case typeDeleteEntitySynonym:
        case typeAddEntitySynonym:
            return {
                ...state,
                synonyms: synonyms(state.synonyms, type, payload)
            };
        case typeAddValueToEntity:
            return {
                id: payload.valueId,
                name: payload.name,
                synonyms: []
            };
        case typeNewEntityValue:
            return {
                id: payload.valueId,
                name: '',
                synonyms: []
            };
        case typeEntityValueName:
            return {
                ...state,
                name: payload.name
            };
        case typeNewEntity: 
            return {
                id: uuid(),
                name: payload.name,
                synonyms: []
            };
        default:
            return state;
    }
}

function values(state, type, payload){
    switch (type) {
        case typeDeleteEntityValue: {
            delete state[payload.valueId];
            return {
                ...state
            };
        }
        case typeAddValueToEntity:
        case typeNewEntityValue:
            return {
                [payload.valueId]: value(state[payload.valueId], type, payload),
                ...state
            };
        case typeEntityValueName:
        case typeDeleteEntitySynonym:
        case typeAddEntitySynonym:
            return {
                ...state,
                [payload.valueId]: value(state[payload.valueId], type, payload)
            };
        case typeNewEntity: {
            const el = value({}, type, payload);
            return {
                [el.id]: el
            };
        }
        default:
            return state;
    }
}

function entity(state, type, payload){
    switch (type) {
        case typeAddValueToEntity:
        case typeNewEntityValue:
        case typeEntityValueName:
        case typeDeleteEntitySynonym:
        case typeAddEntitySynonym:
        case typeDeleteEntityValue:
            return {
                ...state,
                items: values(state.items, type, payload)
            };
        case typeUpdateEntityName: {
            let name = payload.name.trim().replace(/ /g,"_").replace(/[^a-zA-Z0-9_-]/g, "");
            return {
                ...state,
                name: name || state.id
            };
        }
        case typeNewEntity:
            return {
                ...state,
                items: values([], type, payload)
            };
        default:
            return state;
    }
}

export default function(state = {}, { type, payload }){
    switch (type) {
        case fetchTrainingModelSuccess: 
            return {
                ...payload.data.entities
            };
        case typeAddValueToEntity:
        case typeDeleteEntitySynonym:
        case typeAddEntitySynonym:
        case typeNewEntityValue:
        case typeEntityValueName:
        case typeUpdateEntityName:
        case typeDeleteEntityValue:
            return {
                ...state,
                [payload.entity]: entity(state[payload.entity], type, payload)
            };
        case typeDeleteEntity: {
                delete state[payload];
                return {
                    ...state
                };
        }
        case typeNewEntity:
            return {
                [payload.id]: entity(payload, type, payload),
                ...state
            };
        case fetchTrainingModel:
            return {};
        default:
            return state;
    }
}