import {
    fetchSkills,
    typeCreateSkill,
    typeDeleteSkill,
    typeSaveSkill,
    fetchSkill,
    typeCleanSkillData,
    typeUpdateSkill
} from '../constants';
import { apiAction } from './api';

export const getSkillData = id => {
    return apiAction({
        path: `/skills/${id}/nodes`,
        name: fetchSkill,
        payload: {
            id
        }
    });
};

export const cleanSkillData = () => ({
    type: typeCleanSkillData
});

export const getSkills = () => {
    return apiAction({
        path: `/skills`,
        name: fetchSkills
    });
};

export const createSkill = props => {
    return apiAction({
        path: `/skills`,
        method: 'post',
        name: typeCreateSkill,
        ...props
    });
};

export const deleteSkill = id => {
    return apiAction({
        path: `/skills/${id}`,
        method: 'del',
        name: typeDeleteSkill,
        payload: {
            id
        }
    });
};

export const updateSkill = (id, props) => {
    return apiAction({
        path: `/skills/${id}`,
        method: 'post',
        name: typeUpdateSkill,
        ...props
    });
};

export const saveSkill = () => ((dispatch, getState) => {
    const {nodes, skill} = getState();
    let _nodes = {};
    
    Object.keys(nodes).forEach(k => {
        _nodes[k] = {...nodes[k]};
        if (nodes[k].actions) {
            _nodes[k].actions = JSON.stringify(nodes[k].actions);
        }
        if (nodes[k].context) {
            _nodes[k].context = JSON.stringify(nodes[k].context);
        }
        if (typeof nodes[k].output !== 'string') {
            _nodes[k].output = JSON.stringify(nodes[k].output);
        }
    });
    dispatch(apiAction({
        path: `/skills/${skill.id}`,
        method: 'post',
        name: typeSaveSkill,
        data: {
            nodes: _nodes
        }
    }));
});