import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { Popover, Overlay, Button, ButtonToolbar } from 'react-bootstrap';
import { ReactComponent as Trash } from '../../icons/Trash.svg';
import Tooltip from '../../components/TextTooltip';
import Icon from '../../components/Icon';
import './index.sass';

const log = Logger('DeleteIconConfirmation');

function DeleteIconConfirmation({onYes, id}) {
    log.debug('render');

    const [{show, target}, setState] = useState({
        target: null,
        show: false
    });

    const handleClick = useCallback(({target}) => {
        setState(state => {
            return {
                ...state,
                target,
                show: !state.show
            };
        });
    }, []);

    const close = useCallback(() => {
        setState(st => {
            return {
                ...st,
                target: null,
                show: false
            };
        });
    }, []);

    const del = useCallback(() => {
        onYes();
    }, [onYes]);

    useEffect(() => {
        let tId = null;
        setState(st => {
            if (st.tId) {
                clearTimeout(st.tId);
            }
            if (show) {
                tId = setTimeout(close, 4000);
                return {
                    ...st,
                    tId
                };
            }
            return st;
        });
        return () => {
            if (tId) clearTimeout(tId);
        };
    }, [show, close]);

    useEffect(() => {
        close();
    }, [id, close]);

    return useMemo(() => (
        <ButtonToolbar>
            <Tooltip
                text="Delete"
                position="left"
            >
                <Icon
                    onClick={handleClick}
                    active={show}
                >
                    <Trash/>
                </Icon>
            </Tooltip>
            <Overlay
                show={show}
                target={target}
                placement="left"
            >
                <Popover className="delete-icon-confirmation" title="Are you sure?">
                    <Button onClick={del} variant="clean" size="sm">YES</Button>
                    <Button onClick={close} variant="clean" size="sm">NO</Button>
                </Popover>
            </Overlay>
        </ButtonToolbar>
    ), [show, target, close, handleClick, del]);
}

DeleteIconConfirmation.propTypes = {
    onYes: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};

export default memo(DeleteIconConfirmation);