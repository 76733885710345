import {
    fetchSkillsAnalyticsSuccess
} from '../../constants';

export default function (state = [], {type, payload}){
    switch (type) {
        case fetchSkillsAnalyticsSuccess:
            return payload.data.skills;
        default:
            return state;
    }
}