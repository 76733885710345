import {
    fetchIntegrationsSuccess,
    fetchIntegrationsError,
    fetchIntegrations,
    typeCreateIntegration,
    typeCreateIntegrationSuccess,
    typeCreateIntegrationError,
    typeDeleteIntegration,
    typeDeleteIntegrationSuccess,
    typeDeleteIntegrationError,
    typeUpdateIntegration,
    typeUpdateIntegrationSuccess,
    typeUpdateIntegrationError
} from '../constants';

const init = {
    loading: false
};

export default function(state = init, { type }) {
    switch (type) {
        case fetchIntegrationsSuccess:
        case fetchIntegrationsError:
        case typeCreateIntegrationSuccess:
        case typeCreateIntegrationError:
        case typeDeleteIntegrationSuccess:
        case typeDeleteIntegrationError:
        case typeUpdateIntegrationSuccess:
        case typeUpdateIntegrationError:
            return {
                ...state,
                loading: false
            };
        case fetchIntegrations:
        case typeCreateIntegration:
        case typeDeleteIntegration:
        case typeUpdateIntegration:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}