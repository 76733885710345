import React, { memo } from 'react';
import UserCard from '../UserCard';
import { Drop } from '../Dropdown';
import { faPowerOff } from '@fortawesome/pro-duotone-svg-icons';
import InfoSection from './InfoSection';
import config from '../../aws-exports';
import { faCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.sass';

function Header({
    leftContent,
    user,
    signOut
}) {
    return (
        <div className="kt-header kt-grid__item kt-header--fixed metronic">
            { leftContent }
            <div></div>
            <div className="kt-header__topbar">
                {/* <div className="kt-header__topbar-item kt-header__topbar-item--user">
                    <a 
                        href={config.chatUrl} 
                        className="kt-header__topbar-user chat-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="kt-pulse kt-pulse--brand">
                            <FontAwesomeIcon 
                                icon={faCircle} 
                                style={{width: '17px',height: '17px'}}
                            />
                            <span className="kt-pulse__ring"></span>
                        </div>
                        <span className="kt-font-bold kt-label-font-color-1">Web Chat</span>
                    </a>
                </div> */}
                <InfoSection/>
                <Drop 
                    alignRight
                    className="kt-header__topbar-item kt-header__topbar-item--user"
                    toggleProps={{
                        className:"kt-header__topbar-user"
                    }}
                    toggle={
                        <React.Fragment>
                            <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
                            <span className="kt-header__topbar-username kt-hidden-mobile">{user.firstName}</span>
                            <UserCard 
                                size="35" 
                                type="success"
                                firstName={user.firstName} 
                                lastName={user.lastName} 
                                details={null}
                            />
                        </React.Fragment>
                    }
                    menuItems={[{
                        icon: faPowerOff,
                        text: 'Sign Out',
                        onClick: signOut
                    }]}
                />
            </div>
        </div>
    )
}

export default memo(Header);