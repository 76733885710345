import React, { 
    memo
} from 'react';
import { Formik, Field, FastField } from 'formik';
import FSchema from '../formik/Schema';
import { required } from '../validators';
import { useFormRef, useFormValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import Wrap from '../components/FormWrap';
import FSelect from '../formik/Select';

const initial = [{
    key: 'service',
    val: '',
    validate: required('Service')
}, {
    key: 'location',
    val: '',
    validate: required('Location')
}, {
    key: 'method',
    val: '',
    validate: required('Method')
}, {
    key: 'actionType',
    val: 'ibm-watson',
    validate: required('Action Type')
}, {
    key: 'params',
    val: {},
    validate: Joi.object()
}];

const ibmLocations = [{
    value: 'gateway',
    label: 'Dallas'
},{
    value: 'gateway-wdc',
    label: 'Washington DC'
},{
    value: 'gateway-fra',
    label: 'Frankfurt'
},{
    value: 'gateway-syd',
    label: 'Sydney'
},{
    value: 'gateway-tok',
    label: 'Tokyo'
},{
    value: 'gateway-lon',
    label: 'London'
},{
    value: 'gateway-seo',
    label: 'Seoul'
}];

const ibmServices = [{
    value: 'natural-language-understanding',
    label: 'Natural Language Understanding',
    methods: [{
        value: 'analyze',
        label: 'Analyze text',
        params: {
            text: "",
            features: {
                entities: {
                    emotion: false,
                    sentiment: false
                },
                keywords: {
                    emotion: false,
                    sentiment: false
                }
            }
        }
    }]
}];

function IBMWatsonForm({ 
    forwardedRef, 
    values, 
    nested
}) {

    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus, validateCb} = useFormValues(values, initial);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({values: {service, method}}) => (
                <Wrap nested={nested}>
                    <Field 
                        name="service" 
                        component={FSelect} 
                        label="IBM Watson Service"
                        autoFocus={autoFocus}
                        description="Select IBM Watson Service name"
                        convertToValues
                        options={ibmServices}
                    />
                    <Field 
                        name="location" 
                        component={FSelect} 
                        label="Service location"
                        description="IBM Watson Service endpoint location"
                        convertToValues
                        options={ibmLocations}
                    />
                    {
                        service
                        ? (() => {
                            const _service = ibmServices.find(({value}) => value === service);
                            let _method = method && _service 
                                ? _service.methods.find(({value}) => value === method) 
                                : null;
                            return (
                                <React.Fragment>
                                    <Field 
                                        name="method" 
                                        component={FSelect} 
                                        label="Service Method"
                                        convertToValues
                                        description={`Select ${_service.label} method`}
                                        options={_service.methods}
                                    />
                                    {
                                        _method
                                        ? (
                                            <FastField 
                                                name="params" 
                                                label="Method Parameters"
                                                description={`${_method.label} request parameters`}
                                                component={FSchema}
                                                initialValue={_method.params}
                                                expand
                                            />
                                        ) : null
                                    }
                                </React.Fragment>
                            )
                        })() : null
                    }
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(IBMWatsonForm);