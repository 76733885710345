import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import Logger from '../../../utils/logger';
import Joi from '@hapi/joi';

const log = Logger('Forms-Form');

function mapFormItems(children, cb) {
    return children.map((el, key) => {
        if (!el || (el && !el.props)) return el;
        let _children = null;
        if (el.props.children) {
            if (el.props.children.length) {
                _children = mapFormItems(el.props.children, cb);
            } else {
                _children = mapFormItems([el.props.children], cb);
            }
        }
        if (_children) {
            if (el.props.formKey) {
                return cb(el, key, _children);
            }
            return React.cloneElement(el, { 
                key: key,
                children: _children
            });
        } else if (el.props.formKey) {
            return cb(el, key);
        }
        return el;
    });
}

function El({ 
    children, 
    onSubmit, 
    onChange, 
    actions = false,
    forwardedRef
}) {
    log.debug('render');

    const ref = useRef();
    const [state, setState] = useState({});
    const [errors, setErrors] = useState({});
    
    const _onChange = useCallback((data, name) => {
        let __state = {};
        if (!data || typeof data[name] === 'undefined') {
            data = {
                [`${name}`]: data
            };
        }
        setState(st => {
            __state = {
                ...st,
                ...data
            };
            if (onChange) onChange(__state);
            return __state;
        });
        
    }, [onChange]);

    const validateSchema = useMemo(() => {
        var keys = {};
        mapFormItems(children, (el) => {
            keys[el.props.formKey] = el.props.validate || Joi.any();
            return el;
        });
        return Joi.object().keys(keys);
    }, [children]);

    const _onSubmit = useCallback(e => {
        if (e) e.preventDefault();
        const result = validateSchema.validate(state, {abortEarly: false});
        let errors = {};
        if (result.error) {
            result.error.details.forEach(el => {
                errors[el.path[0]] = el.message;
            });
            setErrors(errors);
        } else {
            setErrors({});
            if (onSubmit) onSubmit(result.value);
        }
        return {
            value: result.value,
            errors
        };
    }, [state, validateSchema, onSubmit]);

    useEffect(() => {
        let __state = {};
        mapFormItems(children, el => {
            __state[el.props.formKey] = el.props.value || '';
            setState(__state);
        });
    }, [children]);

    const __children = useMemo(() => {
        return mapFormItems(children, (el, key, children) => {
            return React.cloneElement(el, { 
                key: key,
                name: el.props.formKey,
                onChange: d => {
                    if (el.props.onChange) el.props.onChange(d);
                    _onChange(d, el.props.formKey);
                },
                value: state[el.props.formKey] || '',
                error: errors[el.props.formKey],
                children
            });
        });
    }, [children, state, errors, _onChange]);

    const actionsBtns = useMemo(() => (
        <div className="kt-portlet__foot">
            <div className="kt-form__actions">
                <div className="row">
                    <div className="col-lg-9 ml-lg-auto">
                        <button type="submit" className="btn btn-brand">Save</button>
                        <button type="reset" className="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    ), []);

    useEffect(() => {
        if (forwardedRef) {
            forwardedRef.current = {
                submit: _onSubmit
            }
        }
    }, [forwardedRef, _onSubmit])

    return (
        <form onSubmit={_onSubmit} className="kt-form kt-form--label-right">
            <div className="kt-form__body">
                <div className="kt-section kt-section--first">
                    <div className="kt-section__body">
                        { __children }
                    </div>
                </div>  
            </div>
            <button className="kt-hide" ref={ref}></button>
            {
                actions
                ? <React.Fragment>
                    <div className="kt-separator kt-separator--space-lg kt-separator--fit kt-separator--border-solid"></div>
                    { actionsBtns }
                </React.Fragment>
                : null
            }
        </form>
    )
}

export default memo(El);