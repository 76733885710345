import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { setCurrentIntent } from '../../actions/intent';
import KTNotificationItem from '../../components/KTNotificationItem';
import { getFirstUtterance } from '../../reducers/intents';
import IntentName from '../../components/IntentName';

const log = Logger('IntentsListItem');

function IntentsListItem({name, description, active, id, setCurrentIntent}) {
    log.debug('render');

    const setCurrent = useCallback(() => {
        setCurrentIntent(id);
    }, [id, setCurrentIntent]);

    return (
        <KTNotificationItem 
            onClick={setCurrent}
            name={<IntentName name={name} />}
            description={description}
            active={active}
        />
    )
}

IntentsListItem.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool.isRequired,
    setCurrentIntent: PropTypes.func.isRequired
};

export default connect(
    (state, {id}) => {
        const item = state.intents[id];
        return {
            name: item.name, 
            description: getFirstUtterance(item), 
            active: id === state.currentIntent
        };
    },
    {
        setCurrentIntent
    }
)(IntentsListItem);