import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { I18n } from '@aws-amplify/core';
import Analytics from '@aws-amplify/analytics';

class MySignIn extends SignIn {
    async signIn(event) {
        Analytics.record({
            name: 'SignInClick',
            attributes: { username: this.inputs.username }
        });
        await super.signIn(event);
    }
    showComponent() {
        return (
            <div className="kt-login__signin">
                <div className="kt-login__head">
                    <div className="kt-login__title">
                        Sign In
                    </div>
                </div>
                <form onSubmit={this.signIn} className="kt-form">
					<div className="input-group">
                        <input
                            className="form-control" 
                            defaultValue={this.state.username}
                            autoFocus
                            placeholder="Email" 
                            key="username"
                            name="username"
                            onChange={this.handleInputChange}
                        />
					</div>
					<div className="input-group">
                        <input 
                            className="form-control" 
                            type="password" 
                            placeholder="Password" 
                            name="password"
                            key="password"	
                            onChange={this.handleInputChange}
                        />
					</div>
					<div className="row kt-login__extra">
						<div className="col">
                            <div className="kt-login__link"
                                onClick={() => this.changeState('forgotPassword')} 
                            >Forget Password ?</div>
						</div>
					</div>
					<div className="kt-login__actions">
                        <button 
                            type="submit"
                            id="kt_login_signin_submit" 
                            className="btn btn-brand btn-elevate kt-login__btn-primary"
                            disabled={this.state.loading}
                        >{I18n.get('Sign In')}</button>
					</div>
				</form>
                {/* <div className="kt-login__account">
                    <span className="kt-login__account-msg">
                        Don't have an account yet ?
                    </span>
                    &nbsp;&nbsp;	
                    <span className="kt-login__account-link"
                        onClick={() => this.changeState('signUp')}
                    >
                        Sign Up!
                    </span>
                </div> */}
            </div>
        )
	}
}

export default MySignIn;