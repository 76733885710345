import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { types } from '../../reducers/notification';
import Analytics from '@aws-amplify/analytics';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

function limitsError(title, limitText = 'service plan limits') {
    return {
        title,
        timer: null,
        toast: false,
        position: 'center',
        animation: false,
        customClass: {
            popup: 'animated tada'
        },
        html: `<a class="kt-link kt-font-bolder" target="_blank" rel="noopener noreferrer" href="https://docs.hala.ai/help/service-plan">${limitText}.</a>`
    };
}

function transformMessages(title) {
    switch(title) {
        case 'exceeded_limits_entities':
            return limitsError(`You have exceeded the limits of the entities number (max 50)`);
        case 'exceeded_limits_intents':
            return limitsError(`You have exceeded the limits of the intents number (max 100)`);
        case 'exceeded_limits_users':
            return limitsError(`You have exceeded the limits of the users’ number (max 1)`, 'Beta Version limits');
        default:
            return {title};
    }
}

function Notification({data}) {
    if (data.message) {
        if (data.type === types.error) {
            Analytics.record({
                name: 'NotificationError',
                attributes: { 
                    error: data.message
                }
            });
        }
        Toast.fire({
            type: data.type,
            timer: data.type === types.error ? 7000 : 3000,
            ...transformMessages(data.message)
        });
    }
    return null;
}

export default connect(
    state => ({
        data: state.notification
    }),
    {}
)(Notification);