import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/input/style/index.css';
import { setDates } from '../../actions/analytics';

const { RangePicker } = DatePicker;
const dateFormat = 'MMMM Do, YYYY';

function AnalyticsPeriod({
    from, to, setDates
}) {

    const onChange = useCallback(([start, end]) => 
        start && end && setDates(start.toISOString(), end.endOf('day').toISOString())
    , [setDates]); 

    return (
        <RangePicker
            defaultValue={[moment(from), moment(to)]}
            onChange={onChange}
            format={dateFormat}
        />
    )
}

export default connect(
    state => ({
        from: state.analytics.filters.from, 
        to: state.analytics.filters.to,
    }),
    {
        setDates
    }
)(AnalyticsPeriod);