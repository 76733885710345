import React, { memo } from 'react';

function KTInfobox({
    title,
    badge,
    header,
    children,
    ...props
}) {

    return (
        <div className="kt-infobox" {...props}>
            {
                header || title || badge
                ? (
                    <div className="kt-infobox__header">
                        { header }
                        {
                            title
                            ? <div className="kt-infobox__title">{title}</div>
                            : null
                        }
                        {
                            badge
                            ? <div className="kt-infobox__badge">{badge}</div>
                            : null
                        }
                    </div>
                ) : null
            }
            <div className="kt-infobox__body">
                <div className="kt-infobox__section">
                    <div className="kt-infobox__content">
                        { children }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(KTInfobox);