import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import NavMenu from '../../components/Dropdown/NavMenu';
import { faTrash, faFileEdit } from '@fortawesome/pro-solid-svg-icons';
import {
    deleteIntegration
} from '../../actions/integrations';

const log = Logger('IntegrationsItemMenu');

function IntegrationsItemMenu({
    id,
    deleteItem,
    type
}) {
    log.debug('render');

    return <NavMenu
        data={[{
            text: 'Edit',
            icon: faFileEdit,
            link: `/integrations/${type}/${id}`
        },{
            text: 'Delete',
            icon: faTrash,
            onClick: () => deleteItem(id)
        }]}
    />
}

IntegrationsItemMenu.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    deleteItem: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        deleteItem: deleteIntegration
    }
)(IntegrationsItemMenu);