import React, { memo } from 'react';
import Logger from '../../utils/logger';
import KTMenuLink from './Link';

const log = Logger('KTMenu');

function KTMenu({items}) {
    log.debug('render');
    return (
        <ul className="kt-menu__nav">
            {
                items.map((i, key) => (
                    <KTMenuLink 
                        key={key}
                        link={i.link}
                        title={i.title}
                        icon={i.icon || null}
                        submenu={i.submenu}
                    />
                ))
            }
        </ul>
    )
}

export default memo(KTMenu);