import React, {memo} from 'react';

function Widget12Item({desc, children, ...props}) {
    return (
        <div {...props} className="kt-widget12__info">
            <span className="kt-widget12__desc">{desc}</span>
            <span className="kt-widget12__value">{children}</span>
        </div>
    )
}

export default memo(Widget12Item);