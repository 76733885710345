import React from 'react';
import { faItalic } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createInlineStyleButton } from 'draft-js-buttons';

export default createInlineStyleButton({
    style: 'ITALIC',
    children: (
        <FontAwesomeIcon icon={faItalic} />
    )
});