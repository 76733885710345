import React from 'react';
import { connect } from 'react-redux';
import Selector from '../../components/Selector';
import { getActionById } from '../../reducers/actions';
import ActionName from '../ActionName';

const onFilter = (el, val) => el.name.toLowerCase().indexOf(val) !== -1;
const renderItem = el => <ActionName id={el.id}/>

export default connect(
    (state, props) => ({
        data: state.actions,
        onFilter,
        renderItem,
        value: getActionById(state, props)
    }),
    {}
)(Selector);