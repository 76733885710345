import React, { useCallback } from 'react';
import FormGroup from '../components/FormGroup';
import classnames from 'classnames';
import Select2 from '../components/Select2';

function Select({
    field, 
    form: {
        errors,
        setFieldValue,
        values
    },
    label,
    description,
    onChange,
    ...props
}) {

    const fieldName = field.name;
    const _onChange = useCallback(value => {
        setFieldValue(fieldName, value);
        if (onChange) onChange(value);
    }, [onChange, setFieldValue, fieldName]);

    return (
        <FormGroup 
            label={label}
            error={errors[field.name]}
            description={description}
        >
            <Select2
                className={classnames({
                    'is-invalid': errors[field.name]
                })}
                {...field}
                {...props}
                onChange={_onChange}
            />
        </FormGroup>
    )
}

export default Select;