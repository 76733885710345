import React from 'react';
import { connect } from 'react-redux';
import Selector from '../../components/Selector';
import { ROOT } from '../../reducers/nodes';
import NodeName from './Name';

const onFilter = state => (id, val) => 
    id === ROOT || (state.nodes[id].title && state.nodes[id].title.toLowerCase().indexOf(val) !== -1);
const renderItem = id => <NodeName id={id}/>;

export default connect(
    state => ({
        data: Object.keys(state.nodes),
        onFilter: onFilter(state),
        renderItem
    }),
    {}
)(Selector);