import React, { memo, useMemo } from 'react';
import Logger from '../../utils/logger';
import KTWidget1Item from '../../components/KTWidget1Item';
import AddSynonym from './AddSynonym';
import SynonymsList from './SynonymsList';
import EntityValueName from './EntityValueName';
import EntityValueActions from './EntityValueActions';
import './ValuesListItem.sass';

const log = Logger('ValuesListItem');

function ValuesListItem({id}) {
    log.debug('render');

    const Desctiption = useMemo(() => (
        <div className="synonyms kt-section">
            <div className="kt-section__desc">Synonyms:</div>
            <div className="kt-section__content">
                <SynonymsList id={id}/>
                <AddSynonym id={id}/>
            </div>
        </div>
    ),[id])

    return (
        <KTWidget1Item 
            className="values-list-item"
            text={<EntityValueName id={id}/>}
            desctiption={Desctiption}
        >
            <EntityValueActions id={id}/>
        </KTWidget1Item>
    );
}

export default memo(ValuesListItem);