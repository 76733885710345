import React from 'react';
import SubHeader from '../../components/SubHeader';
import { connect } from 'react-redux';
import Toolbar from './SubHeaderToolbar';
import { isNew } from '../../reducers/users';

export default connect(
    (state, {id, syncRef}) => {
        const _isNew = isNew(id);
		return {
            title: _isNew ? "New User" : "Edit User",
            desc: _isNew ? 'Enter user details and submit': null,
            toolbar: <Toolbar isNew={_isNew} syncRef={syncRef}/>
		}
	},
    {}
)(SubHeader);