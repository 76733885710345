import { connect } from 'react-redux';
import Object from '../../components/Forms/components/Object';
import { onContextChange } from '../../actions/nodeForm';
import { formContextSelector } from '../../reducers/forms/skillNode';

export default connect(
    (state, props) => ({
        value: formContextSelector(state, props)
    }),
    {
        onChange: onContextChange
    }
)(Object);