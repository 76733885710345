import { combineReducers } from 'redux';
import {
    typeActiveNode,
    typeCleanSkillData,
    fetchSkill,
    typeCreateSkillSuccess,
    typeSaveSkill,
    typeSaveSkillSuccess,
    typeSaveSkillError,
    typeTriggerMoveNode,
    typeMoveNode,
    typeCopyNodeTree
} from '../constants';
import { createSelector } from 'reselect';
import { nodeHasChilds } from './nodes';

function moveNode(state = null, { type, payload }) {
    switch (type) {
        case typeTriggerMoveNode:
            return payload || null;
        case typeMoveNode:
            return null;
        default:
            return state;
    }
}

function copyTree(state = null, { type, payload }) {
    switch (type) {
        case typeCopyNodeTree:
            return payload || null;
        default:
            return state;
    }
}

function currentNode(state = null, { type, payload }) {
    switch (type) {
        case typeActiveNode:
            return payload ? payload.id : null;
        case typeCleanSkillData:
            return null;
        default:
            return state;
    }
}

function id(state = null, { type, payload }) {
    switch (type) {
        case fetchSkill:
            return payload.id;
        case typeCreateSkillSuccess:
            return payload.data._id;
        case typeCleanSkillData:
            return null;
        default:
            return state;
    }
}

function loading(state = false, { type }) {
    switch (type) {
        case typeSaveSkill:
            return true;
        case typeSaveSkillSuccess:
        case typeSaveSkillError:
            return false;
        default:
            return state;
    }
}

export const getCurrentNode = state => state.skill.currentNode;
export const getCurrentNodeAsProps = createSelector(
    getCurrentNode,
    id => ({id})
);

export const currentNodeHasChilds = createSelector(
    [st => st, getCurrentNodeAsProps],
    nodeHasChilds
);

export default combineReducers({
    currentNode,
    moveNode,
    id,
    loading,
    copyTree
});