import React, { memo } from 'react';
import InfoIcon from './index';

function DocInfoIcon({
    link
}) {
    return (
        <InfoIcon 
            link={`https://docs.hala.ai${link}`}
            external 
            tooltip="Documentation"
            placement="right"
        />
    )
}

export default memo(DocInfoIcon);