import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import SubHeaderActions from '../../components/SubHeader/Actions';

function Actions({syncRef, ...props}) {
    const submit = useCallback(() => {
        syncRef.current && syncRef.current.submit && syncRef.current.submit();
    }, [syncRef]);
    return <SubHeaderActions 
        {...props}
        submit={submit}
    />;
}

export default connect(
    (state, {isNew}) => ({
        loading: state.users.loading,
        back: "/users",
        submitText: isNew ? 'Create User' : null
    }),
    {}
)(Actions);