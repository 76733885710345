import React, { 
    useCallback, useMemo,
    useState, useEffect
} from 'react';
import { connect } from 'react-redux';
import Logger from '../../utils/logger';
import Portlet from '../../components/Portlet';
import { Toolbar } from './NodeOutputEditor';
import { onOutputChange } from '../../actions/nodeForm';
import { formOutputSelector } from '../../reducers/forms/skillNode';
import { faCode } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditorWrap from './NodeOutputEditorWrap';
import JsonEditor from './NodeOutputJsonEditor';
import { DocInfoIcon } from '../../components/InfoIcon';
import './NodeOutput.sass';

const log = Logger('NodeOutput');
const DocIcon = <DocInfoIcon link="/key-concepts/skills-kit/output-in-nodes"/>;

function NodeOutput({
    onChange,
    isCode,
    syncRef
}) {
    log.debug('render');

    const [showToolbar, setShowToolbar] = useState(false);

    const toggleCode = useCallback(() => {
        if (isCode) onChange(null);
        else onChange('[]');
    }, [isCode, onChange]);

    const title = useMemo(() => (
        <React.Fragment>
            Output
            <small onClick={toggleCode}>
                <FontAwesomeIcon icon={faCode} />
            </small>
            <span>  {DocIcon}</span>
        </React.Fragment>
    ), [toggleCode]);

    useEffect(() => {
        setShowToolbar(isCode ? false : true);
    }, [isCode]);

    const toolbar = useMemo(() => {
        if (showToolbar) 
            return  <Toolbar/>;
        return null;
    }, [showToolbar])

    return (
        <Portlet
            title={title}
            bodyFit={true}
            toolbar={toolbar}
        >
            {
                isCode
                ? <JsonEditor/>
                : <EditorWrap syncRef={syncRef}/>
            }
        </Portlet>
    )
}

export default connect(
    state => {
        const value = formOutputSelector(state);
        return {
            isCode: (typeof value === 'string') ? true : false
        };
    },
    {
        onChange: onOutputChange
    }
)(NodeOutput);