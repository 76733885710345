import {
    layoutSetConfig
} from '../constants';

export function setConfig(payload) {
    return {
        type: layoutSetConfig,
        payload
    };
}

export function setSubheader(payload) {
    return setConfig({
        subheader: payload
    });
}