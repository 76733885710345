import React from 'react';
import { connect } from 'react-redux';
import Selector from '../../components/Selector';
import EntityName from '../../components/EntityName';

const onFilter = (name, val) => name.toLowerCase().indexOf(val) !== -1;
const renderItem = (name) => <EntityName name={name} />;

export default connect(
    state => ({
        data: Object.values(state.entities).map(el => el.name),
        onFilter,
        renderItem
    }),
    {
    }
)(Selector);