import React, { memo } from 'react';
import PageContent from '../PageContent';
import Logger from '../../utils/logger';
import SubHeader from './SubHeader';
import { Switch, Route } from 'react-router-dom';
import SkillOverviewPage from '../SkillOverviewPage';
import NodePage from '../SkillNodePage';
import Nodes from './Nodes';
import SkillPublishPage from '../../pages/skillPublish';

import './index.sass';

const log = Logger('SkillPage');

function SkillPage() {
	log.debug('render');

	const ref = React.createRef();

	return (
		<PageContent 
			className="skill-page"
			subheader={<SubHeader syncRef={ref}/>}
		>
			<Switch>
				<Route exact path="/skills/new" component={props => <SkillOverviewPage {...props} syncRef={ref}/>} />
				<Route exact path="/skills/:skillId" component={ Nodes } />
				<Route exact path="/skills/:skillId/general" component={props => <SkillOverviewPage {...props} syncRef={ref}/>} />
				<Route exact path="/skills/:skillId/node/:nodeId" component={ props => <NodePage {...props} syncRef={ref}/> } />    
				<Route exact path="/skills/:skillId/publish" component={SkillPublishPage} />
			</Switch>
		</PageContent>
	);
}
        
export default memo(SkillPage);