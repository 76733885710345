import React, { memo, useEffect, useRef } from 'react';
import './index.sass';

function LoaderBlock({loading}) {
    const ref = useRef();

    useEffect(() => {
        if (loading) ref.current.classList.add('show');
    }, [ref, loading]);


    if (!loading) {
        return null;
    }

    return (
        <div ref={ref} className="loader-block">
            <div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand"></div>
        </div>
    )
}

export default memo(LoaderBlock);