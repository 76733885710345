import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import PageItemsGrid from '../PageItemsGrid';
import KTProject1 from '../../components/KTProject1';
import ItemMenu from './ItemMenu';

const log = Logger('ActionsPage');

function ActionsPage({
    data
}) {
    log.debug('render');

    const item = useCallback(el => (
        <KTProject1 
            title={el.name}
            onTitle={`/actions/${el.id}`}
            toolbarMenu={<ItemMenu id={el.id}/>}
        />
    ), []);

    const onFilter = useCallback((el, filter) => {
        if (el.name.toLowerCase().indexOf(filter) !== -1) {
            return true;
        }
        return false;
    }, []);

    return (
        <PageItemsGrid
            title="Actions"
            item={item}
            data={data}
            actionBrandTo="/actions/new"
            actionBrandTitle="Create Action"
            onFilter={onFilter}
        />
    )
}

ActionsPage.propTypes = {
    data: PropTypes.array.isRequired
};

export default connect(
    state => ({
        data: state.actions
    }),
    {
    }
)(ActionsPage);