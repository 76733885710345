import React, { 
    memo, useCallback, useRef
} from 'react';
import { Formik, FastField, Field } from 'formik';
import FInput from '../formik/Input';
import FSelect from '../formik/Select';
import Wrap from '../components/FormWrap';
import { validateSchema, required, jError } from '../validators';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import IntegrationForm from './IntegrationForm';

const initialValues = { 
    name: '',
    integration: null
};

const validateCb = validateSchema(Joi.object({
    name: required('Name'),
    integration: Joi.object().required().error(jError({
        'object.base': 'Integration is required'
    }))
}));

function ActionForm({ onSubmit, forwardedRef, values, integrations }) {
    const nestedRef = useRef();
    const ref = useFormRef(forwardedRef, [nestedRef]);
    const {vals, autoFocus} = useValues(values, initialValues);
    const _onSubmit = useCallback(() => {
        if (onSubmit) onSubmit(forwardedRef.current.getValues());
    }, [onSubmit, forwardedRef]);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmit}
        >
            {({values: {integration}}) => (
                <Wrap>
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Action Name"
                        description="Specify action name"
                        component={FInput} 
                    />
                    <Field 
                        name="integration" 
                        component={FSelect} 
                        label="Integration"
                        description="Select integration"
                        options={integrations}
                    />
                    {integration ? <IntegrationForm 
                        forwardedRef={nestedRef}
                        type={integration.type} 
                        integration={integration}
                        values={values}
                    /> : null}
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(ActionForm);