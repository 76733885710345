import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dispatchClick } from './index';
import Div from '../Static/Div';
import A from '../Static/A';
import { Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
import './index.sass';

const LinkWrap = memo(({
    type, link, children
}) => {

    const { Wrap, props } = useMemo(() => {
        let Wrap = Div;
        let props = {
            className: "kt-nav__link"
        };

        if (link) switch(type) {
            case 'external':
                Wrap = A;
                props = {
                    ...props,
                    onClick: dispatchClick,
                    href: link,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                };
                break;
            default:
                Wrap = Link;
                props = {
                    ...props,
                    onClick: dispatchClick,
                    to: link
                };
                break;
        }

        return { Wrap, props };
    }, [type, link]);

    return (
        <Wrap {...props}>
            { children }
        </Wrap>
    )

})

const CustomItem = React.forwardRef(({...props}, ref) => {
    return (
        <li ref={ref} {...props}></li>
    );
});

const ItemWrap = memo(({value, ...props}) => {

    if (!value) {
        return <CustomItem {...props}/>
    }

    return (
        <Dropdown.Item 
            eventKey={value}
            as={CustomItem}
            bsPrefix={'custom-item'}
            {...props}
        />
    )
});

function DropdownNavMenu({
    data, 
    ...props
}) {
    return (
        <ul 
            {...props} 
            className="kt-nav kt-nav--bold kt-nav--md-space kt-nav--v3 drop-nav"
        >
            {
                data.map((el,key) => {
                    return (
                        <ItemWrap 
                            key={key} 
                            onClick={el.disabled ? null : el.onClick}
                            value={el.value}
                            className={classnames('kt-nav__item', {
                                "kt-nav__item--active": el.active,
                                "kt-nav__item--disabled": el.disabled
                            })}
                        >
                            <LinkWrap type={el.type} link={el.link}>
                                {
                                    el.icon
                                    ? (
                                        <div className="kt-nav__link-icon">
                                            <FontAwesomeIcon icon={el.icon}/>
                                        </div>
                                    ) : null
                                }
                                <div className="kt-nav__link-text">
                                    {el.text}
                                </div>
                            </LinkWrap>
                        </ItemWrap>
                    )
                })
            }													
        </ul>
    )
}

export default memo(DropdownNavMenu);