import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import IntentEditor from './IntentEditor';

const log = Logger('IntentEditorWrap');

function El({hasActiveUtterance}) {
    log.debug('render');

    if (hasActiveUtterance) {
        return null;
    }

    return <IntentEditor/>
}

El.propTypes = {
    hasActiveUtterance: PropTypes.bool.isRequired
};

export default connect(
    state => ({
        hasActiveUtterance: state.currentUtterance ? true : false
    }),
    {
    }
)(El);