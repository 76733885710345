import { connect } from 'react-redux';
import Pagination from '../../components/Pagination';
import {
    setPageSize, goToPage
} from '../../actions/users';

export default connect(
    state => ({
        currentPage: state.users.currentPage,
        total: state.users.total,
        pageSize: state.users.pageSize
    }),
    {
        setPageSize,
        goToPage
    }
)(Pagination);