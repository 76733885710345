import {
    fetchTrainingModelSuccess,
    fetchTrainingModelStatusSuccess,
    typeTrainModel,
    typeTrainModelSuccess,
    typeTrainModelError,
    typeSaveTrainingModel,
    typeSaveTrainingModelSuccess,
    typeSaveTrainingModelError
} from '../constants';

const init = {
    version: 0,
    isFetched: false,
    training: false,
    loading: false
};

export default function currentIntent(state = init, { type, payload }) {
    switch (type) {
        case fetchTrainingModelSuccess:
            return {
                ...state,
                version: payload.data.version,
                training: payload.data.training,
                loading: false,
                isFetched: true
            };
        case fetchTrainingModelStatusSuccess:
            return {
                ...state,
                version: payload.data.version,
                isFetched: state.version === payload.data.version,
                training: payload.data.training
            };
        case typeSaveTrainingModel:
            return {
                ...state,
                loading: true
            };
        case typeSaveTrainingModelSuccess:
            return {
                ...state,
                version: payload.data.version,
                loading: false
            };
        case typeSaveTrainingModelError: 
            return {
                ...state,
                loading: false
            };
        case typeTrainModel: 
            return {
                ...state,
                training: true
            };
        case typeTrainModelSuccess:
        case typeTrainModelError:
            return {
                ...state,
                training: false
            };
        default:
            return state;
    }
}