import React, { 
    memo, useRef
} from 'react';
import { Formik, Field } from 'formik';
import SoapAction from './SoapAction';
import FSchema from '../formik/Schema';
import { validateSchema } from '../validators';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';

const initialValues = { 
    actionType: 'soap',
    params: {
        "":""
    }
};

const validateCb = validateSchema(Joi.object({
    actionType: Joi.any(),
    params: Joi.object()
}));

function SapForm({ 
    forwardedRef, 
    values, 
    integration 
}) {
    const nestedRef = useRef();
    const ref = useFormRef(forwardedRef, [nestedRef]);
    const {vals} = useValues(values, initialValues);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {() => (
                <React.Fragment>
                    <SoapAction
                        forwardedRef={nestedRef} 
                        values={values}
                        integration={integration}
                    />
                    <Field 
                        name="params" 
                        label="Parameters"
                        description="Specify request parameters"
                        component={FSchema}
                    />
                </React.Fragment>
            )}
        </Formik>
    )
}

export default memo(SapForm);