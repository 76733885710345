import React, { memo } from 'react';
import { Formik, FastField } from 'formik';
import FInput from './formik/Input';
import { required } from './validators';
import { useFormRef, useFormValues } from './formik/hooks';
import Wrap from './components/FormWrap';

const initial = [{
    key: 'name',
    val: '',
    validate: required('Name')
}, {
    key: 'type',
    val: 'aws'
}, {
    key: 'clientId',
    val: '',
    validate: required('Access Key Id')
}, {
    key: 'clientSecret',
    val: '',
    validate: required('Secret Access Key')
}, {
    key: 'region',
    val: '',
    validate: required('Region')
}];

function AWSIntegrationForm({ onSubmit, forwardedRef, values }) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus, validateCb} = useFormValues(values, initial);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {() => (
                <Wrap>
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Integration Name"
                        description="Specify integration name"
                        component={FInput} 
                    />
                    <FastField 
                        name="clientId" 
                        label="Access Key Id"
                        description="AWS Cloud Access Key Id"
                        type="password"
                        component={FInput}
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="clientSecret" 
                        label="Secret Access Key"
                        description="AWS Cloud Secret Access Key"
                        type="password"
                        component={FInput}
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="region" 
                        label="Region"
                        description="AWS Cloud Region"
                        component={FInput}
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(AWSIntegrationForm);