import api from '@aws-amplify/api';
import {
    API,
    apiFetch
} from '../constants';
import awsconfig from '../aws-exports';
import Analytics from '@aws-amplify/analytics';

api.configure(awsconfig);

export default ({dispatch}) => next =>  async action => {
    next(action);

    if (action.type !== API) return;

    const {
        path = '',
        name = '',
        method = 'get',
        data = {},
        onSuccess = null,
        onError = null,
        headers = {},
        qs = {},
        payload = {},
        transform = null
    } = action.payload;

    if (name) dispatch({type: name, payload});
    dispatch({type: apiFetch});

    api[method]('api', path, {
        queryStringParameters: qs,
        body: data,
        headers
    })
    .then(response => {
        payload.data = response;
        if (transform) {
            payload.data = transform(payload.data);
        }
        if (name) dispatch({type: `${name}Success`, payload});
        dispatch({type: `${apiFetch}Success`});
        if (onSuccess) onSuccess(payload);
    }).catch(error => {
        console.error(error);

        let data = {
            error: 'Api Error'
        };
        if (error) {
            if (error.response && error.response.data) {
                data = error.response.data;
            } else if (error.message) {
                data.error = error.message;
            }
        } 
        Analytics.record({
            name: 'APIMiddlewareError',
            attributes: { 
                error: data.error,
                method,
                path
            }
        });
        if (name) dispatch({type: `${name}Error`, payload: data});
        dispatch({type: `${apiFetch}Error`, payload: data});
        if (onError) onError(data);
    });
};