import React from 'react';
import Logger from '../../utils/logger';
import { ReactComponent as HomeIcon } from '../../icons/Home.svg';
import { ReactComponent as UsersIcon } from '../../icons/Group.svg';
// import { ReactComponent as Cart2 } from '../../icons/Cart2.svg';
// import { ReactComponent as Layers } from '../../icons/Layers.svg';
import { ReactComponent as Chat1 } from '../../icons/Chat1.svg';
import { ReactComponent as Server } from '../../icons/Server.svg';
import { ReactComponent as Shuffle } from '../../icons/Shuffle.svg';
// import { ReactComponent as Share } from '../../icons/Share.svg';
import {Link} from 'react-router-dom';
import { faHashtag, faAt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.sass';
// import { ReactComponent as ChartBar2 } from '../../icons/ChartBar2.svg';

import KTMenu from '../../components/KTMenu';

const log = Logger(':::Aside:::');

function Component() {
	log.debug('render');
    return (
        <React.Fragment>
			<div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop metronic" id="kt_aside">
				<div className="kt-aside__brand kt-grid__item">
					<div className="kt-aside__brand-logo1">
						<Link to="/">
							<img width="120px" alt="Logo" src="/assets/img/hala-full-logo-white.svg" />
						</Link>
						{/* <span className="brand-logo-desc">free trial beta version</span> */}
					</div>
				</div>
				<div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
					<div id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
						<KTMenu
							items={[{
								link: '/dashboard',
								title: 'Dashboard',
								icon: <HomeIcon/>
							// },{
							// 	link: '/users',
							// 	title: 'User Management',
							// 	icon: <UsersIcon/>
							// },{
							// 	link: '/skills-store',
							// 	title: 'Skills Store',
							// 	icon: <Cart2/>
							// },{
							// 	link: '/assistants',
							// 	title: 'Assistants',
							// 	icon: <Layers/>
							},{	
								link: '/skills',
								title: 'Skills kit',
								icon: <Chat1/>
							},{	
								link: '/intents',
								title: 'Intents',
								icon: <FontAwesomeIcon icon={faHashtag}/>
							},{	
								link: '/entities',
								title: 'Entities',
								icon: <FontAwesomeIcon icon={faAt}/>
							// },{
							// 	link: '/skills',
							// 	title: 'Skills kit',
							// 	icon: <Chat1/>,
							// 	submenu: [{
							// 		link: '/intents',
							// 		title: 'Intents'
							// 	},{
							// 		link: '/entities',
							// 		title: 'Entities'
							// 	},{
							// 		link: '/variables',
							// 		title: 'Variables'
							// 	}]
							},{
								link: '/integrations',
								title: 'Integrations',
								icon: <Server/>
							},{
								link: '/actions',
								title: 'Actions',
								icon: <Shuffle/>
							// },{
							// 	link: '/training',
							// 	title: 'Training',
							// 	icon: <Share/>
							}
							// ,{
							// 	link: '/analytics',
							// 	title: 'Analytics',
							// 	icon: <ChartBar2/>
							// }
						]}
						/>
					</div>
				</div>
			</div>
        </React.Fragment>
    );
}

export default Component;