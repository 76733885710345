import React, { memo } from 'react';
import Input from '../../components/Input';
import ContextSelector from '../../components/ContextSelector';
import { 
    skillNodeFormConnector
} from '../../reducers/forms/skillNode';
import ActionsSelector from './ActionsSelector';
import Portlet from '../../components/Portlet';
import AddActionBtn from './AddActionBtn';
import NodeContext from './NodeContext';
import NodeOutput from './NodeOutput';
import NodeBehavior from './NodeBehavior';
import { DocInfoIcon } from '../../components/InfoIcon';

const stateConnector = skillNodeFormConnector();
const NodeInput = stateConnector(Input);
const NodeConditions = stateConnector(ContextSelector);
const Section = memo(props => <Portlet className="kt-portlet--head-noborder" {...props}/>);

const Conditions = (
    <React.Fragment>
        <span>Conditions </span>
        <DocInfoIcon 
            link="/key-concepts/skills-kit/conditions-in-nodes"
        />
    </React.Fragment>
)
const Actions = (
    <React.Fragment>
        <span>Actions </span>
        <DocInfoIcon 
            link="/key-concepts/skills-kit/actions-in-nodes"
        />
    </React.Fragment>
)
const Context = (
    <React.Fragment>
        <span>Context </span>
        <DocInfoIcon 
            link="/key-concepts/skills-kit/context-in-nodes"
        />
    </React.Fragment>
)
const Behavior = (
    <React.Fragment>
        <span>Behavior </span>
        <DocInfoIcon 
            link="/key-concepts/skills-kit/behavior"
        />
    </React.Fragment>
)

function SkillNodeForm({syncRef}) {
    return (
        <React.Fragment>
            <Section title="Name">
                <NodeInput name="title"/>
            </Section>
            <Section title={Conditions}>
                <NodeConditions name="conditions"/>
            </Section>
            <Section 
                title={Actions}
                toolbar={<AddActionBtn/>}
            >
                <ActionsSelector/>
            </Section>
            <Section title={Context}>
                <NodeContext/>
            </Section>
            <NodeOutput syncRef={syncRef}/>
            <Section 
                title={Behavior}
                toolbar={<NodeBehavior/>}
            />
        </React.Fragment>
    )
}

export default memo(SkillNodeForm);