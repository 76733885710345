import React, { memo } from 'react';
import Logger from '../../utils/logger';
import PageContent from '../PageContent';
import IntentsSubHeader from './IntentsSubHeader';
import IntentsList from './IntentsList';
import IntentContent from './IntentContent';
import PageLayout1 from '../../components/PageLayout1';
// import SaveChangesPrompt from '../SaveChangesPrompt';

const log = Logger('IntentsPage');

function IntentsPage() {
    log.debug('render');

    return (
        <PageContent 
            fixedHeight
			subheader={<IntentsSubHeader/>}
		>
            {/* <SaveChangesPrompt/> */}
            <PageLayout1
                left={<IntentsList/>}
                right={<IntentContent/>}
            />
        </PageContent>
    )
}

export default memo(IntentsPage);