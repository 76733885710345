import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap'; 
import NavMenu from '../../components/Dropdown/NavMenu';
import { faEdit, faTrashAlt, faEllipsisH } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confirmation from '../../components/Confirmation';
import { deleteUser } from '../../actions/users';

const initState = {
    confirm: false,
    show: false
};

function RowActions({
    userId, deleteUser, isCurrentUser
}) {
    const [{confirm, show}, setState] = useState(initState);

    const menu = useMemo(() => {
        let data = [{
            text: 'Edit',
            link: `/users/${userId}`,
            icon: faEdit
        }];
        if (!isCurrentUser) data.push({
            text: 'Delete',
            icon: faTrashAlt,
            onClick: () => setState(st => ({
                show: true,
                confirm: true
            }))
        });
        return <NavMenu data={data} />;
    }, [userId, isCurrentUser]);

    const confirmation = useMemo(() => {
        return <Confirmation onYes={() => deleteUser(userId)} />;
    }, [userId, deleteUser]);

    const onToggle = useCallback(show => {
        setState(st => ({
            confirm: !show ? false : st.confirm,
            show
        }))
    }, []);

    const close = useCallback((e) => {
        e.stopPropagation();
        setState(initState);
    }, []);

    return (
        <Dropdown
            onToggle={onToggle}
            show={show}
            alignRight
        >
            <Dropdown.Toggle 
                childBsPrefix="btn-custom"
                bsPrefix="btn btn-clean btn-icon"
            >
                <FontAwesomeIcon icon={faEllipsisH}/>
            </Dropdown.Toggle>
            <Dropdown.Menu 
                onClick={confirm ? close : null}
                className="dropdown-menu-anim"
            >
                { 
                    confirm
                    ? confirmation
                    : menu
                }
            </Dropdown.Menu>			
	    </Dropdown>
    )
}

export default connect(
    (state, {id}) => {
        const userId = state.users.list[id]._id;
        return {
            userId,
            isCurrentUser: userId === state.auth.user.id
        };
    },
    {
        deleteUser
    }
)(RowActions);