import React, { 
    memo, useCallback
} from 'react';
import { Formik, FastField } from 'formik';
import FInput from './formik/Input';
import { validateSchema, required, jIsUrl, jError } from './validators';
import { useFormRef, useValues } from './formik/hooks';
import Wrap from './components/FormWrap';
import Joi from '@hapi/joi';

const initialValues = { 
    name: '',
    baseUrl: '',
    type: 'servicenow',
    username: '',
    password: '',
    clientId: '',
    clientSecret: ''
};

const validateCb = validateSchema(Joi.object({
    name: required('Integration Name'),
    baseUrl: Joi.string().required().custom(jIsUrl).error(jError({
        'string.empty': 'Base URL is required'
    })),
    type: Joi.any(),
    username: required('Username'),
    password: required('Password'),
    clientId: required('Client ID'),
    clientSecret: required('Client Secret')
}));

function ServiceNowForm({ onSubmit, forwardedRef, values }) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    const _onSubmit = useCallback(() => {
        if (onSubmit) onSubmit(forwardedRef.current.getValues());
    }, [onSubmit, forwardedRef]);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmit}
        >
            {() => (
                <Wrap>
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Integration Name"
                        description="Specify integration name"
                        component={FInput} 
                    />
                    <FastField 
                        name="baseUrl" 
                        label="Base URL"
                        description="ServiceNow instance base URL. (Example: https://dev11111.service-now.com/)"
                        component={FInput} 
                    />
                    <FastField 
                        name="username" 
                        label="Username"
                        component={FInput} 
                        description="ServiceNow user name"
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="password" 
                        label="Password"
                        type="password" 
                        component={FInput} 
                        description="ServiceNow user password"
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="clientId" 
                        label="Client ID"
                        description="The client identifier issued to the client during the Application registration process."
                        component={FInput}
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="clientSecret" 
                        label="Client Secret"
                        description="The client secret issued to the client during the Application registration process."
                        type="password"
                        component={FInput}
                        autoComplete="new-password"
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(ServiceNowForm);