import {
    fetchAvailableIntegrationsSuccess
} from '../constants';
import { createSelector } from 'reselect';

export default function(state = [], { type, payload }) {
    switch (type) {
        case fetchAvailableIntegrationsSuccess:
            return [
                ...payload.data
            ];
        default:
            return state;
    }
}

export const getAvailableIntegrationsSelector = state => state.availableIntegrations;
export const getIdFromProps = (_, props) => props.id;
export const getKeyFromProps = (_, props) => props.key;
export const getIntegration = (items, id) => items.find(el => el._id === id) || null;
export const getIntegrationByKey = (items, key) => items.find(el => el.key === key);

export const getAvailableIntegrationSelector = createSelector(
    [getAvailableIntegrationsSelector, getIdFromProps], getIntegration
);

export const getIntegrationByKeySelector = createSelector(
    [getAvailableIntegrationsSelector, getKeyFromProps], getIntegrationByKey
);