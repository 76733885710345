import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import Portlet from '../../components/Portlet';
import { fetchSkills, clearSkillsFilter } from '../../actions/analytics';
import LoaderBlock from '../../components/LoaderBlock';
import Table from '../../components/Table';
import TableBody from '../../components/Table/BodyRedux';
import Span from '../../components/Static/Span';
import Pagination from './SkillsPagination';
import SkillDetails from './SkillDetails';
import SkillStatus from './SkillStatus';
import UserCard from '../../components/UserCard';
import moment from 'moment';

const Loader = connect(st => ({
    loading: st.analytics.loading
}))(LoaderBlock);

const propsByListKey = cb => connect(
    (state, { id }) => cb(state.analytics.skills[id] || {}),
    {}
);

const TBody = connect(
    state => ({
        total: state.analytics.skills.length
    }),
    null
)(TableBody);

const MySkillStatus = props => (
    <Span>
        <SkillStatus {...props}/>
    </Span>
);

const columns = [{
    Head: 'Date & time',
    width: '200px',
    Cell: propsByListKey(u => ({
        string: moment(u.date).format("D MMM YYYY, H:mm:ss")
    }))(Span)
},{
    Head: 'Skill name',
    width: '300px',
    Cell: propsByListKey(u => ({
        string: u.name || u.deletedName
    }))(Span)
},{
    Head: 'User name',
    width: '200px',
    Cell: propsByListKey(u => ({
        firstName: u.firstName,
        lastName: u.lastName,
        size:"sm"
    }))(UserCard)
},{
    Head: 'Messages',
    width: '100px',
    Cell: propsByListKey(u => ({
        string: u.messages
    }))(Span)
},{
    Head: 'Status',
    width: '100px',
    Cell: propsByListKey(u => ({
        val: u.status
    }))(MySkillStatus)
},{
    Head: ' ',
    width: '100px',
    Cell: SkillDetails
}];

const _st = state => ({
    from: state.analytics.filters.from, 
    to: state.analytics.filters.to,
    currentPage: state.analytics.skillsFilter.currentPage,
    pageSize: state.analytics.skillsFilter.pageSize,
});
const _dsp = { fetchSkills, clearFilter: clearSkillsFilter };
const Fetcher = connect(
    _st,_dsp
)(({
    fetchSkills, from, to,
    currentPage, pageSize,
    clearFilter
}) => {
    useEffect(() => {
        fetchSkills({
            from, to,
            page: currentPage,
            pageSize: pageSize
        });
    }, [fetchSkills, from, to, currentPage, pageSize]);
    useEffect(() => () => clearFilter(), [clearFilter]);
    return null;
});

function SkillsList() {
    return (
        <Portlet
            className="table-type1"
            mobile={true}
            bodyFit={true}
        >
            <Fetcher/>
            <Table columns={columns}>
                <TBody columns={columns}/>
            </Table>
            <Pagination/>
            <Loader/>
        </Portlet>
    )
}

export default memo(SkillsList);