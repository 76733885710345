import React, { memo } from 'react';
import { 
    Switch, 
    Route
} from 'react-router-dom';
import {DocInfoBlock} from '../../components/InfoBlock';

const Dashboard = memo(() => (
    <DocInfoBlock link="/key-concepts/dashboard">
        The Dashboard page provides a summary of the interactions between users and Hala.
    </DocInfoBlock>
));
const DashboardSkills = memo(() => (
    <DocInfoBlock link="/key-concepts/dashboard/conversation-history">
        Information about the skills usage for a certain period of time.
    </DocInfoBlock>
));
const DashboardUsers = memo(() => (
    <DocInfoBlock link="/key-concepts/dashboard/conversation-history">
        Information about the active users for a certain period of time.
    </DocInfoBlock>
));
const Users = memo(() => (
    <DocInfoBlock link="/key-concepts/user-management">
        With user management, you can create, confirm, and manage users accounts.
    </DocInfoBlock>
));
// const SkillsStore = memo(() => (
//     <DocInfoBlock link="/skills-store">
//         The Skill Store functionality will be available in Q1 2020.
//     </DocInfoBlock>
// ));
const Skills = memo(() => (
    <DocInfoBlock link="/key-concepts/skills-kit">
        Digital Skills are best described as cognitive bots. The Skill (a bunch of the dialog nodes) matches intents (what users say) to responses (what the Hala says back) or actions.
    </DocInfoBlock>
));
const Nodes = memo(() => (
    <DocInfoBlock link="/key-concepts/skills-kit/dialog-nodes">
        A dialog node is a branching dialog flow that defines how Hala responds when it recognizes the specified conditions (intent, entity, context, regex, or function).
    </DocInfoBlock>
));
const Intents = memo(() => (
    <DocInfoBlock link="/key-concepts/intents">
        An intent represents a mapping between what a user says and what action should be taken by an assistant.
    </DocInfoBlock>
));
const Entities = memo(() => (
    <DocInfoBlock link="/key-concepts/entities">
        Entities represent concepts that are often specific to a domain as a way of mapping natural language phrases to canonical phrases that capture their meaning.
    </DocInfoBlock>
));
const Integrations = memo(() => (
    <DocInfoBlock link="/key-concepts/integrations">
        With integrations, you can connect the external applications with Hala.
    </DocInfoBlock>
));
const Actions = memo(() => (
    <DocInfoBlock link="/key-concepts/actions">
        Action is a mechanism that allows you to call out to an external program based on something happening in your skills.
    </DocInfoBlock>
));
const NewAction = memo(() => (
    <DocInfoBlock link="/key-concepts/actions">
        To add the Action, you need to provide the parameters and values for the parameters.
    </DocInfoBlock>
));

function InfoRoutes() {
    return (
        <Switch>
            <Route exact path="/dashboard" component={Dashboard}/>
            <Route exact path="/dashboard/skills" component={DashboardSkills}/>
            <Route exact path="/dashboard/users" component={DashboardUsers}/>
            <Route exact path="/users" component={Users}/>
            {/* <Route exact path="/skills-store" component={SkillsStore}/> */}
            <Route exact path="/skills" component={Skills}/>
            <Route exact path="/skills/new" component={null}/>
            <Route exact path="/skills/:skillId" component={Nodes}/>
            <Route exact path="/intents" component={Intents}/>
            <Route exact path="/entities" component={Entities}/>
            <Route exact path="/integrations" component={Integrations}/>
            <Route exact path="/actions" component={Actions}/>
            <Route exact path="/actions/new" component={NewAction}/>
        </Switch>
    )
}

export default memo(InfoRoutes);