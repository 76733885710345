import React, { memo } from 'react';
import {
    faInfo
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBlock from './index';

const InfoIcon = <FontAwesomeIcon className="kt-font-info" size="2x" icon={faInfo} />;

function DocInfoBlock({
    link,
    children
}) {
    return (
        <InfoBlock
            icon={InfoIcon}
        >
            { children }
            <br/>
            For more info please visit <a 
                className="kt-link kt-font-bold" 
                href={`https://docs.hala.ai${link}`} 
                rel="noopener noreferrer"
                target="_blank"
            >Documentation</a>.
        </InfoBlock>
    )
} 

export default memo(DocInfoBlock);                        