import {
    typeActiveNode,
    typeAddChildNode,
    typeAddNodeAbove,
    typeAddNodeBelow,
    typeMoveNode,
    typeDeleteNode,
    typeUpdateNode,
    typeTriggerMoveNode,
    typeCopyNodeTree,
    typePasteToNode
} from '../constants';
import uuid from 'uuid/v1';

export const setActiveNode = payload => ({
    type: typeActiveNode,
    payload
});

export const addChildNode = payload => ({
    type: typeAddChildNode,
    payload
});

export const addNodeAbove = payload => ({
    type: typeAddNodeAbove,
    payload
});

export const addNodeBelow = payload => ({
    type: typeAddNodeBelow,
    payload
});

export const moveNode = payload => ({
    type: typeMoveNode,
    payload
});

export const triggerMoveNode = payload => ({
    type: typeTriggerMoveNode,
    payload
});

function copyNodes(obj, ids, nodes) {
    return ids.map(id => {
        const newId = uuid();
        obj[newId] = {
            ...nodes[id],
            id: newId,
            childIds: copyNodes(obj, nodes[id].childIds, nodes)
        };
        return newId;
    });
}

export const copyNodeTree = id => (dispatch, getState) => {
    const { nodes } = getState();
    const copy = {};
    const [newId] = copyNodes(copy, [id], nodes);
    dispatch({
        type: typeCopyNodeTree,
        payload: {
            nodes: copy,
            root: newId
        }
    });
};

export const pasteToNode = toId => (dispatch, getState) => {
    const { skill } = getState();
    if (!skill.copyTree) {
        return;
    }
    dispatch({
        type: typePasteToNode,
        payload: {
            toId,
            ...skill.copyTree
        }
    });
};

export const deleteNode = payload => ({
    type: typeDeleteNode,
    payload
});

const transformContext = d => {
    const _d = {};
    if (d.length) {
        d.forEach(el => {
            _d[el.key] = el.value;
        });
    }
    return _d;
};

export const updateNode = (output, cb) => ((dispatch, getState) => {
    const {skill, forms} = getState();
    if (skill.currentNode) {
        const node = forms.skillNode;
        const data = {
            title: node.title,
            conditions: node.conditions,
            actions: node.actions,
            context: transformContext(node.context),
            output: output || node.output,
            skipUserInput: node.skipUserInput || false,
            jumpTo: node.jumpTo || null
        };
        dispatch({
            type: typeUpdateNode,
            payload: {
                id: skill.currentNode,
                data
            }
        });
    } 
    cb();
});