import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { saveSkill } from '../../actions/skill';
import { updateNode } from '../../actions/node';
import SubHeaderActions from '../../components/SubHeader/Actions';
import { Switch, Route } from 'react-router-dom';

function Actions({syncRef, updateNode, submit, ...props}) {
    const _submit = useCallback(() => {
        let output = null;
        if (syncRef && syncRef.current && syncRef.current.getNodeOutput) {
            output = syncRef.current.getNodeOutput();
        }
        updateNode(output, () => {
            syncRef.current && syncRef.current.submit ? syncRef.current.submit() : submit();
        });
    }, [syncRef, submit, updateNode]);

    const publish = useCallback(() => {

        console.log('!!!!! publish');

    }, []);

    return (
        <Switch>
            <Route exact path="/skills/:skillId/publish" component={() => <SubHeaderActions 
                {...props}
                submitText="Publish new version"
                submit={publish}
            />} />
		    <Route path="/skills/:skillId" component={() => <SubHeaderActions 
                {...props}
                submit={_submit}
            />} />
		</Switch>
    )  
}

export default connect(
    state => ({
        loading: state.skill.loading,
        back: state.skill.currentNode ? `/skills/${state.skill.id}` : "/skills",
        submitText: state.skill.id ? null : 'Save Skill'
    }),
    {
        submit: saveSkill,
        updateNode
    }
)(Actions);