import React, { memo, useCallback, useMemo} from 'react';
import { connect } from 'react-redux';
import * as MySwal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ChatMessages from '../../components/ChatMessages';
import PulseLoader from '../../components/PulseLoader';
import api from '@aws-amplify/api';
import UserCard from '../../components/UserCard';
import Widget12, { Section, Item } from '../../components/Widget12';
import moment from 'moment';
import SkillStatus from './SkillStatus';
import './SkillDetails.sass';

const Swal = withReactContent(MySwal);

const swalOpt = {
    customClass: {
        container: "custom-modal analytics-details-modal",
    },
    showConfirmButton: false,
    showCloseButton: true,
    width: 700,
};

const Details = memo(({data}) => {
    const { 
        status, 
        skill = {}, 
        skillDeleted = {}, 
        date, messages 
    } = data;
    const { messagesCount, user, allMessages} = useMemo(() => {
        let messagesCount = 0;
        let _user = null;
        const allMessages = messages.map(({isHala, user, date, data}) => {
            if (!_user && !isHala) _user = user;
            if (!isHala) messagesCount++;
            data = JSON.parse(data);
            if (isHala) {
                data = data.map((e,k) => {
                    let str = '';
                    switch(e.type) {
                        case 'text':
                            str = e.value;
                            break;
                        default:
                            str = `Preview of "${e.type}" type of content is in progress`;
                            break;
                    }
                    return <span key={k}>{str} <br/></span>;
                });
            } else data = data.text;

            return {
                date: date,
                firstName: isHala ? 'Hala': user.firstName,
                lastName: isHala ? '' : user.lastName,
                isRes: isHala,
                content: data
            };
        });
        return {
            messagesCount, user: _user, allMessages
        };
    }, [messages]);

    return (
        <React.Fragment>
            <Widget12>
                <Section>
                    <Item desc="Skill Name">{skill.name || skillDeleted.name}</Item>
                    <Item desc="Status"><SkillStatus val={status}/></Item>
                </Section>
                <Section>
                    <Item desc="By User"><UserCard size="sm" {...user}/></Item>
                    <Item desc="Invocation date">{ moment(date).format('llll') }</Item>
                    <Item desc="Messages from user">{messagesCount}</Item>
                </Section>
            </Widget12>
            <ChatMessages data={allMessages}/>
        </React.Fragment>
    )
});

async function showDetailsModeal(id) {
    try {
        Swal.fire({
            html: <PulseLoader className="page-loader" width="32"/>,
            ...swalOpt
        });
        const data = await api.get('api', `/analytics/skills/${id}`);
        Swal.fire({
            html: <Details data={data}/>,
            animation: false,
            ...swalOpt
        });
    } catch (err) {
        Swal.fire({
            toast: true,
            type: 'error',
            title: err.message,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        });
    }
}

function SkillDetails({id}) {
    const onClick = useCallback(() => showDetailsModeal(id), [id]);
    return (
        <div 
            className="kt-link kt-font-boldest"
            onClick={onClick} 
        >
            Details
        </div>
    )
}

export default connect(
    (state, {id}) => ({
        id: state.analytics.skills[id]._id
    }),
    {
    }
)(SkillDetails);