import {
    typeFilterUsers,
    userListGoToPage,
    userListPageSize,
    fetchUserInfo,
    fetchUsers,
    saveNewUserDetails,
    typeDeleteUser,
    typeCreateUser,
    typeUpdateUser
} from '../constants';
import { apiAction } from './api';

export const filterUsers = payload => ({
    type: typeFilterUsers,
    payload
});

export const setPageSize = payload => ({
    type: userListPageSize,
    payload
});

export const goToPage = payload => ({
    type: userListGoToPage,
    payload
}); 

export const getUsers = payload => apiAction({
    path: `/users`,
    name: fetchUsers,
    qs: payload
});

export const getUserInfo = () => apiAction({    
    path: `/users/current`,
    name: fetchUserInfo
});

export const submitNewUserDetails = data => apiAction({    
    path: `/users/current`,
    name: saveNewUserDetails,
    method: 'post',
    data   
});

export const deleteUser = id => apiAction({
    path: `/users/${id}`,
    method: 'del',
    name: typeDeleteUser,
    payload: {
        id
    }
});

export const createUser = ({...props}) => apiAction({
    path: `/users/`,
    name: typeCreateUser,
    method: 'put',
    ...props
});

export const updateUser = (id, {...props}) => apiAction({
    path: `/users/${id}`,
    method: 'put',
    name: typeUpdateUser,
    payload: {
        id
    },
    ...props
});