import React, { useCallback  } from 'react';
import { PropTypes } from 'prop-types';
import Portlet from '../../components/Portlet';
import ItemDropdown from './ItemDropdown';
import NodeItemTitle from './NodeItemTitle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MoveNodeActions from './MoveNodeActions';

function preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
}

function NodeItem({
    id, skillId, goTo, 
    titlePrefix, moveNodeId
}) {
    const onClick = useCallback(e => {
        preventDefault(e);
        goTo(`/skills/${skillId}/node/${id}`);
    }, [id, skillId, goTo]);

    return (
        <Portlet 
            onClick={moveNodeId ? null : onClick}
            titleIcon={titlePrefix}
            title={
                <React.Fragment>
                    {
                        moveNodeId
                        ? <MoveNodeActions id={id}/>
                        : null
                    }
                    <NodeItemTitle id={id}/>
                </React.Fragment>
            }
            toolbar={moveNodeId ? null : <ItemDropdown onClick={preventDefault} id={id}/>}
        />
    )
}

NodeItem.propTypes = {
    id: PropTypes.string.isRequired
};

const comp = connect(
    state => ({
        skillId: state.skill.id,
        moveNodeId: state.skill.moveNode
    }),
    {},
    ({skillId, moveNodeId}, disp, {id,history, titlePrefix}) => ({
        skillId,
        id,
        goTo: history.push,
        titlePrefix,
        moveNodeId
    })
)(NodeItem);

export default withRouter(comp);