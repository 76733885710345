import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

function ActionButton({
    action,
    children,
    className,
    loading,
    ...props
}) {
    const _className = useMemo(() => {
        return classnames("btn", className, {
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light active': loading
        });
    }, [className, loading]);

    if (typeof action === 'string') {
        return <Link {...props} className={_className} to={action}>{children}</Link>;
    }
    return <button {...props} onClick={loading ? null : action} type="button" className={_className}>{children}</button>
}

export default memo(ActionButton);