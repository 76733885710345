import React, { memo } from 'react';
import Logger from '../../utils/logger';
import HeaderMenuLink from './HeaderMenuLink';

const log = Logger('HeaderMenu');

function El({items}) {
    log.debug('render');
    return (
        <div className="kt-header-menu-wrapper">
            <div className="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default ">
                <ul className="kt-menu__nav">
                    {
                        items.map((i, key) => (
                            <HeaderMenuLink 
                                key={key}
                                link={i.link}
                                title={i.title}
                            />
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default memo(El);