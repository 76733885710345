import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { createAction, updateAction } from '../../actions/actions';
import { getActionById } from '../../reducers/actions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ActionForm from '../../components/Forms/Action';

function FormWrap({
    formRef, id, integrations,
    create, update, values, type
}) {
    const { push } = useHistory();
    const { url } = useRouteMatch();
    const onSubmit = useCallback(data => {
        data.integration = data.integration.value;
        const req = {
            data,
            onSuccess: () => push(`/${url.split('/')[1]}`)
        };
        if (id && id !== 'new') update(id, req);
        else create(req);
    }, [id, push, create, update, url]);

    const _integrations = useMemo(() => integrations.map(({id,name, type, auth}) => ({
        value: id,
        label: name,
        type,
        baseUrl: auth.baseUrl
    })), [integrations]);

    if (values && values.integration) {
        values.integration = _integrations.find(el => el.value === values.integration);
    } 

    if (type === 'quickbooks') {
        return (
            <div style={{
                padding: '10rem'
            }}>
                Sorry, You can't edit this action! <span role="img" aria-label="hi">&#128513;</span>
            </div>
        )
    }

    return <ActionForm 
        forwardedRef={formRef}
        onSubmit={onSubmit}
        values={values}
        integrations={_integrations}
    ></ActionForm>
}

export default connect(
    (state, props) => {
        let values = null;
        let type = null;
        if (props.id !== 'new') {
            const item = getActionById(state, props);
            if (item) {
                values = {
                    name: item.name,
                    integration: item.integration,
                    ...item.data
                };
                type = item.type;
            }
        }
        return {
            values,
            type,
            integrations: state.integrations
        };
    },
    {
        create: createAction,
        update: updateAction   
    }
)(FormWrap);
