import React, { memo } from 'react';
import './OptionalLabel.sass';

function OptionalLabel({children}) {
    return (
        <React.Fragment>
            <span className="optional-label">(optional) </span>
            { children }
        </React.Fragment>
    )
}

export default memo(OptionalLabel);


