import React, { memo } from 'react';

function TableBodyRedux({ total, columns }) {
    return (
        <tbody className="kt-datatable__body">
            {
                Array.from({ length: total }, (el, stateId) => (
                    <tr key={stateId} className="kt-datatable__row">
                        {
                            columns.map(({Cell, width}, key) => (
                                <td key={key} style={{width}} className="kt-datatable__cell">
                                    {
                                        typeof Cell === 'string'
                                        ? <span>{Cell}</span>
                                        : <Cell id={stateId}/>
                                    }
                                </td>
                            ))
                        }
                    </tr>
                ))
            }
        </tbody>
    )
}

export default memo(TableBodyRedux);