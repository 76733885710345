import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { currentEntity } from '../../actions/entity';
import KTNotificationItem from '../../components/KTNotificationItem';
import EntityName from '../../components/EntityName';

const log = Logger('EntitiesListItem');

function EntitiesListItem({
    name, 
    active, 
    id, 
    setCurrent
}) {
    log.debug('render');

    const onClick = useCallback(() => {
        setCurrent(id);
    }, [id, setCurrent]);

    return (
        <KTNotificationItem 
            onClick={onClick}
            name={<EntityName name={name} />}
            active={active}
        />
    )
}

EntitiesListItem.propTypes = {
    name: PropTypes.string,
    active: PropTypes.bool.isRequired,
    setCurrent: PropTypes.func.isRequired
};

export default connect(
    (state, {id}) => {
        const item = state.entities[id];
        return {
            name: item.name,
            active: id === state.currentEntity
        };
    },
    {
        setCurrent: currentEntity
    }
)(EntitiesListItem);