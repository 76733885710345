import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import NotificationList from '../../components/Portlet/NotificationList';
import IntentsListItem from './IntentsListItem';

function IntentsList({items}) {
    return <NotificationList 
        className="scroll"
        ids={Object.keys(items)} 
        Item={IntentsListItem} 
    />  
}

IntentsList.propTypes = {
    items: PropTypes.object.isRequired
};

export default connect(
    state => ({
        items: state.intents
    }),
    {}
)(IntentsList);