import { connect } from 'react-redux';
import Pagination from '../../components/Pagination';
import {
    setSkillsPageSize, goToSkillsPage
} from '../../actions/analytics';

export default connect(
    state => ({
        currentPage: state.analytics.skillsFilter.currentPage,
        total: state.analytics.skillsFilter.total,
        pageSize: state.analytics.skillsFilter.pageSize
    }),
    {
        setPageSize: setSkillsPageSize,
        goToPage: goToSkillsPage
    }
)(Pagination);