import {
    Authenticator, 
    ConfirmSignIn,
    RequireNewPassword,
    VerifyContact,
    ForgotPassword,
    TOTPSetup
} from 'aws-amplify-react';
import Router from '../Router';
import React, { useState, useCallback, useMemo } from 'react';
import AuthLayout from '../../components/AuthLayout';
import SignIn from '../../components/AuthLayout/SignIn';
import SignUp from '../../components/AuthLayout/SignUp';
import ConfirmSignUp from '../../components/AuthLayout/ConfirmSignUp';
import Auth from '@aws-amplify/auth';
import api from '@aws-amplify/api';
import { useLocation } from 'react-router-dom';
import { storageKey } from '../../components/WelcomeMessage';
import Analytics from '@aws-amplify/analytics';
import { updateEndpoint } from '../../utils/analytics';

export default function() {
    const [loading, setLoading] = useState(false);
    const [isSigned, setSigned] = useState(false);
    const [, setAuth] = useState(null);
    const {pathname} = useLocation();
    const isSignUp = pathname === '/sign-up';

    const _signIn = useCallback(async u => {
        setLoading(true);
        try{
            window.localStorage.setItem(storageKey, true);
            const user = await Auth.signIn(u.username, u.password);
            await api.post('api', '/users/current', {
                body: {
                    firstName: u.firstName,
                    lastName: u.lastName,
                    terms_and_conditions: u.terms_and_conditions,
                    newsletters: u.newsletters
                }
            });
            updateEndpoint({
                email: user.attributes.email,
                sub: user.username
            });
            Analytics.record({
                name: 'NewUserSuccess',
                attributes: { 
                    sub: user.username
                }
            });
            setSigned(true);
        } catch (err) {
            console.error(err);
            Analytics.record({
                name: 'NewUserError',
                attributes: { 
                    error: err.message
                }
            });
        }
        setLoading(false);
    }, []);

    const onStateChange = useCallback((state, data) => {
        Analytics.record({
            name: 'AuthStateChange',
            attributes: { 
                state
            }
        });
        switch(state) {
            case 'confirmSignUp':
                setAuth(data);
                break;
            case 'signedUp': {
                let d = {};
                setAuth(st => {
                    if (!st) return st; 
                    d = {...st};
                    return null;
                });
                if (d) _signIn(d);
                break;
            }
            case 'signedIn':
                setSigned(true);
                updateEndpoint({
                    email: data.attributes.email,
                    sub: data.username
                });
                Analytics.record({
                    name: 'SignedIn',
                    attributes: { 
                        sub: data.username
                    }
                });
                break;
            case 'signIn': 
                setSigned(false);
                break;
            default:
                break;
        }
    }, [_signIn]);

    const errorMessage = useCallback(message => {
        Analytics.record({
            name: 'AuthError',
            attributes: { 
                error: message
            }
        });
        return message;
    }, []);

    const _authenticator = useMemo(() => (
        <Authenticator
            hideDefault={true}
            onStateChange={onStateChange} 
            errorMessage={errorMessage}
            authState={isSignUp ? 'signUp' : null}
        >
            <SignIn/>
            <SignUp signUpConfig={{
                hideAllDefaults: true,
            }}/>
            <ConfirmSignIn/>
            <RequireNewPassword/>
            <ConfirmSignUp/>
            <VerifyContact/>
            <ForgotPassword/>
            <TOTPSetup/>
        </Authenticator>
    ), [onStateChange, errorMessage, isSignUp]);

    const auth = useMemo(() => (
        <AuthLayout 
            loading={loading}
        >
            { 
                loading 
                ? null 
                : _authenticator
            }
        </AuthLayout>
    ), [loading, _authenticator]);

    if (isSigned) {
        return <Router/>;
    }

    return auth
}