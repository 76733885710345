import {
    fetchUsersAnalyticsSuccess
} from '../../constants';

export default function (state = [], {type, payload}){
    switch (type) {
        case fetchUsersAnalyticsSuccess:
            return payload.data.users;
        default:
            return state;
    }
}