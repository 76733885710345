import React, {useCallback} from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import DeleteIconConfirmation from '../../components/DeleteIconConfirmation';
import { deleteEntityValue } from '../../actions/entity';

const log = Logger('EntityValueActions');

function EntityValueActions({id, deleteEntityValue, entity}) {
    log.debug('render');
    const onYes = useCallback(() => deleteEntityValue(id, entity), [deleteEntityValue, id, entity]);
    return (
        <DeleteIconConfirmation
            onYes={onYes} 
            id={id}
        />
    )
}

EntityValueActions.propTypes = {
    id: PropTypes.string.isRequired,
    deleteEntityValue: PropTypes.func.isRequired
};

export default connect(
    state => ({
        entity: state.currentEntity
    }),
    {
        deleteEntityValue
    }
)(EntityValueActions);