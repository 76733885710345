import React, { memo, useCallback } from 'react';
import FormGroup from '../components/FormGroup';
import TagsInput from 'react-tagsinput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

function defaultRenderTag (props) {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
    return (
        <span key={key} {...other} className="btn btn-label-primary btn-sm kt-margin-r-10 kt-margin-b-10">
            {getTagDisplayValue(tag)}
            {!disabled && <FontAwesomeIcon icon={faTimes} onClick={(e) => onRemove(key)} />}
        </span>
    )
}

function FormTagsInput({ 
    field, 
    form: {
        errors,
        setFieldValue
    },
    label,
    sensitive,
    description,
    onChange,
    error,
    ...props
}) {
    const _error = error || errors[field.name];
    const fieldName = field.name;
    const _onChange = useCallback(value => {
        setFieldValue(fieldName, value);
        if (onChange) onChange(value);
    }, [fieldName,setFieldValue, onChange]);
    return (
        <FormGroup 
            label={label}
            error={_error}
            description={description}
        >
            <TagsInput 
                {...field}
                renderTag={defaultRenderTag}
                {...props}
                onChange={_onChange}
            />
        </FormGroup>
    )
}

export default memo(FormTagsInput);