import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { 
    createIntegration, updateIntegration 
} from '../../actions/integrations';
import { getIntegrationSelector } from '../../reducers/integrations';
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import RestIntegration from '../../components/Forms/Rest';
import ServiceNowIntegration from '../../components/Forms/ServiceNowIntegration';
import SapIntegration from '../../components/Forms/SapIntegration';
import AWSIntegration from '../../components/Forms/AWSIntegration';
import IBMWatsonIntegration from '../../components/Forms/IBMWatsonIntegration';
import IBMSecureGateway from '../../components/Forms/IBMSecureGateway';

function FormWrap({
    formRef, name, id,
    create, update, values
}) {
    const { push } = useHistory();
    const { url } = useRouteMatch();
    
    const onSubmit = useCallback(data => {
        const req = {
            data,
            onSuccess: () => push(`/${url.split('/')[1]}`)
        };
        if (id) update(id, req);
        else create(req);
    }, [id, push, create, update, url]);
    
    let Comp = null;
    switch(name) {
        case 'ibm-sg':
            Comp = IBMSecureGateway;
            break;
        case 'rest-api':
            Comp = RestIntegration;
            break;
        case 'servicenow': 
            Comp = ServiceNowIntegration;
            break;
        case 'sap': 
            Comp = SapIntegration;
            break;
        case 'aws': 
            Comp = AWSIntegration;
            break;
        case 'ibm-watson':
            Comp = IBMWatsonIntegration;
            break;
        default:
            return <Redirect to="/404"/>;
    }
    return <Comp 
        forwardedRef={formRef}
        onSubmit={onSubmit}
        values={values}
    />;
}

export default connect(
    (state, {id}) => {
        const item = getIntegrationSelector(state, {id});
        let values = null;
        if (item) {
            values = {
                name: item.name,
                ...item.auth
            };
        }
        return {
            values
        };
    },
    {
        create: createIntegration,
        update: updateIntegration   
    }
)(FormWrap);
