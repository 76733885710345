import React, { memo, useCallback, useMemo } from 'react';
import Select from 'react-select';
import isArray from 'lodash/isArray';
import classnames from 'classnames';
import './Select2.sass';

const styles = {
    multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, paddingRight: 6 }
          : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
};

function Select2({
    convertToValues,
    value,
    options,
    onChange,
    className,
    ...props
}) {

    const isMulti = props.isMulti;
    const _onChange = useCallback((data, { action, removedValue }) => {
        if (isMulti) switch (action) {
            case 'remove-value':
            case 'pop-value':
                if (removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                data = data.filter(v => v.isFixed);
                break;
            default:
                break;
        }
        if (convertToValues && data) {
            if (isArray(data)) {
                data = data.map(el => el.value);
            } else data = data.value;
        }
        if (onChange) onChange(data);
    }, [onChange, convertToValues, isMulti]);

    const _value = useMemo(() => {
        let val = value;
        if (convertToValues && value) {
            if (isArray(value)) {
                val = value.map(v => options.find(el => el.value === v));
            } else val = options.find(el => el.value === value);
        }
        if (isMulti) val = [
            ...options.filter(v => v.isFixed),
            ...(val ? val.filter(v => !v.isFixed) : [])
        ];
        return val;
    }, [convertToValues, value, options, isMulti]);

    return (
        <Select
            className={classnames('select2', className)}
            classNamePrefix="select2"
            onChange={_onChange}
            options={options}
            value={_value}
            styles={styles}
            {...props}
        />
    )
}

export default memo(Select2);