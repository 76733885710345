import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { deleteEntitySynonym } from '../../actions/entity';
import { ReactComponent as Close } from '../../icons/Close.svg';
import Icon from '../../components/Icon';

const log = Logger('SynonymsList');

function SynonymsList({synonyms, id, deleteEntitySynonym, entity}) {
    log.debug('render');
    return synonyms.map((el, key) => (
        <span key={key} className="btn btn-bold btn-sm btn-font-sm btn-label-brand">
            {el}
            <Icon onClick={() => deleteEntitySynonym(id, key, entity)}><Close/></Icon>
        </span>
    ))
}

SynonymsList.propTypes = {
    synonyms: PropTypes.array.isRequired,
    deleteEntitySynonym: PropTypes.func.isRequired
};

export default connect(
    (state, {id}) => ({
        synonyms: state.entities[state.currentEntity].items[id].synonyms,
        entity: state.currentEntity
    }),
    {
        deleteEntitySynonym
    }   
)(SynonymsList);