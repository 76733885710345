import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import Amplify from '@aws-amplify/core';
import aws_exports from './aws-exports';
import { updateEndpoint } from './utils/analytics';
import './icons/flaticon2/flaticon.css';
import 'animate.css/source/attention_seekers/tada.css';
import 'animate.css/source/_base.css';
import './index.sass';
import './metronic/sass/theme/demos/demo1/header/skins/base/light.scss';
import './metronic/sass/theme/demos/demo1/aside/skins/dark.scss';
import Root from './containers/Root';
import './index.css';
import './tailwind.css';

Amplify.configure(aws_exports);
updateEndpoint();

const logger = new Amplify.Logger('Bootstrap');
export const store = configureStore();

if (process.env.NODE_ENV === 'production') {

} else {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
    logger.debug('development environment');
}

render(
    <Root store={store} />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
