import React from 'react';
import { faListUl } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createBlockStyleButton } from 'draft-js-buttons';

export default createBlockStyleButton({
    blockType: 'unordered-list-item',
    children: (
        <FontAwesomeIcon icon={faListUl} />
    )
});