import { connect } from 'react-redux';

export const userPropsByListKey = cb => connect(
    (state, { id }) => cb(state.users.list[id] || {}),
    {}
);

export const stateProps = cb => connect(
    state => cb(state.users),
    {}
);