import React, { 
    memo
} from 'react';
import { Formik, Field, FastField } from 'formik';
import FSchema from '../formik/Schema';
import { required } from '../validators';
import { useFormRef, useFormValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import Wrap from '../components/FormWrap';
import FSelect from '../formik/Select';
import FInput from '../formik/Input';

const initial = [{
    key: 'service',
    val: '',
    validate: required('Service')
}, {
    key: 'method',
    val: '',
    validate: required('Method')
}, {
    key: 'actionType',
    val: 'aws',
    validate: required('Action Type')
}, {
    key: 'params',
    val: {},
    validate: Joi.object()
}, {
    key: 'region',
    val: '',
    validate: Joi.any()
}];

const awsServices = [{
    value: 'ses',
    label: 'Amazon Simple Email Service',
    methods: [{
        value: 'sendTemplatedEmail',
        label: 'Send Templated Email',
        params: {
            Destination: {
                ToAddresses: ['to@email']
            },
            Source: `Signature <from@email>`,
            Template: '',
            TemplateData: {
                confirmation: false
            }
        }
    }]
}, {
    value: 'lambda',
    label: 'AWS Lambda',
    methods: [{
        value: 'invoke',
        label: 'Invokes a Lambda function',
        params: {
            FunctionName: "",
            Payload: {}
        }
    },{
        value: 'invokeAsync',
        label: 'Invokes a function asynchronously',
        params: {
            FunctionName: "",
            InvokeArgs: {}
        }
    }]
}, {
    value: 'textract',
    label: 'Amazon Textract',
    methods: [{
        value: 'detectDocumentText',
        label: 'Detects text in the input document.',
        params: {
            FileKey: ''
        }
    }]
}, {
    value: 'lex',
    label: 'Amazon Lex',
    methods: [{
        value: 'postText',
        label: 'Sends user input to Amazon Lex.',
        params: {
            botAlias: '',
            botName: '',
            inputText: '{{input.text}}',
            userId: '{{user.id}}'
        }
    }]
}];

function AWSForm({ 
    forwardedRef, 
    values, 
    nested
}) {

    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus, validateCb} = useFormValues(values, initial);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({values: {service, method}}) => (
                <Wrap nested={nested}>
                    <Field 
                        name="service" 
                        component={FSelect} 
                        label="AWS Service"
                        autoFocus={autoFocus}
                        description="Select AWS Service name"
                        convertToValues
                        options={awsServices}
                    />
                    {
                        service
                        ? (() => {
                            const _service = awsServices.find(({value}) => value === service);
                            let _method = method && _service 
                                ? _service.methods.find(({value}) => value === method) 
                                : null;
                            return (
                                <React.Fragment>
                                    <FastField 
                                        name="region" 
                                        label="Service Region"
                                        description="Optional: Specify AWS Cloud Region"
                                        component={FInput}
                                    />
                                    <Field 
                                        name="method" 
                                        component={FSelect} 
                                        label="Service Method"
                                        convertToValues
                                        description={`Select ${_service.label} method`}
                                        options={_service.methods}
                                    />
                                    {
                                        _method
                                        ? (
                                            <FastField 
                                                name="params" 
                                                label="Method Parameters"
                                                description={`${_method.label} request parameters`}
                                                component={FSchema}
                                                initialValue={_method.params}
                                                expand
                                            />
                                        ) : null
                                    }
                                </React.Fragment>
                            )
                        })() : null
                    }
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(AWSForm);