import React, {memo, useCallback, useLayoutEffect} from 'react';
import FormGroup from '../components/FormGroup';
import classnames from 'classnames';
import SchemaEditor from '../../SchemaEditor';

function Schema({
    field, 
    form: {
        errors,
        setFieldValue
    },
    label,
    sensitive,
    description,
    onChange,
    initialValue,
    ...props
}) {
    const fieldName = field.name;
    const _onChange = useCallback(value => {
        setFieldValue(fieldName, value);
        if (onChange) onChange(value);
    }, [setFieldValue, onChange, fieldName]);

    useLayoutEffect(() => {
        if (initialValue) {
            setFieldValue(fieldName, initialValue);
        }
    }, [initialValue, setFieldValue, fieldName]);

    return (
        <FormGroup 
            label={label}
            error={errors[field.name]}
        >
            <SchemaEditor 
                className={classnames({
                    'is-invalid': errors[field.name]
                })}
                label={description}
                {...field}
                {...props}
                onChange={_onChange}
            />
        </FormGroup>
    )
}

export default memo(Schema);