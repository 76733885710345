import React from 'react';
import createStore from './store';
import Toolbar from './Toolbar';

export default () => {
    const store = createStore({});
    const StaticToolbar = props => (
        <Toolbar {...props} store={store}/>
    );

    return {
        initialize: ({ getEditorState, setEditorState }) => {
            store.updateItem('getEditorState', getEditorState);
            store.updateItem('setEditorState', setEditorState);
        },
        onChange: editorState => {
            store.updateItem('selection', editorState.getSelection());
            store.updateItem('getEditorState', () => editorState);
            return editorState;
        },
        Toolbar: StaticToolbar,
    };
};