import React, { useEffect, useCallback, useState } from 'react';
import Logger from '../../utils/logger';
import EntityDropdown from './EntityDropdown';
import colorHash from 'material-color-hash';
import { connect } from 'react-redux';
import { addValueToEntity } from '../../actions/entity';
import './Entity.sass';

const log = Logger('Entity');

export function getEntityColorByID(n) {
    return colorHash(`entity${n}`, 100);
}

function Entity({
    children, current, entityKey, 
    contentState, onClose, decoratedText,
    addValueToEntity
}) {
    log.debug('render');
    const { entity } = contentState.getEntity(entityKey).getData();
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (typeof entity === 'undefined') {
            contentState.mergeEntityData(
                entityKey,
                { 
                    entity: null
                }
            );
            setShow(true);
        }
    }, [entityKey, contentState, entity]);

    const isActive = current && current === entityKey;

    useEffect(() => {
        if (isActive) {
            const tId = setTimeout(() => {
                setShow(true);
            }, 1);
            return () => clearTimeout(tId);
        }
    }, [isActive]);

    const close = useCallback(() => {
        if (show) {
            setShow(false);
            onClose && onClose();
        }
    }, [show, onClose]);

    const onSelect = useCallback(item => {
        contentState.mergeEntityData(
            entityKey,
            { 
                entity: item ? item.id : item
            }
        );
        if (item && item.id) {
            addValueToEntity(item.id, decoratedText);
        }
        close();
    }, [contentState, entityKey, close, addValueToEntity, decoratedText]);

    return (
        <span 
            className="intents-entity"
        >
            <span className="entity" style={{
                backgroundColor: getEntityColorByID(entityKey).backgroundColor
            }}>
                { children }
            </span>
            <span className="intents-entity-dropdown">
                <EntityDropdown
                    onSelect={onSelect}
                    value={entity}
                    onToggle={close}
                    entityText={decoratedText}
                    show={show}
                />
            </span>
        </span>
    )
}

export default connect(
    null,
    {addValueToEntity}
)(Entity); 