import React, { memo } from 'react';
import PageContent from '../PageContent';
import SubHeader from '../../components/SubHeader';
import RefreshInfo from './RefreshInfo';
import Dashboard from './Dashboard';
import Filter from './AnalyticsPeriod';
import SkillsList from './SkillsList';
import UsersList from './UsersList';
import { Switch, Route } from 'react-router-dom';

const Title = memo(() => (<Switch>
    <Route exact path="/dashboard" component={() => "Analytics"} />
    <Route exact path="/dashboard/skills" component={() => "Skills"} />
    <Route exact path="/dashboard/users" component={() => "Users"} />
</Switch>));

const subheader = <SubHeader 
    title={<Title/>}
    desc={<RefreshInfo/>}
    toolbar={<Filter/>}
/>;

function Analytics({match}) {
    const {path} = match;
    return (
        <PageContent subheader={subheader}>
            <Switch>
				<Route exact path={path} component={Dashboard} />
                <Route exact path={`${path}/skills`} component={SkillsList} />
                <Route exact path={`${path}/users`} component={UsersList} />
			</Switch>
        </PageContent>
    )
}

export default memo(Analytics);