
import {
    typeCurrentIntent,
    typeCurrentUtterance,
    typeUpdateUtterance,
    fetchTrainingModel,
    fetchTrainingModelSuccess
} from '../constants';


export default function currentUtterance(state = null, { type, payload }) {
    switch (type) {
        case typeCurrentUtterance:
            return payload;
        case typeCurrentIntent:
        case typeUpdateUtterance:
        case fetchTrainingModelSuccess:
        case fetchTrainingModel:
            return null;
        default:
            return state;
    }
}