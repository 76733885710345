import React, { memo } from 'react';
import Portlet from '../Portlet';

function PortletFormWrap({children}) {
    return (
        <Portlet
            bodyFit={true}
        >
            <div className="kt-grid  kt-wizard-v1 kt-wizard-v1--white">
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                    { children }
                </div>
            </div>
        </Portlet>
    )
}

export default memo(PortletFormWrap);