import React, {useMemo} from 'react';
import { connect } from 'react-redux';
import SubHeader from '../../components/SubHeader';
import Toolbar from '../../components/SubHeader/Actions';
import { actionNameSelector, actionTypeSelector } from '../../reducers/actions';

function MySubHeader({
    formRef, id, loading, name, type
}) {
    const isNew = id === 'new' ? true : false;
    const toolbar = useMemo(() => (
        <Toolbar 
            submit={type === 'quickbooks' ? null : () => formRef.current && formRef.current.submit()}
            loading={loading}
            submitText={isNew ? 'Save' : null}
        />
    ), [loading, formRef, isNew, type]);

    return (
        <SubHeader 
            toolbar={toolbar}
            title={isNew ? "New Action" : `Edit Action - ${name}`}
            desc={isNew ? 'Enter action details and Save': null}
        />
    );
}

export default connect(
    (state, props) => ({
        name: actionNameSelector(state, props),
        type: actionTypeSelector(state, props),
        loading: state.actionsState.loading
    }),
    {}
)(MySubHeader);