import React, {memo} from 'react';
import Item from './Item';
import Section from './Section';

function Widget12({children, ...props}) {
    return (
        <div {...props} className="kt-widget12">
            <div className="kt-widget12__content">
                {children}
            </div>
        </div>
    )
}

export default memo(Widget12);
export {
    Item,
    Section
};