import React, { memo } from 'react';
import Dropdown from '../Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import TruncateText from '../TruncateText';

function KTProject1({
    title,
    description,
    tags,
    toolbarMenu,
    onTitle
}) {
    return (
        <div className="kt-widget kt-widget--project-1">
            <div className="kt-widget__head d-flex">
                <div className="kt-widget__label">
                    <div className="kt-widget__info kt-padding-0">
                        <Link className="kt-widget__title" to={onTitle}>{title}</Link>
                        <div className="kt-widget__desc">
                            <TruncateText maxHeight={40}>
                                {description}
                            </TruncateText>
                        </div>
                    </div>
                </div>
                {
                    toolbarMenu
                    ? (
                        <Dropdown
                            className="kt-portlet__head-toolbar"
                            toggleClass="btn-icon"
                            alignRight
                            toggle={
                                <FontAwesomeIcon icon={faEllipsisV}/>
                            }
                        > 
                            { toolbarMenu }
                        </Dropdown>
                    ) : null
                }
            </div>
            {
                tags
                ? (
                    <div className="kt-widget__body">
                        <div className="kt-widget__stats">
                            <div className="kt-widget__item kt-padding-0">
                                <span className="kt-widget__date">Tags</span>
                                <div className="kt-widget__label tags">
                                    {
                                        tags.map((tag,key) => (
                                            <span key={key} className="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10 kt-margin-b-10">{tag}</span>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default memo(KTProject1);