import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import AppContainer from '../AppContainer';
import Logger from '../../utils/logger';
import { 
    BrowserRouter as Router
} from 'react-router-dom';

const log = Logger('Root');

function Root({ store }) {
    log.debug('render');
    return (
        <Provider store={store}>
            <Router>
                <AppContainer/>
            </Router>
        </Provider>
    );
}

Root.propTypes = {
    store: PropTypes.object.isRequired
}

export default Root;