import React, { useCallback } from 'react';
import FormGroup from '../components/FormGroup';
import classnames from 'classnames';
import { sensitiveString } from '../formik/hooks';
import InputGroup from '../../InputGroup';

function Input({
    field, 
    form: {
        errors
    },
    label,
    sensitive,
    description,
    onChange,
    error,
    ...props
}) {

    const isSensitive = field.value === sensitiveString;

    if (isSensitive) {
        field.type = 'password';
    }

    const onFocus = useCallback(e => {
        if (isSensitive) e.target.value = '';
    }, [isSensitive]);

    const onBlur = useCallback(e => {
        if (isSensitive && e.target.value === '') {
            e.target.value = sensitiveString;
        }
        field.onBlur(e);
    }, [isSensitive, field]);

    const _onChange = useCallback(e => {
        if (onChange) onChange(e);
        field.onChange(e);
    }, [field, onChange]);

    const _error = error || errors[field.name];

    return (
        <FormGroup 
            label={label}
            error={_error}
            description={description}
        >
            <InputGroup
                className={classnames({
                    'is-invalid': _error
                })}
                onFocus={onFocus}
                {...field}
                onBlur={onBlur}
                {...props}
                onChange={_onChange}
            />
        </FormGroup>
    )
}

export default Input;