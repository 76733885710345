import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import { ReactComponent as DoubleCheck } from '../../icons/DoubleCheck.svg';
import Icon from '../../components/Icon';
import { addEntitySynonym } from '../../actions/entity';

const log = Logger('AddSynonym');

function AddSynonym({id, addEntitySynonym, entity}) {
    log.debug('render');
    const [value, setValue] = useState('');
    const onChange = useCallback(e => setValue(e.target.value), []);
    const onAdd = useCallback((id,val) => addEntitySynonym(id,val, entity), [addEntitySynonym, entity]);
    const save = useCallback(() => {
        setValue(val => {
            val = val.trim();
            if (val) onAdd(id, val);
            return '';
        });
    }, [onAdd, id]);
    const handleKeyDown = useCallback(e => {
        if (e.key === 'Enter') {
            onChange(e);
            save();
        }
    }, [onChange, save]);
    return (
        <div className="add-synonym-wrap kt-input-icon kt-input-icon--right">
            <input 
                onKeyDown={handleKeyDown}
                autoFocus 
                onChange={onChange} 
                value={value} 
                type="text" 
                placeholder="Type Synonym Name"
                className="form-control" 
            />
            <span className="kt-input-icon__icon kt-input-icon__icon--right">
			    <Icon onClick={save}><DoubleCheck/></Icon>
			</span>
		</div>
    )
}

AddSynonym.propTypes = {
    id: PropTypes.string.isRequired,
    addEntitySynonym: PropTypes.func.isRequired
};

export default connect(
    state => ({
        entity: state.currentEntity
    }),
    {
        addEntitySynonym
    }
)(AddSynonym);