import {
    fetchSkillSuccess,
    typeCleanSkillData,
    typeAddChildNode,
    typeAddNodeAbove,
    typeAddNodeBelow,
    typeMoveNode,
    typeDeleteNode,
    typeUpdateNode,
    typePasteToNode
} from '../constants';
import uuid from 'uuid/v1';
import _isArray from 'lodash/isArray';
import { createSelector } from 'reselect';

function formatJson(str) {
    try {
        str = JSON.parse(str);
        // JSON.stringify(JSON.parse(str.replace(/\\w/g,'\\\\w')), null, 2);
    } catch (err) {}
    return str;
}

function formatOutput(str) {
    try {
        str = JSON.parse(str);
        if (_isArray(str)) {
            str =  JSON.stringify(str, null, 2);
        }
        // JSON.stringify(JSON.parse(str.replace(/\\w/g,'\\\\w')), null, 2);
    } catch (err) {}
    return str;
}

const getAllDescendantIds = (state, nodeId) => (
    state[nodeId].childIds.reduce((acc, childId) => (
      [ ...acc, childId, ...getAllDescendantIds(state, childId) ]
    ), [])
);

const deleteMany = (state, ids) => {
    ids.forEach(id => delete state[id]);
    return state;
};

const getParentById = (state, id) => {
    return Object.keys(state).find(_id => state[_id].childIds.includes(id));
};

const childIds = (state, {type, id, newId}) => {
    switch (type) {
        case typeAddChildNode:
            return [ ...state, id ];
        case typeDeleteNode:
            return state.filter(_id => id !== _id);
        case typeAddNodeAbove:
        case typeAddNodeBelow: {
            state.splice(state.indexOf(id) + (type === typeAddNodeBelow ? 1 : 0), 0, newId);
            return [...state];
        }
        default:
            return state;
    }
};

const node = (state, action) => {
    switch (action.type) {
        case typeDeleteNode:
        case typeAddChildNode: 
        case typeAddNodeAbove:
        case typeAddNodeBelow:
            return {
                ...state,
                childIds: childIds(state.childIds, action)
            };
        case typeUpdateNode:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};

const newNode = () => ({
    id: uuid(),
    childIds: []
});

const initState = {
    root: {
        id: 'root',
        childIds: []
    }
};

export const ROOT = 'root';

export default (state = initState, {type, payload}) => {
    switch (type) {
        case typeAddNodeAbove:
        case typeAddNodeBelow: {
            const _node = newNode();
            const parentId = getParentById(state, payload);
            return {
                ...state,
                [parentId]: node(state[parentId], {
                    type,
                    id: payload,
                    newId: _node.id
                }),
                [_node.id]: _node
            };
        }
        case typeAddChildNode: {
            const _node = newNode();
            const id = payload || 'root';
            return {
                ...state,
                [id]: node(state[id], {
                    type,
                    id: _node.id
                }),
                [_node.id]: _node
            };
        }
        case typeCleanSkillData:
            return {
                ...initState
            };
        case typePasteToNode: 
            state[payload.toId].childIds = [
                ...state[payload.toId].childIds,
                payload.root
            ];
            return {
                ...state,
                ...payload.nodes
            };
        case typeMoveNode: {
            const { id, moveNodeId, type } = payload;
            const nodes = Object.values(state);
            let {id: parent, childIds: childs} = 
                type === 'child'
                ? nodes.find(el => el.id === id)
                : nodes.find(el => el.childIds.includes(id));
            let {id: mParent, childIds: mChilds} = nodes.find(el => el.childIds.includes(moveNodeId));
            mChilds.splice(mChilds.indexOf(moveNodeId), 1);
            if (parent === mParent) childs = mChilds;
            let index = childs.indexOf(id);
            switch (type) {
                case 'above':
                    index = index - 1;
                    if (index < 0) index = 0;
                    childs.splice(index, 0, moveNodeId);
                    break;
                case 'below':
                    childs.splice(index + 1, 0, moveNodeId);
                    break;
                case 'child':
                    childs.push(moveNodeId);
                    break;
                default:
                    break;
            }
            return {
                ...state,
                [parent]: {
                    ...state[parent],
                    childIds: [...childs]
                },
                ...(() => {
                    if (parent === mParent) {
                        return {};
                    } 
                    return {
                        [mParent]: {
                            ...state[mParent],
                            childIds: [...mChilds]
                        }
                    };
                })()
            };
        }
        case typeDeleteNode: {
            const descendantIds = getAllDescendantIds(state, payload);
            state = deleteMany(state, [ payload, ...descendantIds ]);
            const parentId = getParentById(state, payload);
            return {
                ...state,
                [parentId]: node(state[parentId], {
                    type,
                    id: payload
                })
            };
        }
        case typeUpdateNode: 
            return {
                ...state,
                [payload.id]: node(state[payload.id], {
                    type,
                    data: payload.data
                })
            };
        case fetchSkillSuccess: {
            Object.keys(payload.data.nodes).forEach(id => {
                const el = payload.data.nodes[id];
                if (el.actions) el.actions = formatJson(el.actions);
                if (el.context) el.context = formatJson(el.context);
                if (el.output) el.output = formatOutput(el.output);
                payload.data.nodes[id] = el;
            });
            return {
                ...initState,
                ...payload.data.nodes
            };
        }
        default:
            return state;
    }
};

export const getNodes = state => state.nodes;
export const idFromProps = (_, props) => props.id;

export const getNodeById = createSelector(
    [getNodes, idFromProps],
    (nodes, id) => nodes[id]
);

export const getNodeName = createSelector(
    getNodeById,
    node => node && node.id ? node.title || ROOT.toUpperCase() : null
);

export const nodeHasChilds = createSelector(
    getNodeById,
    node => node && node.childIds && node.childIds.length ? true : false
);