import {
    fetchAnalyticsSuccess,
    fetchSkillsAnalyticsSuccess,
    fetchUsersAnalyticsSuccess
} from '../../constants';

export default function (state = '', {type, payload}){
    switch (type) {
        case fetchAnalyticsSuccess:
        case fetchSkillsAnalyticsSuccess:
        case fetchUsersAnalyticsSuccess:
            return payload.data.date;
        default:
            return state;
    }
}