import React, { 
    memo, useCallback, useRef
} from 'react';
import { Formik, FastField } from 'formik';
import FInput from '../formik/Input';
import FSelect from '../formik/Select';
import OptionalLabel from '../components/OptionalLabel';
import { validateSchema, required, jIsUrl, jError } from '../validators';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import AuthForm from './AuthForm';

const authTypes = [{
    value: 'noAuth',
    label: 'No Auth'
},{
    value: 'apiKey',
    label: 'API Key'
},{
    value: 'bearer',
    label: 'Bearer Token'
},{
    value: 'basic',
    label: 'Basic Auth'
},{
    value: 'oauth2',
    label: 'OAuth 2.0'
}];

const initialValues = { 
    name: '',
    baseUrl: '',
    type: 'rest-api',
    authType: authTypes[0].value
};

const validateCb = validateSchema(Joi.object({
    name: required('Integration Name'),
    authType: required('Authorization type'),
    baseUrl: Joi.any().custom(jIsUrl).error(jError()),
    type: Joi.any()
}));

const UrlDescription = (
    <OptionalLabel>
        API provider base URL. (Example: https://some-api.com/)
    </OptionalLabel>
)

function RestForm({ onSubmit, forwardedRef, values }) {
    const authRef = useRef();
    const ref = useFormRef(forwardedRef, [authRef]);
    const {vals, autoFocus} = useValues(values, initialValues);
    const _onSubmit = useCallback(() => {
        if (onSubmit) onSubmit(forwardedRef.current.getValues());
    }, [onSubmit, forwardedRef]);

    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmit}
        >
            {({values: {authType}}) => (
                <form className="kt-form kt-form--label-right">
                    <FastField 
                        name="name" 
                        autoFocus={autoFocus}
                        label="Integration Name"
                        description="Specify integration name"
                        component={FInput} 
                    />
                    <FastField 
                        name="baseUrl" 
                        label="Base URL"
                        description={UrlDescription}
                        component={FInput} 
                    />
                    <FastField 
                        name="authType" 
                        component={FSelect} 
                        label="Authorization"
                        description="Select authorization type"
                        options={authTypes}
                        convertToValues
                    />
                    <AuthForm 
                        forwardedRef={authRef} 
                        type={authType}
                        values={values}
                    />
                </form>
            )}
        </Formik>
    )
}

export default memo(RestForm);