import React, { memo } from 'react';
import { Formik, FastField } from 'formik';
import FInput from '../formik/Input';
import Wrap from '../components/FormWrap';
import { useFormRef, useValues } from '../formik/hooks';
import Joi from '@hapi/joi';
import { validateSchema, required } from '../validators';

const initialValues = { 
    username: '',
    password: ''
};

const validateCb = validateSchema(Joi.object({
    username: required('Username'),
    password: required('Password')
}));

function BasicAuthForm({
    onSubmit, forwardedRef, 
    values, nested
}) {
    const ref = useFormRef(forwardedRef);
    const {vals, autoFocus} = useValues(values, initialValues);
    return (
        <Formik
            ref={ref}
            initialValues={vals}
            validate={validateCb}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {() => (
                <Wrap nested={nested}>
                    <FastField 
                        name="username" 
                        label="Username"
                        autoFocus={autoFocus}
                        component={FInput} 
                        autoComplete="new-password"
                    />
                    <FastField 
                        name="password" 
                        label="Password"
                        type="password" 
                        component={FInput} 
                        autoComplete="new-password"
                    />
                </Wrap>
            )}
        </Formik>
    )
}

export default memo(BasicAuthForm);