import React, {useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Logger from '../../utils/logger';
import KTWidget1Item from '../../components/KTWidget1Item';
import { getEntityColorByID } from './Entity';
import { ReactComponent as Trash } from '../../icons/Trash.svg';
import { ReactComponent as Write } from '../../icons/Write.svg';
import Icon from '../../components/Icon';
import { deleteUtterance, setActiveUtterance } from '../../actions/intent';
import IntentEditor from './IntentEditor';
import EntityNameById from '../EntitiesPage/EntityNameById';

const log = Logger('Utterance');

function Utterance({
    id, 
    text, 
    entityMap = {},
    deleteItem,
    setActive,
    isActive,
    intent
}) {
    log.debug('render');

    const _text = useMemo(() => {
        var prev = 0;
        var keys = Object.keys(entityMap);
        var lng = keys.length - 1;

        if (!keys.length) {
            return text;
        }

        return (
            <React.Fragment>
                {
                    keys.map((key, n) => {
                        var res = [];
                        let d = entityMap[key].data;
                        res.push(<span key={`${n}-1`}>{text.substring(prev, d.start)}</span>);
                        res.push(<span key={`${n}-2`} style={{
                            backgroundColor: getEntityColorByID(n).backgroundColor
                        }} className={`utterance-entity`}>{text.substring(d.start, d.end)}</span>)
                        prev = d.end;
                        if (n === lng) {
                            res.push(<span key={`${n}-3`}>{text.substring(prev, text.length)}</span>);
                        }
                        return res;
                    })
                }
            </React.Fragment>
        )
    }, [text, entityMap]);

    const _desctiption = useMemo(() => {
        var keys = Object.keys(entityMap);
        if (!keys.length) {
            return null;
        }
        return (
            <React.Fragment>
                {
                    keys.map((key, n) => {
                        let d = entityMap[key].data;
                        return <span className="entity" key={n} style={{
                            backgroundColor: getEntityColorByID(n).backgroundColor
                        }}>
                            <EntityNameById id={d.entity} />
                        </span>
                    })
                }
            </React.Fragment>
        )
    }, [entityMap])

    const _setActive = useCallback(() => setActive(id), [id, setActive]);
    const _deleteItem = useCallback(() => deleteItem(id, intent), [id, deleteItem, intent]);

    const actions = useMemo(() => {
        return (
            <div className="utterance-action">
                <Icon onClick={_setActive}><Write/></Icon>
                <Icon onClick={_deleteItem}><Trash/></Icon>
            </div>
        );
    }, [_deleteItem, _setActive]);
    
    const Item = useMemo(() => (
        <KTWidget1Item 
            text={<span onClick={_setActive}>{_text}</span>}
            desctiption={_desctiption}
        >
            {actions}
        </KTWidget1Item>
    ), [_text, _desctiption, actions, _setActive]);

    return isActive ? <IntentEditor /> : Item;
}

Utterance.propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    entityMap: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired
};

export default connect(
    (state, {id}) => {
        const item = state.intents[state.currentIntent].items[id];
        return {
            id,
            text: item.data.blocks[0].text,
            entityMap: item.data.entityMap,
            isActive: state.currentUtterance === id,
            intent: state.currentIntent
        };
    },
    {
        deleteItem: deleteUtterance,
        setActive: setActiveUtterance
    }
)(Utterance);