import {
    apiFetchError,
    errorMessage,
    successMessage
} from '../constants';

const init = {
    type: null,
    message: null
};

export const types = {
    error: 'error',
    success: 'success'
};

export default function (state = init, { type, payload }){
    if (!payload) return state;
    switch (type) {
        case apiFetchError:
            return {
                type: types.error,
                message: payload.error
            };
        case errorMessage:
            return {
                type: types.error,
                message: payload
            };
        case successMessage:
            return {
                type: types.success,
                message: payload
            };
        default:
            return state;
    }
}