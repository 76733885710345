import {
    fetchAvailableIntegrations,
    typeCreateIntegration,
    typeUpdateIntegration,
    fetchIntegrations,
    typeDeleteIntegration
} from '../constants';
import { apiAction } from './api';

export function getAvailableIntegrations() {
    return apiAction({
        path: `/available-integrations`,
        name: fetchAvailableIntegrations
    });
}

export function getIntegrations() {
    return apiAction({
        path: `/integrations`,
        name: fetchIntegrations
    });
}

export function createIntegration(props) {
    return apiAction({
        path: `/integrations`,
        method: 'post',
        name: typeCreateIntegration,
        ...props
    });
}

export function updateIntegration(id, props) {
    return apiAction({
        path: `/integrations/${id}`,
        method: 'post',
        name: typeUpdateIntegration,
        ...props
    });
}

export function deleteIntegration(id) {
    return apiAction({
        path: `/integrations/${id}`,
        method: 'del',
        name: typeDeleteIntegration,
        payload: {
            id
        }
    });
}