import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import Portlet from '../../components/Portlet';
import LoaderBlock from '../../components/LoaderBlock';
import Table from '../../components/Table';
import TableBody from '../../components/Table/BodyRedux';
import Span from '../../components/Static/Span';
import UserCard from '../../components/UserCard';
import { fetchUsers } from '../../actions/analytics';

const Loader = connect(st => ({
    loading: st.analytics.loading
}))(LoaderBlock);

const propsByListKey = cb => connect(
    (state, { id }) => cb(state.analytics.users[id] || {}),
    {}
);

const TBody = connect(
    state => ({
        total: state.analytics.users.length
    }),
    null
)(TableBody);

const columns = [{
    Head: 'User name',
    width: '300px',
    Cell: propsByListKey(u => ({
        firstName: u.firstName,
        lastName: u.lastName
    }))(UserCard)
},{
    Head: 'Total messages',
    width: '100px',
    Cell: propsByListKey(u => ({
        string: u.total
    }))(Span)
}];

const _st = state => ({
    from: state.analytics.filters.from, 
    to: state.analytics.filters.to
});
const _dsp = { fetchUsers };
const Fetcher = connect(
    _st,_dsp
)(({fetchUsers, from, to}) => {
    useEffect(() => {
        fetchUsers({from, to});
    }, [fetchUsers, from, to]);
    return null;
});

function UsersList() {
    return (
        <Portlet
            className="table-type1"
            mobile={true}
            bodyFit={true}
        >
            <Fetcher/>
            <Table columns={columns}>
                <TBody columns={columns}/>
            </Table>
            <Loader/>
        </Portlet>
    )
}

export default memo(UsersList);