import React, { memo } from 'react';

function Table({ 
    columns,
    children
}) {
    return (
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
            <table className="kt-datatable__table">
                <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row">
                      {columns.map(({Head, width}, k) => (
                          <th key={k} style={{width}}className="kt-datatable__cell">
                          {
                              typeof Head === 'string'
                              ? <span>{Head}</span>
                              : <Head/>
                          }
                          </th>
                      ))}
                    </tr>
                </thead>
                { children }
            </table>
        </div>
    )
}

export default memo(Table);