import React, { memo } from 'react';
import classnames from 'classnames';

function LongText({ 
    className,
    error,
    forwardedRef,
    ...props
}) {
    return (
        <textarea 
            ref={forwardedRef}
            className={classnames('form-control', className, {
                'is-invalid': error
            })} 
            {...props}
        />
    )
}

export default memo(LongText);