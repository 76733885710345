import React, { memo } from 'react';
import Logger from '../../utils/logger';
import classnames from 'classnames';
import './index.sass';

const log = Logger('KTWidget1Item');

function KTWidget1Item({text, desctiption, children, className, ...props}) {
    log.debug('render');
    return (
        <div {...props} className={classnames("kt-widget1__item", className)}>
            <div className="kt-widget1__info">
                <div className="kt-widget1__title">
                    {text}
                </div>
                {
                    desctiption
                    ?(
                        <span className="kt-widget1__desc">
                            {desctiption}
                        </span>
                    )
                    : null
                }
            </div>
            { children }
        </div>
    )
}

export default memo(KTWidget1Item);