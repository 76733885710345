import React, { memo, useEffect, useState, useCallback } from 'react';
import api from '@aws-amplify/api';
import Portlet from '../../components/Portlet';
import KTWidget from '../../components/KTWidget';
import { Input } from 'antd';

const { TextArea } = Input;

function SkillPublish({skillId}) {
    const [data, setData] = useState({});
    const [notes, setNotes] = useState('');

    useEffect(() => {
        api.get('api', `/skills/${skillId}/publish`)
        .then(res => setData(res))
        .catch(err => {
            console.error('SkillPublish info :: ', err)
        });
    }, [skillId]);


    const onClick = useCallback(() => {


        api.post('api', `/skills/${skillId}/publish`, {
            body: { notes }
        });

        
    }, [skillId, notes]);

    return (
        <Portlet>
            <KTWidget 
                type="user-profile-3"
                title={data.name}
                actions={<button type="button" onClick={onClick} class="btn btn-brand btn-sm btn-upper">new task</button>}
                description={
                    <>
                        { data.description } <br/>
                        { data.tags } <br/>
                        { data.entities && data.entities.map(e => (
                            <>
                                {e.name} {e.isValid ? '1' : '0'} <br/>
                            </>
                        ))}
                        { data.intents && data.intents.map(e => (
                            <>
                                {e.name} {e.isValid ? '1' : '0'} <br/>
                            </>
                        ))}

                        <TextArea onChange={e => setNotes(e.target.value)} value={notes}/>

                    </>
                }
            >
                

            </KTWidget>
        </Portlet>
    )
}

export default memo(SkillPublish);