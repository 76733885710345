import React, {memo} from 'react';
import classnames from 'classnames';

function Tags({tags, className, ...props}) {
    return (
        <span>
            {
                tags.map((tag,key) => (
                    <span 
                        style={{marginRight: '0.5rem'}}
                        key={key}
                        className={classnames(
                            "btn btn-label-success btn-sm", 
                            className
                        )}
                        {...props}
                    >{tag}</span>
                ))
            }
        </span>
    )
}

export default memo(Tags);