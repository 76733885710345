import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import _isArray from 'lodash/isArray';

function Checkbox({ 
    label,
    className,
    forwardedRef,
    type,
    value,
    ...props
}) {
    const typeClass = useMemo(() => {
        if (typeof type === 'string') return type;
        if (_isArray(type)) {
            return type.map(el => `kt-checkbox--${el}`).join(' ');
        }
        return null;
    },[type]);

    return (
        <label className={classnames("kt-checkbox", typeClass, className)} >
            <input 
                ref={forwardedRef}
                type="checkbox" 
                checked={value}
                {...props}
            /> {label}
			<span></span>
		</label>
    )
}

export default memo(Checkbox);
