import React, { memo } from 'react';
import Logger from '../../utils/logger';
import PageContent from '../PageContent';
import EntitiesSubHeader from './EntitiesSubHeader';
import PageLayout1 from '../../components/PageLayout1';
import EntitiesList from './EntitiesList';
import EntityContent from './EntityContent';

const log = Logger('EntitiesPage');

function EntitiesPage() {
    log.debug('render');

    return (
        <PageContent 
            fixedHeight
			subheader={<EntitiesSubHeader/>}
		>
            <PageLayout1
                left={<EntitiesList/>}
                right={<EntityContent/>}
            />
        </PageContent>
    )
}

export default memo(EntitiesPage);