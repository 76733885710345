import React, { memo } from 'react';
import HeaderMenu from '../../components/Header/HeaderMenu';

const items= [{
    title: 'Analytics',
    link: `/dashboard`
},{
    title: 'Skills',
    link: `/dashboard/skills`
},{
    title: 'Users',
    link: `/dashboard/users`
}];

export default memo(() => (<HeaderMenu items={items}/>));